import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

//Páginas

//Dashboard
import Home from "@pages/Home"
import Mantainers from "@pages/Maintainers"
import AddMantainers from "@pages/AddMaintainers"
import Offerer from "@pages/Offerer"
import DoorToDoor from "@pages/DoorToDoor"
import Accounting from "@pages/Accounting"
import Communication from "@pages/Communication"
import Team from "@pages/Team"
import Settings from "@pages/Settings"
import AddPlan from "@pages/AddPlan"
import AddMantainersCard from "@pages/AddMaintainersCard"
import isAuthenticated from "@api/auth/verifyIfIsAuthenticated"
import ChangePasswordLoggedIn from "@pages/ChangePasswordLoggedIn"
import AddTeam from "@pages/AddTeam"
import MaintainerPanel from "@pages/MaintainerPanel"
import EditMaintainer from "@pages/EditMaintainer"
import RenovationMaintainer from "@pages/RenovationMaintainer"
import EditMaintainerCard from "@pages/EditMaintainerCard"
import RenovationMaintainerCard from "@pages/RenovationMaintainerCard"

export default function RoutesDashboard() {
  return (
    <Switch>
      {!isAuthenticated() && <Redirect to="/login" />}
      <Route exact path="/app/inicio" component={Home} />
      <Route path="/app/mantenedores" component={Mantainers} />
      <Route path="/app/painel-mantenedor/:id" component={MaintainerPanel} />
      <Route path="/app/adiciona-mantenedor" component={AddMantainers} />
      <Route path="/app/editar-mantenedor/:id" component={EditMaintainer} />
      <Route
        path="/app/renovar-mantenedor/:id"
        component={RenovationMaintainer}
      />
      <Route path="/app/mantenedor/:id/doacao" component={AddMantainersCard} />
      <Route
        path="/app/mantenedor/:id/editar-doacao"
        component={EditMaintainerCard}
      />
      <Route
        path="/app/mantenedor/:id/renovar-doacao"
        component={RenovationMaintainerCard}
      />
      <Route path="/app/ofertantes" component={Offerer} />
      <Route path="/app/porta-em-porta" component={DoorToDoor} />
      <Route path="/app/contabilidade" component={Accounting} />
      <Route path="/app/comunicacao" component={Communication} />
      <Route path="/app/time" component={Team} />
      <Route path="/app/adicionar-time" component={AddTeam} />
      <Route path="/app/configuracoes" component={Settings} />
      <Route path="/app/adiciona-plano" component={AddPlan} />
      <Route path="/app/editar-plano/:id" component={AddPlan} />
      <Route path="/app/alterar-senha" component={ChangePasswordLoggedIn} />
      <Redirect to="/app/inicio" />
    </Switch>
  )
}
