import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

import InputAdornment from "@material-ui/core/InputAdornment"
import { IconButton } from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"
import SearchIcon from "@material-ui/icons/Search"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    padding: 15,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  papper: {
    width: "100%",
    borderRadius: 6,
    height: 90,
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
      height: 200,
    },
  },
  searchbarInput: {
    width: 330,
    maxWidth: "90%",
    height: 48,
    fontWeight: 500,
    fontFamily: "Quicksand",
    backgroundColor: colors.whiteText,
    borderRadius: 100,
    paddingLeft: 16,
    "&:focus": {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginTop: 10,
    },
  },
  input: {
    display: "flex",
    alignItems: "center",
  },
})

class Searchbar extends Component {
  componentDidUpdate(prevProps) {
    const { search } = this.props
    if (prevProps.value && !this.props.value) {
      if (search) {
        search()
      }
    }
  }

  handleCheckKey = (e) => {
    //Caso seja a tecla enter, a busca da chave é realizada
    if (e.keyCode === 13) {
      const { value, search } = this.props
      if (search && value.length) {
        search()
      }
    }
  }

  handleSearchIcon = () => {
    const { value, search } = this.props
    if (value.length > 0) {
      search()
    }
  }

  render() {
    const {
      classes,
      children,
      value,
      changeKey,
      typeSearch = "nome",
      disabled = false,
    } = this.props

    return (
      <Grid container justify="center" className={classes.root}>
        <Paper className={classes.papper}>
          <Grid container justify="space-between">
            <Grid
              item
              xs={12}
              md={children ? 3 : 12}
              sm={12}
              className={classes.input}
            >
              {!disabled ? (
                <InputBase
                  className={classes.searchbarInput}
                  classes={{
                    focused: classes.focused,
                  }}
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        onClick={this.handleSearchIcon}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder={`Pesquise por: ${typeSearch}`}
                  value={value}
                  onChange={(e) => changeKey(e)}
                  onKeyDown={this.handleCheckKey}
                />
              ) : null}
            </Grid>
            {children && (
              <Grid
                item
                sm={12}
                xs={12}
                md={9}
                className={classes.buttonContainer}
              >
                {children}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

export default withStyles(styles)(Searchbar)
