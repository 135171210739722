import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, CircularProgress } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    marginBottom: 24,
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}))

export default function Loading() {
  const classes = useStyles()

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <CircularProgress />
    </Grid>
  )
}
