import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

import Title from "./Title"
import Input from "@components/UI/Input/FormInput"

import { useDispatch } from "react-redux"

import PhoneMask from "@components/Masks/PhoneMask"
import DateMask from "@components/Masks/DateMask"
import CPFCNPJMask from "@components/Masks/CPFCNPJMask"

import {
  emailAlreadyExists,
  docAlreadyExists,
} from "@api/services/dataAlreadyExists"
import { alert } from "@redux/actions/alertActions"
import { formatCpf } from "@api/format/format"

const useStyles = makeStyles({
  root: {
    marginBottom: 30,
  },
})

export default function PersonalDataForm(props) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { errors, control, getValues, isCNPJ, type } = props

  const { phone, phone2 } = getValues()

  const verifyEmail = async () => {
    const { email } = getValues()

    if (email) {
      if ((await emailAlreadyExists(email)) === true) {
        dispatch(alert("warning", "Email já cadastrado"))
      }
    }
  }

  const verifyDocument = async () => {
    const { cpfCnpj } = getValues()

    if (cpfCnpj) {
      if ((await docAlreadyExists(formatCpf(cpfCnpj))) === true) {
        dispatch(alert("warning", "Documento já cadastrado"))
      }
    }
  }

  const verifyPhone = (phone) => {
    if (typeof phone != "object") {
      return true
    }
    return false
  }

  return (
    <Grid className={classes.root}>
      <Title text="Dados Pessoais" />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Input
            name="cpfCnpj"
            label="CPF/CNPJ"
            control={control}
            inputComponent={CPFCNPJMask}
            errors={errors}
            // onBlur={() => verifyDocument()}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            disabled={type !== "add" ? true : false}
            control={control}
            name="email"
            label="Email"
            errors={errors}
            onBlur={() => verifyEmail()}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            name="name"
            control={control}
            label={isCNPJ ? "Razão Social" : "Nome"}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {!isCNPJ ? (
            <Input
              control={control}
              name="lastName"
              label="Sobrenome"
              errors={errors}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            name="bornAt"
            control={control}
            label="Data de Nascimento"
            inputComponent={DateMask}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {verifyPhone(phone) ? (
            <Input
              name="phone"
              control={control}
              label="Telefone 1"
              inputComponent={PhoneMask}
              errors={errors}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={3}>
          {verifyPhone(phone2) ? (
            <Input
              name="phone2"
              control={control}
              label="Telefone 2"
              inputComponent={PhoneMask}
              errors={errors}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}
