import React, { useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { Typography } from "@material-ui/core"

import queryString from "query-string"
import { Link, useHistory, useLocation } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import { recoverPassword } from "@redux/actions/passwordActions"

import { useForm } from "react-hook-form"
import changePasswordResolver from "@api/validation/resolvers/changePasswordResolver"

import PrimaryButton from "@components/UI/Button/PrimaryButton"
import SecondaryButton from "@components/UI/Button/SecondaryButton"
import Input from "@components/UI/Input/FormInput"
import Loading from "@components/UI/Loading/Loading"

import colors from "@styles/colors"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  paper: {
    padding: 16,
  },

  title: {
    fontSize: 45,
    fontFamily: "Quicksand",
    marginRight: "3rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },

  text: {
    marginRight: "3rem",
    marginLeft: "3rem",
    color: colors.grayText,
  },

  input: {
    marginTop: "1rem",
  },

  password: {
    width: "80%",
    marginTop: "1.5rem",
  },

  buttons: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  link: {
    textDecoration: "none",
  },

  paperContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 550,
    },
  },
}))

export default function ChangePassword() {
  const classes = useStyles()

  const { error, loading } = useSelector((state) => state.get("password"))
  const dispatch = useDispatch()

  const history = useHistory()

  const location = useLocation()
  const { email, token } = queryString.parse(location.search)

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: changePasswordResolver(),
  })

  useEffect(() => {
    if (error) {
      setError(
        "newPassword",
        {
          type: "manual",
          message: "Senha e confirmação de senha não batem",
        },
        { shouldFocus: true }
      )

      setError("confirmNewPassword", {
        type: "manual",
        message: "Senha e confirmação de senha não batem",
      })
    }
  }, [error])

  const handleChangePassword = ({ newPassword, confirmNewPassword }) => {
    if (newPassword !== confirmNewPassword) {
      setError(
        "newPassword",
        {
          type: "manual",
          message: "Senha e confirmação de senha não batem",
        },
        { shouldFocus: true }
      )

      setError("confirmNewPassword", {
        type: "manual",
        message: "Senha e confirmação de senha não batem",
      })

      return
    }

    if (!token || !email) {
      return
    }

    const data = {
      email: email,
      token: token,
      newPassword: newPassword,
    }

    dispatch(recoverPassword(data, handleRedirect))
  }

  const handleRedirect = () => {
    history.push("/login")
  }

  return (
    <Grid className={classes.root} container>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={11} className={classes.paperContainer}>
          <Paper
            component="form"
            onSubmit={handleSubmit(handleChangePassword)}
            className={classes.paper}
            elevation={3}
          >
            <Typography className={classes.title}>Mantenedores</Typography>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.input}
            >
              <Grid container justify="center" alignItems="center">
                <Typography className={classes.text}>
                  Digite sua nova senha
                </Typography>
              </Grid>
              <Input
                className={classes.password}
                size="small"
                id="outlined-basic"
                label="Senha Nova"
                type="password"
                name="newPassword"
                control={control}
                errors={errors}
              />
              <Input
                className={classes.password}
                size="small"
                id="outlined-basic"
                label="Confirmar Senha"
                type="password"
                name="confirmNewPassword"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid className={classes.buttons} container justify="center">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <Link className={classes.link} to="/login">
                    <SecondaryButton onClick={() => null}>
                      CANCELAR
                    </SecondaryButton>
                  </Link>
                  <PrimaryButton type="submit">ALTERAR SENHA</PrimaryButton>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}
