import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { TextField, MenuItem } from "@material-ui/core"
import classNames from "classnames"

import colors from "@styles/colors"

const styles = (theme) => ({
  select: {
    marginRight: 24,
    width: 163,

    "& .MuiOutlinedInput-root": {
      height: 45,
      "& fieldset": {
        borderRadius: 100,
        borderColor: colors.orange,
      },
      "&:hover fieldset": {
        borderColor: colors.orange,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.orange,
      },
    },
  },
})

class DefaultSelect extends Component {
  isWithError = () => {
    const { errors, name, error } = this.props
    if ((errors && errors.includes(name)) || error) {
      return true
    }
    return false
  }
  render() {
    const { classes, onChange, value, className, options, ...rest } = this.props

    return (
      <TextField
        className={classNames(classes.select, className)}
        select
        error={this.isWithError()}
        variant="outlined"
        value={value}
        onChange={onChange}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    )
  }
}

export default withStyles(styles)(DefaultSelect)
