import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Switch, FormControlLabel, FormGroup } from "@material-ui/core"

import "react-credit-cards/es/styles-compiled.css"
import CreditCard from "react-credit-cards"

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import Input from "@components/UI/Input/FormInput"
import cardCodeMask from "@components/Masks/CardCodeMask"
import cardDateMask from "@components/Masks/CardDateMask"
// import CardNumberMask from "@components/Masks/CardNumberMask"
import Select from "@components/UI/Select/FormSelect"

import colors from "@styles/colors"

const parcelsOptions = [
  { name: 1 },
  { name: 2 },
  { name: 3 },
  { name: 4 },
  { name: 5 },
  { name: 6 },
  { name: 7 },
  { name: 8 },
  { name: 9 },
  { name: 10 },
  { name: 11 },
  { name: 12 },
]

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1920,
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  inputForms: {
    display: "flex",
    flexDirection: "column",
  },
  creditCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
  },
  inputs: {
    paddingLeft: 4,
  },
  switch: {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        color: colors.primaryColor,
        "& + .MuiSwitch-track": {
          opacity: 0.5,
          backgroundColor: colors.primaryColor,
        },
      },
    },
  },
  fontLabel: {
    "& .MuiTypography-body1": {
      fontFamily: "Raleway",
    },
  },
}))

export default function CreditCardForm(props) {
  const classes = useStyles()
  const [disabledCharge, setDisabledCharge] = useState(false)
  const {
    type,
    reset,
    getValues,
    control,
    errors,
    watch,
    switchCheck,
    setSwitchCheck,
    chargeNextMonth,
    setChargeNextMonth,
    frequency,
    card,
  } = props

  const cvc = watch("cvc")
  const expiry = watch("expiry")
  const name = watch("name")
  const number = watch("number")

  const [focused, setFocused] = useState("name")

  useEffect(() => {
    const values = getValues()

    reset({
      ...values,
      registeredCard: switchCheck,
    })
  }, [])

  useEffect(() => {
    setFocused("cvc")
  }, [cvc])

  useEffect(() => {
    setFocused("name")
  }, [name])

  useEffect(() => {
    setFocused("expiry")
  }, [expiry])

  useEffect(() => {
    setFocused("number")
  }, [number])

  const verifyFrequency = () => {
    return !(frequency === "Annually")
  }

  const verifyDisabled = () => {
    const date = new Date()
    const day = date.getDate()
    const chargeDay = Number(control._formValues.chargeDay)
    if (chargeDay < day) {
      setChargeNextMonth(true)
      setDisabledCharge(true)
    } else {
      setDisabledCharge(false)
    }
  }

  const handleChangeSwitch = () => {
    const { expirationMonth, expirationYear, last4CardNumber } = card

    setSwitchCheck(!switchCheck)
    const values = getValues()

    reset({
      ...values,
      registeredCard: !switchCheck,
    })

    const newValues = getValues()

    if (!switchCheck) {
      reset({
        ...newValues,
        number: `**** **** **** ${last4CardNumber}`,
        expiry: `${expirationMonth}/${expirationYear.slice(2, 4)}`,
      })
    } else {
      reset({
        ...newValues,
        number: "",
        expiry: "",
      })
    }
  }

  const handleSwitchCreditCard = () => {
    if (type === "edit" || type === "renovation") {
      return (
        <Grid item md={12}>
          <FormControlLabel
            className={classes.fontLabel}
            control={
              <Switch
                className={classes.switch}
                onChange={handleChangeSwitch}
                checked={switchCheck}
                color="primary"
              />
            }
            label="Desejo manter os dados do cartão cadastrado"
          />
        </Grid>
      )
    }
  }

  const renderInputsForms = () => {
    if (!switchCheck) {
      return (
        <Grid item xs={12} md={4} sm={3} className={classes.inputForms}>
          <Input
            name="number"
            label="Número do cartão"
            // inputComponent={CardNumberMask}
            control={control}
            errors={errors}
          />
          <Input
            name="name"
            label="Nome no cartão"
            control={control}
            errors={errors}
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Input
                name="expiry"
                label="Validade"
                inputComponent={cardDateMask}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                name="cvc"
                label="CVV"
                inputComponent={cardCodeMask}
                endIcon={<InfoOutlinedIcon />}
                control={control}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      {handleSwitchCreditCard()}
      {renderInputsForms()}

      <Grid item className={classes.creditCard}>
        <CreditCard
          placeholders={{ name: "SEU NOME" }}
          preview={switchCheck}
          focused={focused}
          name={name || ""}
          number={number || ""}
          expiry={expiry || ""}
          cvc={cvc || ""}
        />
      </Grid>
      <Grid container spacing={1} className={classes.inputs}>
        <Grid item xs={12} md={4} sm={3}>
          <Input
            name="chargeDay"
            label="Dia da cobrança"
            endIcon={<InfoOutlinedIcon />}
            control={control}
            onBlur={() => verifyDisabled()}
            errors={errors}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={chargeNextMonth}
                  onChange={(event) => setChargeNextMonth(event.target.checked)}
                  disabled={disabledCharge}
                />
              }
              label="Cobrar no próximo mês"
            />
          </FormGroup>
        </Grid>
        <Grid item md={4}>
          {!verifyFrequency() ? (
            <Select
              label="Parcelas"
              name="parcels"
              options={parcelsOptions}
              control={control}
              errors={errors}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}
