/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from "redux-immutable"

// Global Reducers

import maintainer from "@redux/reducers/maintainerReducer"
import password from "./reducers/passwordReducer"
import auth from "./reducers/authReducer"
import churches from "./reducers/churchReducer"
import alert from "./reducers/alertReducer"
import messages from "./reducers/messageReducer"
import sentMessages from "./reducers/sentMessagesReducer"
import user from "./reducers/userReducer"
import admin from "@redux/reducers/teamReducer"
import origins from "./reducers/originsReducer"
import donation from "@redux/reducers/donationReducer"
import plans from "@redux/reducers/plansReducer"

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    admin,
    user,
    password,
    auth,
    messages,
    sentMessages,
    maintainer,
    donation,
    churches,
    origins,
    plans,
    alert,
    ...injectedReducers,
  })

  // Wrap the root reducer and return a new root reducer with router state
  return rootReducer
}
