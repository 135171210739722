// "single_donation", "manual_booklet"
const getFormattedMaintainer = (body) => {
  return {
    email: body.email,
    firstName: body.user.firstName,
    lastName: body.user.lastName,
    bornAt: body.user.bornAt,
    documentNumber: body.documentNumber,
    church: body.complementary.church,
    origin: body.complementary.origin,
    obs: body.complementary.observation ? body.complementary.observation : "",
    phone: {
      ddi: body.user.phone.ddi,
      ddd: body.user.phone.ddd,
      number: body.user.phone.number,
    },
    secondPhone: {
      ddi: body.user.secondPhone.ddi,
      ddd: body.user.secondPhone.ddd,
      number: body.user.secondPhone.number,
    },
    address: {
      street: body.user.address.street,
      neighborhood: body.user.address.neighborhood,
      streetNumber: body.user.address.streetNumber,
      complementary: body.user.address.complementary,
      zipcode: body.user.address.zipcode,
      city: body.user.address.city,
    },
    blocked: body.blocked,
  }
}

export default getFormattedMaintainer
