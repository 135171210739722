import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { useSelector, useDispatch } from "react-redux"
import { getMessages } from "@redux/actions/messageActions"

import { useHistory, useParams } from "react-router-dom"

import FlowItem from "./FlowItem"

import Searchbar from "@components/UI/Input/SearchInput"
import Pagination from "@material-ui/core/TablePagination"
import Loading from "@components/UI/Loading/Loading"

import colors from "@styles/colors"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapper: {
    paddingTop: "0.5rem",
    paddingBottom: "2rem",
    marginRight: "0.3rem",
    marginLeft: "0.3rem",
  },
  pagination: {
    fontWeight: 400,
    fontFamily: "Quicksand",
    color: colors.grayText,
  },
  errorContainer: {
    paddingTop: "0.5rem",
    paddingBottom: "2rem",
    marginRight: "0.3rem",
    marginLeft: "0.3rem",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    fontWeight: 500,
    fontFamily: "Quicksand",
    fontSize: 20,
    marginTop: "2rem",
  },
}))

export default function MessagesFlow() {
  const classes = useStyles()

  const {
    messages,
    loading,
    error,
    currentPage,
    messagesCount,
    messagesPerPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    next,
    prev,
  } = useSelector((state) => state.get("messages"))
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState("")

  const history = useHistory()
  const { p } = useParams()

  const [page, setPage] = useState(parseInt(p) >= 0 ? parseInt(p) : 0)

  useEffect(() => {
    const pattern = /^\d+$/

    if (!pattern.test(p)) {
      history.replace(`/app/comunicacao/fluxo-mensagens/1`)
    }
  }, [p])

  useEffect(() => {
    if (currentPage !== page || messages.length === 0) {
      dispatch(getMessages(page, messagesPerPage, ""))
    }
  }, [page])

  const handleChangeSearchKey = ({ target }) => setInputValue(target.value)

  const handleSearch = () => {
    setPage(1)
    dispatch(getMessages(1, messagesPerPage, inputValue))
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(1)
    dispatch(getMessages(1, parseInt(event.target.value, 10), inputValue))
  }

  const handleChangePage = (_, newPage) => {
    if (newPage >= page) {
      if (hasNextPage && next <= totalPages) {
        setPage(next)
        history.replace(`/app/comunicacao/fluxo-mensagens/${next}`)
      }
    } else {
      if (hasPrevPage) {
        setPage(prev)
        history.replace(`/app/comunicacao/fluxo-mensagens/${prev}`)
      }
    }
  }

  if (error) {
    return (
      <Grid container className={classes.errorContainer}>
        <span className={classes.error}>
          Falha ao buscar os dados dos Fluxos de Mensagens
        </span>
      </Grid>
    )
  }

  return (
    <>
      <Searchbar
        value={inputValue}
        changeKey={handleChangeSearchKey}
        search={handleSearch}
        typeSearch="nome do fluxo"
      >
        <Pagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={messagesCount}
          labelDisplayedRows={({ count }) =>
            `${messagesPerPage * (currentPage - 1)}-${Math.min(
              (currentPage + 1) * messagesPerPage,
              count
            )} de ${count}`
          }
          labelRowsPerPage={"Por página"}
          rowsPerPage={messagesPerPage}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Searchbar>

      <Grid container spacing={2} className={classes.wrapper}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {messages.map((message, index) => (
              <FlowItem
                key={message._id}
                message={message}
                inputValue={inputValue}
              />
            ))}
          </>
        )}
      </Grid>
    </>
  )
}
