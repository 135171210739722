import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { Grid, Typography, Paper } from "@material-ui/core"

import AddButton from "@components/UI/Button/AddButton"

import colors from "@styles/colors"
import SearchInput from "./SearchInput"

const styles = (theme) => ({
  root: {
    padding: 15,
    paddingTop: 4,
    paddingBottom: 4,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  textSearch: {
    color: colors.darkFullText,
    fontSize: 21,
    fontWeight: "normal",
  },
  papper: {
    width: "100%",
    borderRadius: 6,
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  searchbarInput: {
    width: 700,
    maxWidth: 600,
    height: 55,
    fontWeight: 500,
    fontFamily: "Quicksand",
    backgroundColor: colors.whiteText,
    borderRadius: 100,
    paddingLeft: 10,
    marginRight: 16,
  },
  searchbarIcon: {
    width: 20,
  },
  input: {
    display: "flex",
    alignItems: "center",
  },
  AddButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
})

class Searchbar extends Component {
  handleCheckKey = (e) => {
    //Caso seja a tecla enter, a busca da chave é realizada
    if (e.keyCode === 13) {
      const { search } = this.props
      if (search) {
        search()
      }
    }
  }

  render() {
    const { classes, value, changeKey, search, users } = this.props

    return (
      <Grid container justify="center" className={classes.root}>
        <Paper className={classes.papper}>
          <Grid container justify="space-between">
            <Grid item xs={12} md={4} sm={12} className={classes.input}>
              <Typography
                variant="h2"
                gutterBottom
                className={classes.textSearch}
              >
                Pesquise por Email
              </Typography>
            </Grid>
            <Grid
              container
              sm={12}
              xs={12}
              md={8}
              justify="space-between"
              alignItems="center"
            >
              <Grid item md={11} xs={12} sm={12}>
                <SearchInput value={value} changeKey={changeKey} />
              </Grid>
              <Grid item md={1} xs={12} sm={12} className={classes.AddButton}>
                <AddButton onCLick={search} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

export default withStyles(styles)(Searchbar)
