import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { validateEmail } from "../"

export default function forgotPasswordResolver() {
  const validateSchema = yup.object().shape({
    email: validateEmail(),
  })

  return yupResolver(validateSchema)
}
