import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { Grid, Paper, Typography } from "@material-ui/core"
import { connect } from "react-redux"

import TableOptionButton from "@components/UI/Table/TableOptionButton"
import colors from "@styles/colors"
import Loading from "@components/UI/Loading/Loading"
import { blockedUser } from "@redux/actions/userActions"
import formatZipcode from "@api/format/formatZipcode"

const styles = (theme) => ({
  paper: {
    width: "100%",
    padding: 24,
  },
  title: {
    fontSize: 30,
    fontWeight: 400,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
    },
    paddingBottom: 24,
  },
  textInfo: {
    color: colors.darkText,
    fontFamily: "Raleway",
    paddingBottom: 4,
    paddingTop: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  editButton: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  error: {
    color: colors.darkText,
    fontSize: 24,
  },
})

class CardDetails extends Component {
  state = {
    block: this.props.maintainer.blocked,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.maintainer.blocked !== this.props.maintainer.blocked) {
      this.setState({ block: this.props.maintainer.blocked })
    }
  }
  onToggleBlockSuccess = () => {
    const { block } = this.state
    this.setState({ block: !block })
  }

  handleBlockMaintainer = () => {
    const { maintainerId, maintainer } = this.props
    const { block } = this.state
    this.props.dispatch(
      blockedUser(maintainerId, !block, this.onToggleBlockSuccess)
    )
  }

  verifyBlocked = () => {
    const { block } = this.state
    return !block
  }

  handleEdit = () => {
    const { maintainerId } = this.props
    this.props.history.push(`/app/editar-mantenedor/${maintainerId}`)
  }

  render() {
    const { classes, maintainer, isLoading, error } = this.props

    return (
      <Paper elevation={3} className={classes.paper}>
        {isLoading || !maintainer || !maintainer.address ? (
          <Loading />
        ) : !error ? (
          <Grid container>
            <Grid item md={10}>
              <Typography className={classes.title}>
                {`${maintainer.firstName} ${
                  maintainer.lastName ? maintainer.lastName : ""
                }`}
              </Typography>
            </Grid>
            <Grid item md={2} className={classes.editButton}>
              {!this.state.block ? (
                <TableOptionButton
                  isTable={false}
                  type="blockMaintainer"
                  funct={this.handleBlockMaintainer}
                />
              ) : (
                <TableOptionButton
                  isTable={false}
                  type="unlockMaintainer"
                  funct={this.handleBlockMaintainer}
                />
              )}
              <TableOptionButton
                isTable={false}
                type="edit"
                funct={this.handleEdit}
              />
            </Grid>

            <Grid item md={4}>
              <Typography className={classes.textInfo}>
                {maintainer.documentNumber}
              </Typography>
              <Typography className={classes.textInfo}>
                {maintainer.bornAt}
              </Typography>
              <Typography className={classes.textInfo}>
                {maintainer.church?.name}
              </Typography>
              <Typography className={classes.textInfo}>
                {maintainer.origin?.name}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.textInfo}>
                {formatZipcode(maintainer.address.zipcode)}
              </Typography>
              <Typography className={classes.textInfo}>
                {`${maintainer.address.street}, ${maintainer.address.streetNumber}`}
              </Typography>
              <Typography className={classes.textInfo}>
                {maintainer.address.complementary}
              </Typography>
              <Typography className={classes.textInfo}>
                {maintainer.address.city}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className={classes.textInfo}>Observação:</Typography>
              <Typography className={classes.textInfo}>
                {maintainer.obs}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Typography className={classes.error}>
              Erro ao pesquisar o mantenedor
            </Typography>
          </Grid>
        )}
      </Paper>
    )
  }
}

function mapStateToProps(state) {
  const { maintainer, isLoading, maintainerId, error } = state.get("maintainer")
  return {
    maintainer,
    isLoading,
    maintainerId,
    error,
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(CardDetails))
)
