import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { validateEmail, validatePassword } from "../"

export default function loginResolver() {
  const validateSchema = yup.object().shape({
    email: validateEmail(),
    password: validatePassword(),
  })

  return yupResolver(validateSchema)
}
