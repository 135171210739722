import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import CardLogin from "@components/Login/CardLogin"

import colors from "@styles/colors"

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.whiteText,
  },
})

export default function Login() {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <CardLogin />
    </Grid>
  )
}
