import c from "@redux/constants/originsConstants"

const initialState = {
  origins: [],
  loading: false,
  error: false,
  rowsPerPage: 5,
  totalPages: 1,
  rowsCount: 1,
  currentPage: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prev: null,
  next: null,
}

export default function origin(state = initialState, action) {
  switch (action.type) {
    case c.ORIGIN_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.ORIGIN_SUCCESS: {
      return {
        ...state,
        origins: action.payload,
        loading: false,
        error: false,
      }
    }
    case c.ORIGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case c.ORIGIN_PAGINATED_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.ORIGIN_PAGINATED_SUCCESS: {
      return {
        ...state,
        origins: action.payload.origins,
        rowsPerPage: action.payload.originsPerPage,
        totalPages: action.payload.totalPages,
        rowsCount: action.payload.originsCount,
        currentPage: action.payload.currentPage,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prev: action.payload.prev,
        next: action.payload.next,
        loading: false,
        error: false,
      }
    }
    case c.ORIGIN_PAGINATED_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }

    case c.CREATE_ORIGIN_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }
    case c.EDIT_ORIGIN_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }
    case c.DELETE_ORIGIN_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }
    default:
      return state
  }
}
