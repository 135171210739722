import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import SidebarRender from "@components/Sidebar/SidebarRender"
import colors from "@styles/colors"

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  logoContainer: {
    height: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    fontSize: 45,
    color: colors.whiteText,
    fontFamily: "Quicksand",
    fontWeight: "bold",
  },
  divider: {
    height: 1,
    marginTop: 5,
    margin: "0 10px",
    backgroundColor: colors.whiteText,
    borderRadius: 4,
  },
})

class SidebarContent extends Component {
  state = {}
  render() {
    const { classes } = this.props

    return (
      <div>
        <Grid className={classes.toolbar}>
          <Link to="/app" style={{ textDecoration: "none" }}>
            <Grid container className={classes.logoContainer}>
              <Typography className={classes.logo}>M</Typography>
            </Grid>
          </Link>
        </Grid>
        <Grid className={classes.divider} />
        <SidebarRender />
      </div>
    )
  }
}

export default withStyles(styles)(SidebarContent)
