import c from "../constants/messageConstants"
import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"
import { RequestSDK } from "@sdk"

export const getMessages = (page = 1, perPage = 6, search = "") => {
  return (dispatch) => {
    dispatch(messageRequest())
    RequestSDK.messages
      .getMessages(search, page, perPage)
      .then((response) => {
        const data = response.data

        dispatch(messageSuccess(data))
      })
      .catch((error) => {
        dispatch(messageFailure(error.response))
      })
  }
}

export const messageRequest = () => {
  return {
    type: c.MESSAGE_REQUEST,
  }
}

export const messageSuccess = (payload) => {
  return {
    type: c.MESSAGE_SUCCESS,
    payload,
  }
}

export const messageFailure = () => {
  return {
    type: c.MESSAGE_FAILURE,
  }
}

export const editMessage = (
  id,
  message,
  handleCloseModalAndResetForm,
  { page, rowsPerPage, searchValue }
) => {
  return (dispatch) => {
    dispatch(editMessageRequest())

    RequestSDK.messages
      .editMessage(id, message)
      .then((response) => {
        dispatch(editMessageSuccess())
        dispatch(alert("success", "Mensagem editada com sucesso!"))
        dispatch(getMessages(page, rowsPerPage, searchValue))

        if (typeof handleCloseModalAndResetForm === "function")
          handleCloseModalAndResetForm()
      })
      .catch((error) => {
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const editMessageRequest = () => {
  return {
    type: c.EDIT_MESSAGE_REQUEST,
  }
}

export const editMessageSuccess = () => {
  return {
    type: c.EDIT_MESSAGE_SUCCESS,
  }
}
