import React, { Component } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Grid from "@material-ui/core/Grid"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    padding: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
      overflow: "scroll",
      width: "100%",
      paddingLeft: 160,
    },
  },
  tab: {
    textTransform: "none",
    color: colors.darkText,
    fontWeight: 500,
    fontFamily: "Quicksand",
    fontSize: 14,
    marginRight: 4,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  tabSelected: {
    opacity: 1,
    color: colors.whiteText,
    backgroundColor: colors.secondaryColor,
    borderRadius: 70,
  },
})

const StyledTabs = withStyles({
  indicator: {
    display: "none",
  },
})((props) => <Tabs {...props} />)

class GenricalTab extends Component {
  handleChange = (_, index) => {
    const tab = this.props.tabs[index]
    if (tab) {
      this.props.history.replace(`${this.props.match.path}/${tab.path}`)
    }
  }

  isTabSelected = (tab) => {
    const path = this.props.location.pathname.split("/")
    const tabSelected = tab.path.split("/")
    return path[3] === tabSelected[0]
  }

  render() {
    const { classes, tabs, current, justify = "center" } = this.props

    return (
      <Grid container justify={justify} className={classes.root}>
        <Grid item>
          <StyledTabs
            value={current}
            onChange={this.handleChange}
            className={classes.tabsContainer}
          >
            {tabs.map((tab, i) => (
              <Tab
                label={tab.name}
                className={classNames(
                  classes.tab,
                  this.isTabSelected(tab) ? classes.tabSelected : null
                )}
              />
            ))}
          </StyledTabs>
        </Grid>
      </Grid>
    )
  }
}

GenricalTab.propTypes = {
  content: PropTypes.array,
}

export default withRouter(withStyles(styles)(GenricalTab))
