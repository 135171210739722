import c from "../constants/churchConstants"
import { RequestSDK } from "@sdk"
import getErrorMessage from "@api/utils/getErrorMessage"
import { alert } from "@redux/actions/alertActions"

export const getChurchesPaginated = (page = 1, perPage = 5, search = "") => {
  return (dispatch) => {
    dispatch(churchPaginatedRequest())
    RequestSDK.churches
      .getChurches(search, page, perPage)
      .then((response) => {
        const data = response.data

        const formattedChurches = data.churches.map((church) => {
          return {
            id: church._id,
            name: church.name,
          }
        })

        const formattedPayload = {
          ...data,
          churches: formattedChurches,
        }

        dispatch(churchPaginatedSuccess(formattedPayload))
      })
      .catch((error) => {
        dispatch(churchPaginatedFailure(error.response))
      })
  }
}

export const getChurch = (churchId) => {
  return (dispatch) => {
    dispatch(churchRequest())
    RequestSDK.churches
      .getChurch(churchId)
      .then((res) => {
        const formattedChurch = res.data.map((church) => {
          return {
            id: church._id,
            name: church.name,
          }
        })
        dispatch(churchSuccess(formattedChurch))
      })
      .catch((err) => {
        dispatch(churchFailure(err.response))
      })
  }
}

export const churchRequest = () => {
  return {
    type: c.CHURCH_REQUEST,
  }
}

export const churchSuccess = (payload) => {
  return {
    type: c.CHURCH_SUCCESS,
    payload,
  }
}

export const churchFailure = () => {
  return {
    type: c.CHURCH_FAILURE,
  }
}

export const churchPaginatedRequest = () => {
  return {
    type: c.CHURCH_PAGINATED_REQUEST,
  }
}

export const churchPaginatedSuccess = (payload) => {
  return {
    type: c.CHURCH_PAGINATED_SUCCESS,
    payload,
  }
}

export const churchPaginatedFailure = () => {
  return {
    type: c.CHURCH_PAGINATED_FAILURE,
  }
}

// CRIANDO UMA IGREJA
export const createChurch = (
  name,
  paginatedChurch = false,
  submitCallbackFunction = false
) => {
  return (dispatch) => {
    RequestSDK.churches
      .createChurch(name)
      .then((response) => {
        const data = response.data
        dispatch(createChurchSuccess())

        if (paginatedChurch)
          dispatch(
            getChurchesPaginated(
              paginatedChurch.page,
              paginatedChurch.rowsPerPage,
              paginatedChurch.searchValue
            )
          )

        if (
          submitCallbackFunction &&
          typeof submitCallbackFunction === "function"
        )
          submitCallbackFunction(data)

        dispatch(alert("success", "Igreja cadastrada com sucesso!"))
      })
      .catch((error) => {
        dispatch(churchFailure(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const createChurchSuccess = () => {
  return {
    type: c.CREATE_CHURCH_SUCCESS,
  }
}

//EXCLUINDO UMA IGREJA
export const deleteChurch = (id, { page, rowsPerPage, searchValue }) => {
  return (dispatch) => {
    RequestSDK.churches
      .deleteChurch(id)
      .then((response) => {
        dispatch(deleteChurchSuccess())
        dispatch(getChurchesPaginated(page, rowsPerPage, searchValue))

        dispatch(alert("success", "Igreja deletada com sucesso!"))
      })
      .catch((error) => {
        dispatch(churchFailure(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const deleteChurchSuccess = () => {
  return {
    type: c.DELETE_CHURCH_SUCCESS,
  }
}

//EDITANDO UMA IGREJA
export const editChurch = (id, value, { page, rowsPerPage, searchValue }) => {
  return (dispatch) => {
    RequestSDK.churches
      .editChurch(id, value)
      .then((response) => {
        dispatch(deleteChurchSuccess())
        dispatch(getChurchesPaginated(page, rowsPerPage, searchValue))

        dispatch(alert("success", "Igreja editada com sucesso!"))
      })
      .catch((error) => {
        dispatch(churchFailure(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const editChurchSuccess = () => {
  return {
    type: c.EDIT_CHURCH_SUCCESS,
  }
}
