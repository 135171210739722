import homeIcon from "@static/icons/home.svg"
import homeSelectedIcon from "@static/icons/homeselected.svg"

import groupIcon from "@static/icons/group.svg"
import groupSelectedIcon from "@static/icons/groupselected.svg"

import peopleIcon from "@static/icons/people.svg"
import peopleSelectedIcon from "@static/icons/peopleselected.svg"

import doorIcon from "@static/icons/door.svg"
import doorSelectedIcon from "@static/icons/doorselected.svg"

import accountingIcon from "@static/icons/accounting.svg"
import accountingSelectedIcon from "@static/icons/accountingselected.svg"

import chatIcon from "@static/icons/chat.svg"
import chatSelectedIcon from "@static/icons/chatselected.svg"

import teamIcon from "@static/icons/team.svg"
import teamSelectedIcon from "@static/icons/teamselected.svg"

import optionIcon from "@static/icons/option.svg"
import optionSelectedIcon from "@static/icons/optionselected.svg"

const menu = [
  {
    key: "index",
    name: "Início",
    icon: homeIcon,
    iconSelected: homeSelectedIcon,
    link: "/app/inicio",
    disabled: false,
    show: true,
  },
  {
    key: "maintainers",
    name: "Mantenedores",
    icon: groupIcon,
    iconSelected: groupSelectedIcon,
    link: "/app/mantenedores",
    disabled: false,
    show: true,
  },
  {
    key: "offerer",
    name: "Ofertantes",
    icon: peopleIcon,
    iconSelected: peopleSelectedIcon,
    link: "/app/ofertantes",
    disabled: false,
    show: true,
  },
  {
    key: "door-to-door",
    name: "Porta em porta",
    icon: doorIcon,
    iconSelected: doorSelectedIcon,
    link: "/app/porta-em-porta",
    disabled: false,
    show: true,
  },
  {
    key: "accounting",
    name: "Contabilidade",
    icon: accountingIcon,
    iconSelected: accountingSelectedIcon,
    link: "/app/contabilidade",
    disabled: false,
    show: true,
  },
  {
    key: "communication",
    name: "Comunicação",
    icon: chatIcon,
    iconSelected: chatSelectedIcon,
    link: "/app/comunicacao",
    disabled: false,
    show: true,
  },
  {
    key: "team",
    name: "Time",
    icon: teamIcon,
    iconSelected: teamSelectedIcon,
    link: "/app/time",
    disabled: false,
    show: true,
  },
  {
    key: "option",
    name: "Configurações",
    icon: optionIcon,
    iconSelected: optionSelectedIcon,
    link: "/app/configuracoes",
    disabled: false,
    show: true,
  },
  {
    key: "painel",
    name: "Painel do Mantenedor",
    // icon: optionIcon,
    // iconSelected: optionSelectedIcon,
    link: "/app/painel-mantenedor",
    disabled: true,
    show: false,
  },
]

export default menu
