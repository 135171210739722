import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core"
import Loading from "@components/UI/Loading/Loading"
import colors from "@styles/colors"
import {
  editDonation,
  getCurrentDonation,
} from "@redux/actions/donationActions"
import { CloudDownload, Refresh } from "@material-ui/icons"

import BookletModal from "./BookletModal"
import { RequestSDK } from "@sdk"
import moment from "moment"
import { formatDonationAmount } from "@api/format/formatDonationAmount"
import { formatDonationStatus } from "@api/format/formatDonationStatus"

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: 24,
  },
  titleDonation: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  defaultGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  donationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 4,
  },
  amount: {
    fontSize: 18,
    color: colors.secondaryColor,
  },
  creditCardText: {
    fontSize: 18,
    color: colors.primaryColor,
    textAlign: "center !important",
  },
  defaultText: {
    fontSize: 15,
    color: colors.darkText,
    textAlign: "center !important",
  },
  installmentText: {
    fontSize: 15,
    color: colors.darkText,
    textAlign: "center !important",
    fontWeight: 600,
  },
  booklet: {
    fontSize: 18,
    color: colors.orange,
  },
  rowDonation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: colors.primaryColor,
    "&:disabled": {
      color: "#AAAAAA",
    },
  },
}))

function formatDate(date) {
  return date.split("/").join("-")
}

export default function CardCurrentBooklet(props) {
  const classes = useStyles()

  const { id } = useParams()

  const [transactions, setTransactions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const getTransactions = async () => {
    try {
      const { data: booklet } = await RequestSDK.donations.getCurrentBooklet(id)
      setTransactions(booklet.transactions ?? [])
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    getTransactions()
  }, [])


  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedInstallment, setSelectedInstallment] = useState(null)

  const handleClickOnBookletLink = (urlLink) => {
    window.open(urlLink, "_blank").focus()
  }

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const dispatch = useDispatch()

  const callbackFunction = () => {
    dispatch(getCurrentDonation(id))
    getTransactions()
  }

  const handleSubmitRegenerateBooklet = async (date) => {
    const body = {
      donationType: "booklet",
      gatewayId: selectedInstallment.bookletId,
      installmentNumber: selectedInstallment.installmentNumber,
      dueDate: formatDate(date),
    }

    dispatch(editDonation(body, "booklet", id, callbackFunction))
  }

  const handleRegenerateBooklet = (transaction) => {
    setSelectedInstallment(transaction)
    handleOpenModal()
  }

  const formatTransactions = (transactions) => {
    const formattedTransactions = transactions.map((transaction) => ({
      date: moment(new Date(transaction.dueDate)).format("DD/MM/YYYY"),
      amount: formatDonationAmount(transaction.amount?.authorized),
      status: formatDonationStatus(transaction.status),
      bookletId: transaction.bookletId,
      installmentNumber: transaction.installmentNumber,
      bookletLink: transaction.urlBoleto,
    }))

    return formattedTransactions
  }

  const renderTransactions = () => {
    const transactionsFormatted = formatTransactions(transactions)

    return transactionsFormatted.map((transaction, index) => {
      return (
        <Grid
          container
          className={classes.donationContainer}
          key={`transaction-${index}`}
        >
          <Grid item md={2} className={classes.rowDonation}>
            <Typography className={classes.installmentText}>
              {transaction.installmentNumber} de {transactions.length}
            </Typography>
          </Grid>
          <Grid item md={1} className={classes.rowDonation}>
            <Typography className={classes.amount}>
              {transaction.amount}
            </Typography>
          </Grid>

          <Grid item md={3} className={classes.rowDonation}>
            <Typography className={classes.defaultText}>
              {transaction.status}
            </Typography>
          </Grid>

          <Grid item md={2} className={classes.rowDonation}>
            <Typography className={classes.defaultText}>
              {transaction.date}
            </Typography>
          </Grid>

          <Grid item md={2} className={classes.rowDonation}>
            <Tooltip title="Baixar boleto">
              <IconButton
                onClick={() =>
                  handleClickOnBookletLink(transaction.bookletLink)
                }
                style={{ color: colors.primaryColor }}
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>

            <Tooltip title="Regerar boleto">
              <IconButton
                onClick={() => handleRegenerateBooklet(transaction)}
                style={{ marginLeft: 4, color: colors.orange }}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )
    })
  }

  if (error || !isLoading && !transactions.length) return null

  return (
    <Paper elevation={3} className={classes.paper}>
      <BookletModal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitRegenerateBooklet}
        title="Data de Vencimento"
        label="Data de Vencimento"
        selectedInstallment={selectedInstallment}
        name="bookletDueDate"
      >
        Informe a nova data de vencimento para esta parcela:
      </BookletModal>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Grid container>
            <Grid item md={12} className={classes.defaultGrid}>
              <Typography className={classes.titleDonation}>Parcelas do Boleto</Typography>
            </Grid>

            {renderTransactions()}
          </Grid>
        </>
      )}
    </Paper>
  )
}
