//Options
import Shipments from "@components/Communication/Shipments"
import MessagesFlow from "@components/Communication/MessagesFlow"

const communicationPages = [
  {
    name: "Envios",
    path: "envios/:p",
    component: Shipments,
  },
  {
    name: "Fluxo de Mensagens",
    path: "fluxo-mensagens/:p",
    component: MessagesFlow,
  },
]

export default communicationPages
