//Options
import Pendencies from "@components/Mantainers/Pendencies"
import Renovations from "@components/Mantainers/Renovations"

import Payments from "@components/Mantainers/Payments"
import Canceled from "@components/Mantainers/Canceled"

export default () => {
  return [
    {
      name: "Ativos",
      path: "pagamentos/:p",
      component: Payments,
    },
    {
      name: "Pendências",
      path: "pendencias/:p",
      component: Pendencies,
    },
    {
      name: "Renovações",
      path: "renovacoes/:p",
      component: Renovations,
    },
    {
      name: "Cancelados",
      path: "cancelados/:p",
      component: Canceled,
    },
  ]
}
