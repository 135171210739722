import { getUFOfState } from "@api/utils/getStateName"
import { formatCpf, formatZipcode, formatPhone, formatDate } from "./format"

// "single_donation", "manual_booklet"
const getFormattedMantainerBody = (body) => {
  const secondPhone = body.phone2
    ? {
        ddi: formatPhone(body.phone2).phoneDDI,
        ddd: formatPhone(body.phone2).phoneDDD,
        number: formatPhone(body.phone2).phoneNumber,
      }
    : {}
  if (body.lastName === "") {
    return {
      email: body.email,
      firstName: body.name,
      bornAt: formatDate(body.bornAt),
      documentNumber: formatCpf(body.cpfCnpj),
      phone: {
        ddi: formatPhone(body.phone).phoneDDI,
        ddd: formatPhone(body.phone).phoneDDD,
        number: formatPhone(body.phone).phoneNumber,
      },
      registerType: body.isCNPJ ? "pj" : "pf",
      secondPhone,
      address: {
        street: body.street,
        neighborhood: body.neighborhood,
        streetNumber: body.number,
        complementary: body.complement,
        city: body.city,
        state: getUFOfState(body.state),
        zipcode: formatZipcode(body.zipcode),
      },
      complementary: {
        churchId: body.church,
        originId: body.origin,
        observation: body.obs == "" ? undefined : body.obs,
      },
    }
  } else {
    return {
      email: body.email,
      firstName: body.name,
      lastName: body.lastName,
      bornAt: formatDate(body.bornAt),
      documentNumber: formatCpf(body.cpfCnpj),
      phone: {
        ddi: formatPhone(body.phone).phoneDDI,
        ddd: formatPhone(body.phone).phoneDDD,
        number: formatPhone(body.phone).phoneNumber,
      },
      registerType: body.isCNPJ ? "pj" : "pf",
      secondPhone,
      address: {
        street: body.street,
        neighborhood: body.neighborhood,
        streetNumber: body.number,
        complementary: body.complement,
        city: body.city,
        state: getUFOfState(body.state),
        zipcode: formatZipcode(body.zipcode),
      },
      complementary: {
        churchId: body.church,
        originId: body.origin,
        observation: body.obs == "" ? undefined : body.obs,
      },
    }
  }
}

export default getFormattedMantainerBody
