import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import { Link } from "react-router-dom"

import PrimaryButton from "@components/UI/Button/PrimaryButton"

import colors from "@styles/colors"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 16,
  },

  text: {
    marginRight: "3rem",
    marginLeft: "3rem",
    color: colors.grayText,
  },

  input: {
    marginTop: "1rem",
  },
  link: {
    textDecoration: "none",
  },

  buttons: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}))

export default function ForgotPasswordSuccess() {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.input}
      >
        <Grid container justify="center" alignItems="center">
          <Typography className={classes.text}>
            Email de recuperação de senha enviado com sucesso!
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.buttons} container justify="center">
        <Link className={classes.link} to="/login">
          <PrimaryButton onClick={() => null}>IR PARA O LOGIN</PrimaryButton>
        </Link>
      </Grid>
    </Paper>
  )
}
