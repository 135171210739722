import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core"
import classNames from "classnames"

const styles = (theme) => ({
  input: {
    marginTop: 20,
  },
})

class DefaultInput extends Component {
  isWithError = () => {
    const { errors, name, error } = this.props
    if ((errors && errors.includes(name)) || error) {
      return true
    }
    return false
  }

  render() {
    const {
      classes,
      onChange,
      value,
      inputComponent,
      className,
      errors,
      onBlur,
      endIcon,
      ...rest
    } = this.props
    return (
      <TextField
        fullWidth
        className={classNames(classes.input, className)}
        variant="outlined"
        InputProps={{
          inputComponent,
          value,
          onChange,
        }}
        error={this.isWithError()}
        endIcon={endIcon}
        onBlur={onBlur}
        {...rest}
      />
    )
  }
}

export default withStyles(styles)(DefaultInput)
