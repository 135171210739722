import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { Grid } from "@material-ui/core"
import { withRouter } from "react-router-dom"

import { getMaintainer } from "@redux/actions/maintainerActions"
import CardDetails from "@components/Mantainers/MaintainerPanel/CardDetails"
import ButtonWhatsapp from "@components/Mantainers/MaintainerPanel/ButtonWhatsapp"
import ButtonMessage from "@components/Mantainers/MaintainerPanel/ButtonMessage"
import CardDonationsActive from "@components/Mantainers/MaintainerPanel/CurrentDonationCard/index"
import CardDonation from "@components/Mantainers/MaintainerPanel/CardDonation"
import CardMessages from "@components/Mantainers/MaintainerPanel/CardMessages"
import CardCurrentBooklet from "@components/Mantainers/MaintainerPanel/CardCurrentBooklet"
// import CardNote from "@components/Mantainers/MaintainerPanel/CardNote"

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    marginTop: 24,
  },
  container: {
    flex: 1,
    padding: "0 5px",
    marginLeft: 56,
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  defaultContainer: {
    marginTop: 24,
  },
  tabContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  containerCard: {
    marginLeft: 0,
  },
})

class MaintainerPanel extends Component {
  state = {
    currentTab: 0,
  }

  componentDidMount() {
    this.props.dispatch(getMaintainer(this.props.match.params.id))
  }

  render() {
    const { classes, maintainer } = this.props
    const { currentTab } = this.state
    return (
      <Grid container className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item md={11}>
            <CardDetails />
          </Grid>
          <Grid item md={1} className={classes.buttons}>
            <ButtonWhatsapp
              number={`${maintainer.phone.ddd}${maintainer.phone.ddi}${maintainer.phone.number}`}
            />
            {maintainer.secondPhone ? (
              <ButtonWhatsapp
                number={`${maintainer.secondPhone.ddd}${maintainer.secondPhone.ddi}${maintainer.secondPhone.number}`}
              />
            ) : null}
            {/* <ButtonMessage /> */}
          </Grid>
          <Grid container spacing={2} className={classes.defaultContainer}>
            <Grid item md={4}>
              <CardDonationsActive />
            </Grid>
            <Grid item md={7} className={classes.containerCard}>
              <CardCurrentBooklet />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.defaultContainer}>
            <Grid item md={12} className={classes.containerCard}>
              <CardDonation />
            </Grid>
          </Grid>
          <Grid container className={classes.defaultContainer}>
            <Grid item md={11}>
              <CardMessages />
            </Grid>
            {/* <Grid item md={5} className={classes.containerCard}>
              <CardNote />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  const { maintainer, maintainerId } = state.get("maintainer")
  return {
    maintainer,
    maintainerId,
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(MaintainerPanel))
)
