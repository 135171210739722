import c from "../constants/authConstants"
import { RequestSDK } from "@sdk"

export const login = (data) => {
  return (dispatch) => {
    dispatch(loginRequest(data))
    RequestSDK.authentication
      .login(data)
      .then((response) => {
        const payload = {
          ...response.data,
          email: data.email,
        }
        setAuthStorage(response.data.token)
        dispatch(loginSuccess(payload))
      })
      .catch((error) => {
        dispatch(loginFailure(error))
      })
  }
}

export const loginRequest = (data) => {
  return {
    type: c.LOGIN_REQUEST,
    data,
  }
}

export const loginSuccess = (payload) => {
  return {
    type: c.LOGIN_SUCCESS,
    payload,
  }
}

export const clearErrors = () => {
  return {
    type: c.CLEAR_ERRORS,
  }
}

export const loginFailure = (error) => {
  return {
    type: c.LOGIN_FAILURE,
  }
}

export const setAuthStorage = (token) => {
  localStorage.setItem("access_token", token)
}

export const logout = () => {
  localStorage.removeItem("access_token")
  return { type: c.CLEAR_TOKEN }
}

const setStorage = (token) => {
  setAuthStorage(token)
}

// Get User
export const getUser = () => {
  return (dispatch) => {
    RequestSDK.authentication
      .getUser()
      .then((response) => {
        dispatch(getUserSuccess(response.data))
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(getUserFailure(error.response))
        }
      })
  }
}

const getUserSuccess = (user) => {
  return {
    type: c.GET_USER_SUCCESS,
    user,
  }
}

export const getUserFailure = (error) => {
  return {
    type: c.GET_USER_FAILURE,
    error,
  }
}
