import c from "@redux/constants/donationConstants"

const initialState = {
  dataDonation: {
    description: "",
    amount: "",
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    // cardNumber: "",
    // cardValidity: "",
    // cardName: "",
    // cardSecurityCode: "",
    chargeDay: "",
  },
  currentDonation: {},
  transactions: [],
  currentPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  next: null,
  prev: null,
  transactionsCount: 0,
  transactionsPerPage: 10,
  totalPages: 1,
  donationLoader: false,
  transactionsLoader: false,
  error: false,
  success: false,
  cancelled: false,
}

export default function donation(state = initialState, action) {
  switch (action.type) {
    case c.SET_DONATION_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    }
    case c.DONATION_REQUEST: {
      return {
        ...state,
        donationLoader: true,
        transactionsLoader: true,
        error: false,
        success: false,
      }
    }
    case c.DONATION_FAILURE: {
      return {
        ...state,
        donationLoader: false,
        transactionsLoader: false,
        error: true,
      }
    }
    case c.DONATION_SUCCESS: {
      return {
        ...state,
        donation: action.payload,
        donationLoader: false,
        transactionsLoader: true,
        success: true,
      }
    }
    case c.CURRENT_DONATION_SUCCESS: {
      return {
        ...state,
        donation: action.payload,
        donationLoader: false,
        success: true,
      }
    }
    case c.TRANSACTIONS_REQUEST: {
      return {
        ...state,
        transactionsLoader: true,
        error: false,
        success: false,
      }
    }
    case c.TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactions: action.payload.transactions,
        currentPage: action.payload.currentPage,
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        next: action.payload.next,
        prev: action.payload.prev,
        transactionsCount: action.payload.transactionsCount,
        transactionsPerPage: action.payload.transactionsPerPage,
        totalPages: action.payload.totalPages,
        transactionsLoader: false,
        error: false,
      }
    }
    case c.CLEAR_DONATION_FIELDS: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
