export class PlansRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async getPlans() {
    return this.requestApi({
      url: `/plans`,
      method: "get",
    })
  }

  async getPlansPaginated(page, perPage, search) {
    return this.requestApi({
      url: "/plans",
      params: {
        isCustomized: false,
        page,
        perPage,
        q: search || "",
      },
      method: "get",
    })
  }

  async getStandardPlans() {
    return this.requestApi({
      url: "/plans",
      params: {
        isCustomized: false,
        isPaginated: false,
      },
      method: "get",
    })
  }

  async getPlan(id) {
    return this.requestApi({
      url: `/plans/${id}`,
      method: "get",
    })
  }

  async createPlan(body) {
    return this.requestApi({
      url: "/plans",
      method: "post",
      body,
    })
  }

  async deletePlan(planId) {
    return this.requestApi({
      url: `/plans/${planId}/status`,
      method: "patch",
    })
  }

  async editPlan(planId, body) {
    return this.requestApi({
      url: `/plans/${planId}`,
      method: "patch",
      body,
    })
  }
}
