import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Paper } from "@material-ui/core"

import IconButton from "@material-ui/core/IconButton"
import EmailIcon from "@material-ui/icons/Email"
import colors from "@styles/colors"

const styles = (theme) => ({
  button: {
    height: 50,
    width: 50,
    borderRadius: "100%",
    marginBottom: 16,
  },
})

class ButtonWhatsapp extends Component {
  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.button} elevation={3}>
        <IconButton aria-label="email">
          <EmailIcon style={{ color: colors.darkLightText }} />
        </IconButton>
      </Paper>
    )
  }
}

export default withStyles(styles)(ButtonWhatsapp)
