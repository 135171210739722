import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

// import Title from "@redux/Compo"
import Input from "@components/UI/Input/FormInput"
import Select from "@components/UI/Select/FormSelect"

const useStyles = makeStyles({
  root: {
    marginBottom: 30,
  },
  switch: {
    justify: "flex-start",
  },
})

export default function PlanForm(props) {
  const classes = useStyles()

  const { control, errors, editPlan } = props

  const hideIfEditPlan = () => {
    if (!editPlan) {
      return (
        <>
          <Grid item xs={12} sm={3}>
            <Input
              name="amount"
              label="Valor"
              control={control}
              errors={errors}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Select
              label="Plano"
              name="frequency"
              options={[{ name: "Anual" }, { name: "Mensal" }]}
              control={control}
              errors={errors}
            />
          </Grid>
        </>
      )
    }
  }

  return (
    <Grid className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Input name="name" label="Nome" control={control} errors={errors} />
        </Grid>

        {hideIfEditPlan()}
      </Grid>
    </Grid>
  )
}
