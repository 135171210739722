import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { Link, animateScroll as scroll, animateScroll } from "react-scroll"

import TableHead from "./TableHead"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: 15,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  papper: {
    width: "100%",
    borderRadius: 6,
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 53px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  table: {
    width: "100%",
  },
  titles: {
    height: 40,
  },
  title: {
    fontWeight: 500,
    fontFamily: "Quicksand",
    color: colors.darkText,
    fontSize: 18,
  },
  pagination: {
    marginTop: 20,
    fontWeight: 400,
    fontFamily: "Quicksand",
    color: colors.grayText,
  },
})

class GeneralTable extends Component {
  handleChangePage = (event, newPage) => {
    this.props.changePage(newPage)
    animateScroll.scrollToBottom()
  }

  handleChangeRowsPerPage = (event) => this.props.changeRows(event)

  handleRenderRow = (row, index) => {
    return React.cloneElement(this.props.row, { row, index })
  }

  render() {
    const {
      classes,
      headConfig,
      data,
      title,
      subtitle,
      currentPage,
      rowsPerPage,
      count = data && data.length,
      pagination = "front",
    } = this.props

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, data.length - currentPage * rowsPerPage)

    return (
      <Grid container justify="center" className={classes.root}>
        <Paper className={classes.papper}>
          {title || subtitle ? (
            <Grid container className={classes.titles}>
              <Grid item xs={12} md={6}>
                <Typography align="left" className={classes.title}>
                  {subtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="right" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <TableContainer>
            <Table className={classes.table}>
              <TableHead data={headConfig} />
              <TableBody>
                {data &&
                  (rowsPerPage > 0 && pagination === "front"
                    ? data.slice(
                        currentPage * rowsPerPage,
                        currentPage * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row, index) => (
                    <Fragment>
                      {this.handleRenderRow(
                        row,
                        rowsPerPage * currentPage + 1 + index
                      )}
                    </Fragment>
                  ))}

                {pagination === "front" && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[5, 10, 15, 20, 30]}
            component="div"
            count={count}
            labelDisplayedRows={({ from, to, count }) =>
              pagination === "front"
                ? `${from}-${to} de ${count}`
                : `${rowsPerPage * currentPage + 1}-${Math.min(
                    (currentPage + 1) * rowsPerPage,
                    count
                  )} de ${count}`
            }
            labelRowsPerPage={"Por página"}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    )
  }
}

export default withStyles(styles)(GeneralTable)
