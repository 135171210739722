import c from "../constants/messageConstants"

const initialState = {
  messages: [],
  loading: false,
  editLoading: false,
  error: false,
  currentPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  messagesCount: 0,
  messagesPerPage: 5,
  next: null,
  prev: null,
  totalPages: 1,
}

export default function message(state = initialState, action) {
  switch (action.type) {
    case c.MESSAGE_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.MESSAGE_SUCCESS: {
      return {
        ...state,
        messages: action.payload.messages,
        currentPage: action.payload.currentPage,
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        messagesCount: action.payload.messagesCount,
        messagesPerPage: action.payload.messagesPerPage,
        next: action.payload.next,
        prev: action.payload.prev,
        totalPages: action.payload.totalPages,
        loading: false,
        error: false,
      }
    }
    case c.MESSAGE_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }
    case c.EDIT_MESSAGE_REQUEST: {
      return {
        ...state,
        error: false,
        editLoading: true,
      }
    }
    case c.EDIT_MESSAGE_SUCCESS: {
      return {
        ...state,
        error: false,
        editLoading: false,
      }
    }
    default:
      return { ...state }
  }
}
