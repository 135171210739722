import React, { Component, Fragment, useEffect } from "react"

import { withStyles, makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"

import { connect, useSelector, useDispatch } from "react-redux"
import { forgotPassword } from "@redux/actions/passwordActions"

import { useForm } from "react-hook-form"

import forgotPasswordResolver from "@api/validation/resolvers/forgotPasswordResolver"

// import Input from "@components/UI/Input/DefaultInput"
import Input from "@components/UI/Input/FormInput"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import SecondaryButton from "@components/UI/Button/SecondaryButton"
import Loading from "@components/UI/Loading/Loading"

import colors from "@styles/colors"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 16,
  },

  title: {
    fontSize: 45,
    fontFamily: "Quicksand",
    marginRight: "3rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },

  text: {
    marginRight: "3rem",
    marginLeft: "3rem",
    color: colors.grayText,
  },

  input: {
    marginTop: "1rem",
  },

  link: {
    textDecoration: "none",
  },

  email: {
    width: "80%",
    marginTop: "1.5rem",
  },

  buttons: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}))

export default function ForgotPasswordInput() {
  const classes = useStyles()

  const { error, loading } = useSelector((state) => state.get("password"))
  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: forgotPasswordResolver(),
  })

  useEffect(() => {
    if (error) {
      setError(
        "email",
        {
          type: "manual",
          message: "Falha ao enviar o email de confirmação",
        },
        { shouldFocus: true }
      )
    }
  }, [error])

  const handleSubmitForgotPassword = ({ email }) => {
    dispatch(forgotPassword({ email }))
  }

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(handleSubmitForgotPassword)}
      className={classes.paper}
      elevation={3}
    >
      <Typography className={classes.title}>Mantenedores</Typography>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.input}
      >
        <Grid container justify="center" alignItems="center">
          <Typography className={classes.text}>
            Informe seu email para que possamos enviar uma mensagem de
            recuperação de senha
          </Typography>
        </Grid>
        <Input
          label="Email"
          name="email"
          type="email"
          size="small"
          className={classes.email}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid className={classes.buttons} container justify="center">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Link className={classes.link} to="/login">
              <SecondaryButton onClick={() => null}>CANCELAR</SecondaryButton>
            </Link>
            <PrimaryButton type="submit">ALTERAR SENHA</PrimaryButton>
          </Fragment>
        )}
      </Grid>
    </Paper>
  )
}
