import React from "react"
import "./styles/App.css"
import { withStyles } from "@material-ui/core/styles"
import { BrowserRouter } from "react-router-dom"
import store from "@redux/configureStore"
import { Provider } from "react-redux"
import Grid from "@material-ui/core/Grid"

import App from "@components/Routes/RoutesGeneral"

const styles = (theme) => ({})

// const store = configureStore()

class MyApp extends React.Component {
  render() {
    return (
      <Grid className="App">
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </Grid>
    )
  }
}

export default withStyles(styles)(MyApp)
