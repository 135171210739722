import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid } from "@material-ui/core"

import moment from "moment"
import ButtonPrimary from "@components/UI/Button/PrimaryButton"
import Input from "@components/UI/Input/DefaultInput"

import { alert } from "@redux/actions/alertActions"

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: 32,
  },
  title: {
    fontFamily: "Quicksand",
    fontSize: 30,
  },
  text: {
    fontFamily: "Quicksand",
    fontSize: 22,
  },
  description: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  buttons: {
    paddingTop: 24,
    paddingBottom: 16,
    gap: 12,
  },
  button: {
    width: 130,
  },
  field: {
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(28),
    },
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default function FilterChat(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [initialDate, setInitialDate] = useState(
    moment().subtract(10, "days").format("YYYY-MM-DD")
  )
  const [finalDate, setFinalDate] = useState(moment().format("YYYY-MM-DD"))

  const { handleSubmit } = props

  const handleSearchFilter = () => {
    if (initialDate != "" && finalDate != "") {
      handleSubmit(initialDate, finalDate)
      setInitialDate("")
      setFinalDate("")
    } else {
      dispatch(alert("error", "Preencha o(s) campo(s) de data"))
    }
  }

  const handleSelectDateInitial = (e) => {
    const dateIn = new Date(e.target.value)
    const dateEn = new Date(finalDate)
    if (dateIn > dateEn) {
      dispatch(
        alert("error", "Selecione uma data que seja anterior à data final")
      )
    } else {
      setInitialDate(e.target.value)
    }
  }

  const handleSelectDateEnd = (e) => {
    const dateIn = new Date(initialDate)
    const dateEn = new Date(e.target.value)
    if (dateIn > dateEn) {
      dispatch(
        alert("error", "Selecione uma data que seja posterior à data inicial")
      )
    } else {
      setFinalDate(e.target.value)
    }
  }

  return (
    <Grid container>
      <Grid container justify="center" alignItems="center">
        <Grid
          container
          md={12}
          justify="space-between"
          alignItems="space-between"
        >
          <Grid item md={4} className={classes.gridItem}>
            <Input
              name="Date"
              type="date"
              value={initialDate}
              onChange={handleSelectDateInitial}
              className={classes.field}
            />
          </Grid>
          <Grid item md={1} className={classes.gridItem}>
            <Typography className={classes.text}>a</Typography>
          </Grid>
          <Grid item md={4} className={classes.gridItem}>
            <Input
              name="Date"
              type="date"
              value={finalDate}
              onChange={handleSelectDateEnd}
              className={classes.field}
            />
          </Grid>
          <Grid item md={2} className={classes.gridItem}>
            <ButtonPrimary
              className={classes.button}
              onClick={handleSearchFilter}
            >
              FILTRAR
            </ButtonPrimary>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
