import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { TextField, Grid } from "@material-ui/core"
import classNames from "classnames"
import AmountMask from "@components/Masks/AmountMask"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    paddingTop: 6,
  },
  input: {
    marginTop: 20,
    maxWidth: 160,
    "& .MuiOutlinedInput-root": {
      borderRadius: 70,
      maxHeight: 48,
      "&.Mui-focused fieldset": {
        borderColor: colors.orange,
      },
    },
    "& label.Mui-focused": {
      color: colors.orange,
    },
  },
  inputActive: {
    "& input:valid + fieldset": {
      borderColor: colors.orange,
    },
    "& label": {
      color: colors.orange,
    },
  },
})

class AmountInput extends Component {
  isWithError = () => {
    const { errors, name, error } = this.props
    if ((errors && errors.includes(name)) || error) {
      return true
    }
    return false
  }

  render() {
    const {
      classes,
      onChange,
      value,
      inputComponent,
      shrink,
      className,
      errors,
      onBlur,
      ...rest
    } = this.props
    return (
      <Grid container className={classes.root}>
        <TextField
          fullWidth
          className={classNames(
            classes.input,
            value > 0 ? classes.inputActive : null
          )}
          variant="outlined"
          InputProps={{
            value,
            onChange,
            inputComponent: AmountMask,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          error={this.isWithError()}
          onBlur={onBlur}
          {...rest}
        />
      </Grid>
    )
  }
}

export default withStyles(styles)(AmountInput)
