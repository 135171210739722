import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Grid, Paper, Typography } from "@material-ui/core"
import TableOptionButton from "@components/UI/Table/TableOptionButton"
import Loading from "@components/UI/Loading/Loading"

import {
  deleteDonation,
  getCurrentDonation,
  getTransactions,
} from "@redux/actions/donationActions"
import colors from "@styles/colors"
import { formatDonationAmount } from "@api/format/formatDonationAmount"
import moment from "moment"
import { formatDonationType } from "@api/format/formatDonationType"

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: 24,
  },
  titleDonation: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  defaultGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  donationActive: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  amount: {
    fontSize: 49,
    fontFamily: "Quicksand",
    color: colors.primaryColor,
    fontWeight: 600,
  },
  amountPending: {
    fontSize: 35,
    fontFamily: "Quicksand",
    color: colors.secondaryColor,
    fontWeight: 600,
  },
  text: {
    fontSize: 20,
    color: colors.primaryColor,
    paddingTop: 16,
  },
  textSecondary: {
    fontSize: 20,
    color: colors.secondaryColor,
    paddingTop: 16,
  },
  situationDonation: {
    fontSize: 16,
    color: colors.secondaryColor,
  },
  creditCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: colors.grayText,
    marginTop: 16,
  },
  name: {
    marginTop: 8,
    color: colors.grayText,
  },
  textContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  donationDisabled: {
    fontSize: 24,
    fontFamily: "Quicksand",
    color: colors.orange,
    fontWeight: 600,
    marginTop: 16,
  },
  highlighted: {
    fontWeight: 600,
    color: colors.primaryColor,
  },
}))

export default function CardDonationsActive(props) {
  const classes = useStyles()

  const history = useHistory()
  const { id } = useParams()

  const { donationLoader: isLoading, donation } = useSelector((state) =>
    state.get("donation")
  )
  const { error } = useSelector((state) => state.get("maintainer"))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrentDonation(id))
  }, [id])

  const handleAddDonation = () => {
    history.push(`/app/mantenedor/${id}/doacao`)
  }

  const handleRenewDonation = () => {
    history.push(`/app/renovar-mantenedor/${id}`)
  }

  const handleEditDonation = () => {
    history.push(`/app/mantenedor/${id}/editar-doacao`)
  }

  const deleteDonationCallback = () => {
    dispatch(getCurrentDonation(id))
    dispatch(getTransactions(id, 1, 6))
  }

  const handleDeleteDonation = () => {
    dispatch(
      deleteDonation(
        donation.gatewayId,
        donation.donationType,
        deleteDonationCallback
      )
    )
  }

  const verifyRenovation = () => {
    if (donation) {
      if (
        donation.donationType === "subscription" &&
        donation.status === "paid" &&
        donation.toRenew
      ) {
        return true
      }
    }

    return false
  }

  const showInfoDonations = () => {
    if (!donation) {
      return (
        <Grid item md={12} className={classes.textContainer}>
          <Typography className={classes.donationDisabled}>
            Sem doações ativas
          </Typography>
        </Grid>
      )
    }

    return (
      <>
        <Grid item md={12} className={classes.donationActive}>
          <Grid className={classes.donationActive}>
            <Typography className={classes.amount}>
              {formatDonationAmount(donation.amount)}
            </Typography>

            {donation.donationType === "subscription" ? (
              donation.frequency === "Annually" ? (
                <Typography className={classes.text}>/ano</Typography>
              ) : (
                <Typography className={classes.text}>/mês</Typography>
              )
            ) : (
              <Typography className={classes.text}>/mês</Typography>
            )}
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Typography className={classes.name}>
            Tipo de pagamento:{" "}
            <span className={classes.highlighted}>
              {formatDonationType(donation.donationType)}
            </span>
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <Typography className={classes.name}>
            Data da próxima parcela:{" "}
            <span className={classes.highlighted}>
              {moment(new Date(donation.nextPaymentDate)).format("DD/MM/YYYY")}
            </span>
          </Typography>
        </Grid>
      </>
    )
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      {isLoading ? (
        <Loading />
      ) : !error ? (
        <Grid container>
          <Grid item md={12} className={classes.defaultGrid}>
            <Typography className={classes.titleDonation}>
              Doação Ativa
            </Typography>

            <Grid>
              {verifyRenovation() ? (
                <TableOptionButton
                  isTable={false}
                  type="renovation"
                  funct={handleRenewDonation}
                />
              ) : null}

              {!donation ? (
                <TableOptionButton
                  isTable={false}
                  type="add"
                  funct={handleAddDonation}
                />
              ) : (
                <>
                  <TableOptionButton
                    isTable={false}
                    type="del"
                    funct={handleDeleteDonation}
                  />
                  {donation.donationType !== "booklet" && (
                    <TableOptionButton
                      isTable={false}
                      type="edit"
                      funct={handleEditDonation}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>

          {showInfoDonations()}
        </Grid>
      ) : (
        <Grid item md={12} className={classes.textContainer}>
          <Typography className={classes.donationDisabled}>
            Sem doações Ativas
          </Typography>
        </Grid>
      )}
    </Paper>
  )
}
