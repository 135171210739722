import React, { Component, Fragment } from "react"
import { withStyles, Grid } from "@material-ui/core"
import { connect } from "react-redux"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import hexToRgba from "hex-to-rgba"
import TableOptionButton from "@components/UI/Table/TableOptionButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    cursor: "pointer",
    width: "80%",
    "&:hover": {
      opacity: 0.8,
    },
  },
  tableCell: {
    fontWeight: 400,
    fontFamily: "Quicksand",
    fontSize: 15,
    color: colors.grayText,
    border: "none",
  },
  tableCellYellow: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: hexToRgba(colors.yellow, "0.5"),
    borderRadius: 49,
    color: colors.grayText,
  },
  tableIcon: {
    padding: 8,
  },
  markedWithRed: {
    backgroundColor: hexToRgba(colors.red, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.red, "0.1"),
    },
  },
  markedWithGreen: {
    backgroundColor: hexToRgba(colors.green, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.green, "0.1"),
    },
  },
})

class CustomTableRow extends Component {
  handleClickRow = () => {
    this.props.handleClickRow(this.props.row)
  }

  render() {
    const {
      classes,
      index,
      row,
      handleDeleteRow,
      tableCellOptions,
      button = true,
    } = this.props
    return (
      <TableRow
        key={index}
        classes={{
          root: classes.root,
        }}
      >
        <TableCell
          component="th"
          scope="row"
          className={classes.tableCell}
          onClick={this.handleClickRow}
        >
          {index}
        </TableCell>
        {tableCellOptions.map((name) => (
          <TableCell
            component="th"
            scope="row"
            align="left"
            className={classes.tableCell}
            onClick={this.handleClickRow}
          >
            {row[name]}
          </TableCell>
        ))}
        {button ? (
          <Grid container justify="flex-end">
            <TableOptionButton type="del" funct={() => handleDeleteRow(row)} />
          </Grid>
        ) : null}
        {/* <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.name}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.email}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.register}
        </TableCell> */}
      </TableRow>
    )
  }
}

export default connect()(withStyles(styles)(CustomTableRow))
