import c from "../constants/teamConstants"
import formatDate from "@api/format/team/formatDate"
import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"

import qs from "qs"
import { RequestSDK } from "@sdk"

export const createAdmin = (email) => {
  return (dispatch) => {
    dispatch(createAdminRequest())
    RequestSDK.admins
      .createAdmin(email)
      .then((resp) => {
        dispatch(createAdminSuccess(resp.data))
        dispatch(getUsers(1, 10, ["admin"]))
      })
      .catch((error) => {
        dispatch(createAdminFailure())
      })
  }
}

export const createAdmins = (emails) => {
  return (dispatch) => {
    dispatch(createAdminRequest())
    RequestSDK.admins
      .createAdmins(emails)
      .then((resp) => {
        dispatch(getUsers(1, 10, ["admin"]))
        dispatch(alert("success", "Usuários adicionados com sucesso!"))
      })
      .catch((error) => {
        dispatch(createAdminFailure())
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const getUsersRequest = () => {
  return {
    type: c.GET_USERS_REQUEST,
  }
}

export const createAdminRequest = () => {
  return {
    type: c.CREATE_ADMIN_REQUEST,
  }
}

export const createAdminSuccess = (payload) => {
  return {
    type: c.CREATE_ADMIN_SUCCESS,
    payload,
  }
}

export const createAdminFailure = () => {
  return {
    type: c.CREATE_ADMIN_FAILURE,
  }
}

export const getUsers = (page = 1, rowsPerPage = 5, roles = []) => {
  return (dispatch) => {
    dispatch(getUsersRequest())
    RequestSDK.users
      .getUsers(page, rowsPerPage, roles, qs)
      .then((response) => {
        dispatch(getUsersSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getUsersFailure())
      })
  }
}

export const getAdmin = (email, isRegister = false) => {
  return (dispatch) => {
    if (!isRegister) {
      dispatch(clearAdmin())
      return RequestSDK.admins
        .getAdmin(email)
        .then((response) => {
          const admin = {
            createdAt: formatDate(response.data.createdAt).date,
            email: response.data.email,
            id: response.data.user_id,
          }
          dispatch(getAdminSuccess(admin))
          dispatch(alert("success", "Adicionado á lista com sucesso!"))
        })
        .catch((error) => {
          dispatch(getAdminFailure())
          dispatch(alert("error", getErrorMessage(error)))
        })
    } else {
      dispatch(alert("error", "Usuário já inserido"))
    }
  }
}

export const removeAdmin = (user) => {
  return (dispatch) => {
    dispatch(createAdminRequest())
    const roles = user.type.filter((role) => role !== "admin")
    return RequestSDK.users
      .removeAdmin(user.email, roles)
      .then((response) => {
        dispatch(getUsers(1, 10, ["admin"]))
        dispatch(dispatch(alert("success", "Usuário removido com sucesso!")))
      })
      .catch((error) => {
        dispatch(getUsersFailure())
      })
  }
}

export const removeAdminSuccess = (payload) => {
  return {
    type: c.REMOVE_ADMIN_SUCCESS,
    payload,
  }
}

export const getAdminsSuccess = (payload) => {
  return {
    type: c.GET_ADMINS_SUCCESS,
    payload,
  }
}

export const getUsersSuccess = (payload) => {
  return {
    type: c.GET_USERS_SUCCESS,
    payload,
  }
}

export const getAdminSuccess = (payload) => {
  return {
    type: c.GET_ADMIN_SUCCESS,
    payload,
  }
}

export const getAdminFailure = () => {
  return {
    type: c.GET_ADMIN_FAILURE,
  }
}

export const getUsersFailure = () => {
  return {
    type: c.GET_USERS_FAILURE,
  }
}

export const clearAdmin = () => {
  return {
    type: c.CLEAR_ADMIN,
  }
}
