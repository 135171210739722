import React, { Component, Fragment } from "react"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"
import { Menu, Tooltip } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"

import tableIconTypes from "@api/tables/tableIconTypes"
import colors from "@styles/colors"

const styles = (theme) => ({
  tableCell: {
    fontWeight: 400,
    fontFamily: "Quicksand",
    fontSize: 15,
    color: colors.grayText,
    border: "none",
    paddingTop: 10,
    paddingBottom: 10,
  },
  paddingNoTable: {
    padding: 0,
    paddingLeft: 16,
  },
  tableIcon: {
    padding: 8,
  },
  iconPrimary: {
    backgroundColor: colors.primaryColor,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  iconOrange: {
    backgroundColor: colors.orange,
    "&:hover": {
      backgroundColor: colors.orange,
    },
  },
  iconSecondaryOrange: {
    backgroundColor: colors.secondaryColor,
    "&:hover": {
      backgroundColor: colors.secondaryColor,
    },
  },
  addButton: {
    padding: 2,
  },
})

class CustomTableButton extends Component {
  state = {
    open: null,
  }

  handleClick = (event) => this.setState({ open: event.currentTarget })

  handleClose = () => this.setState({ open: null })

  handleSubmit = () => {
    this.props.funct()
    this.handleClose()
  }

  handleRender = () => {
    const { classes, type, funct, isTable = true } = this.props
    const { open } = this.state

    return (
      <TableCell
        className={classNames(
          classes.tableCell,
          !isTable ? classes.paddingNoTable : null
        )}
        align="center"
      >
        <Tooltip title={tableIconTypes[type].alt}>
          <IconButton
            className={classNames(
              classes.tableIcon,
              classes[tableIconTypes[type].class]
            )}
            onClick={
              type === "del" ||
              type === "blockMaintainer" ||
              type === "unlockMaintainer"
                ? this.handleClick
                : () => funct()
            }
          >
            <img
              src={tableIconTypes[type].icon}
              alt={tableIconTypes[type].alt}
              className={type == "add" ? classes.addButton : null}
            />
          </IconButton>
        </Tooltip>
        {type === "del" ||
        type === "blockMaintainer" ||
        type === "unlockMaintainer" ? (
          <Menu
            id="menu"
            anchorEl={open}
            open={Boolean(open)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleSubmit}>
              {tableIconTypes[type].alt}
            </MenuItem>
          </Menu>
        ) : null}
      </TableCell>
    )
  }

  render() {
    const { type } = this.props

    if (!tableIconTypes[type]) return null

    return <Fragment>{this.handleRender()}</Fragment>
  }
}

export default withStyles(styles)(CustomTableButton)
