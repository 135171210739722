import React from "react"
import { Controller } from "react-hook-form"

import { makeStyles } from "@material-ui/core/styles"

import { TextField } from "@material-ui/core"

import classnames from "classnames"

const useStyles = makeStyles({
  input: {
    marginTop: 20,
  },
})

export default function FormInput(props) {
  const classes = useStyles()

  const {
    control,
    name,
    inputComponent,
    className,
    errors,
    error,
    endIcon,
    ...rest
  } = props

  const isWithError = () => {
    if ((errors && errors[name]) || error) {
      return true
    }

    return false
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <TextField
          fullWidth
          className={classnames(classes.input, className)}
          variant="outlined"
          error={isWithError()}
          InputProps={{
            inputComponent,
          }}
          onChange={onChange}
          value={value || ""}
          name={name}
          endIcon={endIcon}
          {...rest}
        />
      )}
    />
  )
}
