import formatDateAndTime from "@api/format/formatDateAndTime"

export const tableShipsmentsEmail = (messages) => {
  return messages.map((message) => {
    const dateTime = formatDateAndTime(message.createdAt)
    return {
      email: message.email,
      text: message.title,
      date: `${dateTime.date} ${dateTime.time}`,
    }
  })
}

export const tableShipsmentsSMS = (messages) => {
  return messages.map((message) => {
    const dateTime = formatDateAndTime(message.createdAt)
    return {
      email: message.email,
      // phone: `(${message.phone.ddd}) ${message.phone.ddi} ${message.phone.number}`,
      situation: "",
      text: message.title,
      date: `${dateTime.date} ${dateTime.time}`,
    }
  })
}
