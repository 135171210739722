import c from "@redux/constants/originsConstants"
import getErrorMessage from "@api/utils/getErrorMessage"
import { alert } from "@redux/actions/alertActions"
import { RequestSDK } from "@sdk"

export const originRequest = () => {
  return {
    type: c.ORIGIN_REQUEST,
  }
}

export const originSuccess = (payload) => {
  return {
    type: c.ORIGIN_SUCCESS,
    payload,
  }
}

export const originFailure = () => {
  return {
    type: c.ORIGIN_FAILURE,
  }
}

export const originPaginatedRequest = () => {
  return {
    type: c.ORIGIN_PAGINATED_REQUEST,
  }
}

export const originPaginatedSuccess = (payload) => {
  return {
    type: c.ORIGIN_PAGINATED_SUCCESS,
    payload,
  }
}

export const originPaginatedFailure = () => {
  return {
    type: c.ORIGIN_PAGINATED_FAILURE,
  }
}

export const getOriginsPaginated = (page = 1, perPage = 5, search = "") => {
  return (dispatch) => {
    dispatch(originRequest())

    RequestSDK.origins
      .getOrigins(search, page, perPage)
      .then((response) => {
        const data = response.data

        const formattedOrigins = data.origins.map((origin) => {
          return {
            id: origin._id,
            name: origin.name,
          }
        })

        const formattedPayload = {
          ...data,
          origins: formattedOrigins,
        }

        dispatch(originPaginatedSuccess(formattedPayload))
      })
      .catch((err) => {
        dispatch(originFailure(err.response))
      })
  }
}

export const getOrigin = (originId) => {
  return (dispatch) => {
    dispatch(originRequest())
    RequestSDK.origins
      .getOrigin(originId)
      .then((res) => {
        const formattedOrigin = res.data.map((origin) => {
          return {
            id: origin._id,
            name: origin.name,
          }
        })
        dispatch(originSuccess(formattedOrigin))
      })
      .catch((err) => {
        dispatch(originFailure(err.response))
      })
  }
}

export const createOrigin = (
  name,
  paginatedOrigin = false,
  submitCallbackFunction = false
) => {
  return (dispatch) => {
    RequestSDK.origins
      .createOrigin(name)
      .then((response) => {
        const data = response.data

        dispatch(createOriginSuccess())

        if (paginatedOrigin)
          dispatch(
            getOriginsPaginated(
              paginatedOrigin.page,
              paginatedOrigin.rowsPerPage,
              paginatedOrigin.searchValue
            )
          )

        if (
          submitCallbackFunction &&
          typeof submitCallbackFunction === "function"
        )
          submitCallbackFunction(data)

        dispatch(alert("success", "Origem cadastrada com sucesso!"))
      })
      .catch((err) => {
        dispatch(originFailure(err.response))
        dispatch(alert("error", getErrorMessage(err)))
      })
  }
}

export const createOriginSuccess = () => {
  return {
    type: c.CREATE_ORIGIN_SUCCESS,
  }
}

export const deleteOrigin = (originId, { page, rowsPerPage, searchValue }) => {
  return (dispatch) => {
    RequestSDK.origins
      .deleteOrigin(originId)
      .then((response) => {
        dispatch(deleteOriginSuccess())
        dispatch(getOriginsPaginated(page, rowsPerPage, searchValue))

        dispatch(alert("success", "Origem deletada com sucesso!"))
      })
      .catch((err) => {
        dispatch(originFailure(err.response))
        dispatch(alert("error", getErrorMessage(err)))
      })
  }
}

export const deleteOriginSuccess = () => {
  return {
    type: c.DELETE_ORIGIN_SUCCESS,
  }
}

export const editOrigin = (
  originId,
  value,
  { page, rowsPerPage, searchValue }
) => {
  return (dispatch) => {
    RequestSDK.origins
      .editOrigin(originId, value)
      .then((response) => {
        dispatch(editOriginSuccess())
        dispatch(getOriginsPaginated(page, rowsPerPage, searchValue))

        dispatch(alert("success", "Origem editada com sucesso!"))
      })
      .catch((err) => {
        dispatch(originFailure(err.response))
        dispatch(alert("error", getErrorMessage(err)))
      })
  }
}

export const editOriginSuccess = () => {
  return {
    type: c.EDIT_ORIGIN_SUCCESS,
  }
}
