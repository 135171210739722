import TeamTable from "@components/Team/TeamTable"
import ConstructionPage from "@components/UI/ConstructionPage/index"

export default function teamPages() {
  return [
    {
      name: "Todos",
      path: "todos/:p",
      component: () => <TeamTable roles={["admin"]} route="todos" />,
    },
    {
      name: "Administradores",
      path: "administradores/:p",
      component: () => <TeamTable roles={["admin"]} route="administradores" />,
    },
    {
      name: "Motoqueiros",
      path: "motoqueiros",
      component: ConstructionPage,
    },
  ]
}
