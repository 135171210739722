import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Snackbar from "@components/UI/Snackbar/Snackbar"
//Páginas

//Sign
import Login from "@pages/Login"
import Register from "@pages/Register"

//Dashboard
import Dashboard from "@components/Template/Dashboard"
import ForgotPassword from "@pages/ForgotPassword"
import ChangePassword from "@pages/ChangePassword"

const useStyles = makeStyles({
  root: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
})

export default function RoutesGeneral() {
  const classes = useStyles()

  return (
    <Grid>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/registrar" component={Register} />
        <Route exact path="/mudar-senha" component={ChangePassword} />
        <Route exact path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route path="/app" component={Dashboard} />
        <Redirect to="/login" />
      </Switch>
      <Snackbar />
    </Grid>
  )
}
