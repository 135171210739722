import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"

import DefaultButton from "./DefaultButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  button: {
    color: colors.whiteText,
    width: 180,
    height: 48,
    marginRight: 10,
    backgroundColor: colors.orange,
    "&:hover": {
      backgroundColor: colors.orange,
    },
  },
})

class CancelButton extends Component {
  render() {
    const { classes, children, ...rest } = this.props
    return (
      <DefaultButton className={classes.button} {...rest}>
        {children}
      </DefaultButton>
    )
  }
}

export default withStyles(styles)(CancelButton)
