//Options
import Management from "@components/Accounting/Management"
import Overdue from "@components/Accounting/Overdue"
import ConstructionPage from "@components/UI/ConstructionPage"

export default () => {
  return [
    {
      name: "Gerenciar",
      path: "gerenciar",
      component: ConstructionPage,
    },
    {
      name: "Vencimentos",
      path: "vencimentos",
      component: ConstructionPage,
    },
    {
      name: "Gerar Relatórios",
      path: "gerar-relatorios",
      component: ConstructionPage,
    },
  ]
}
