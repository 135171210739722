import React, { Component, Fragment } from "react"
import { withStyles, Typography } from "@material-ui/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import hexToRgba from "hex-to-rgba"
import TableOptionButton from "@components/UI/Table/TableOptionButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  tableCell: {
    fontWeight: 400,
    fontFamily: "Quicksand",
    fontSize: 15,
    color: colors.grayText,
    border: "none",
  },
  tableCellYellow: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: hexToRgba(colors.yellow, "0.5"),
    borderRadius: 49,
    color: colors.grayText,
  },
  tableIcon: {
    padding: 8,
  },
  markedWithRed: {
    backgroundColor: hexToRgba(colors.red, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.red, "0.1"),
    },
  },
  markedWithGreen: {
    backgroundColor: hexToRgba(colors.green, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.green, "0.1"),
    },
  },
})

class CustomTableRow extends Component {
  handleShowInfoRow = () => {} //Implementar

  render() {
    const { classes, index, row, type } = this.props

    return (
      <TableRow
        key={index}
        classes={{
          root: classes.root,
        }}
      >
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {index}
        </TableCell>
        {type == "email" ? (
          <Fragment>
            <TableCell
              component="th"
              scope="row"
              align="left"
              className={classes.tableCell}
            >
              {row.email}
            </TableCell>

            <TableCell
              component="th"
              scope="row"
              align="left"
              className={classes.tableCell}
            >
              {row.text}
            </TableCell>
          </Fragment>
        ) : (
          <TableCell
            component="th"
            scope="row"
            align="left"
            className={classes.tableCell}
          >
            {row.phone}
          </TableCell>
        )}

        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.date}
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(CustomTableRow)
