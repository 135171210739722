import c from "@redux/constants/plansConstants"

const initialState = {
  plans: [],
  plan: {},
  loading: false,
  allLoaded: false,
  error: null,
  rowsPerPage: 5,
  totalPages: 1,
  rowsCount: 1,
  currentPage: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prev: null,
  next: null,
}

export default function plan(state = initialState, action) {
  switch (action.type) {
    case c.PLANS_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case c.PLANS_SUCCESS: {
      return {
        ...state,
        error: false,
        allLoaded: true,
        loading: false,
        plans: action.payload,
      }
    }
    case c.GET_PLAN_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
        plan: action.payload,
      }
    }
    case c.PLANS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case c.PLANS_PAGINATED_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.PLANS_PAGINATED_SUCCESS: {
      return {
        ...state,
        plans: action.payload.plans,
        rowsPerPage: action.payload.plansPerPage,
        totalPages: action.payload.totalPages,
        rowsCount: action.payload.plansCount,
        currentPage: action.payload.currentPage,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prev: action.payload.prev,
        next: action.payload.next,
        loading: false,
        allLoaded: false,
        error: false,
      }
    }
    case c.PLANS_PAGINATED_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }

    case c.CREATE_PLAN_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }
    case c.EDIT_PLAN_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }
    case c.DELETE_PLAN_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }
    default:
      return state
  }
}
