import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

import { useDebounce } from "use-debounce"

import { useSelector, useDispatch } from "react-redux"

// import Autocomplete from "./Autocomplete"
import Autocomplete from "./AutocompleteForm"
import AddButton from "@components/UI/Button/AddButton"
import Modal from "@components/UI/Modal/Modal"

import { createChurch } from "@redux/actions/churchActions"
import { RequestSDK } from "@sdk"

const useStyles = makeStyles({
  button: {},
})

export default function ChurchForm(props) {
  const classes = useStyles()

  const [churches, setChurches] = useState([])
  const [church, setChurch] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [debouncedText] = useDebounce(church, 300)

  const dispatch = useDispatch()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [addChurch, setAddChurch] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const { control, errors, getValues, reset, trigger } = props

  const handleChangeDebounceText = (value) => {
    setIsLoading(true)
    setChurch(value)
  }

  const loadChurches = async () => {
    setIsLoading(true)

    RequestSDK.churches
      .getChurches(debouncedText)
      .then((res) => {
        const data = res.data

        setChurches(data.churches)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    loadChurches()
  }, [debouncedText])

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleToggleButton = (value, inputValue) => {
    const church = getValues("church")

    setAddChurch(value && church?.name !== inputValue)
    setInputValue(inputValue)
  }

  const submitCallbackFunction = (data) => {
    const values = getValues()

    setChurch(data.name)

    reset({
      ...values,
      church: data,
    })

    setInputValue("")

    trigger("church")
  }

  const handleSubmitForm = (value) => {
    dispatch(createChurch(value, false, submitCallbackFunction))
    setAddChurch(false)
  }

  return (
    <Grid container justify="flex-start" spacing={4}>
      <Modal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitForm}
        buttonTitle="ADICIONAR"
        title="Adicionar Igreja"
        label="Igreja"
        value={inputValue}
        name="church"
      >
        Informe o nome da igreja no campo abaixo:
      </Modal>
      <Grid item xs={12} sm={3} md={10}>
        <Autocomplete
          options={churches}
          control={control}
          isLoading={isLoading}
          handleToggleButton={handleToggleButton}
          handleChange={handleChangeDebounceText}
          name="church"
          label="Igreja"
          errors={errors}
        />
      </Grid>
      {addChurch ? (
        <Grid item xs={12} sm={3} md={1} className={classes.button}>
          <AddButton onCLick={handleOpenModal} title="Adicionar igreja" />
        </Grid>
      ) : null}
    </Grid>
  )
}
