import c from "../constants/sentMessagesConstants"

const initialState = {
  currentPage: 0,
  hasNextPage: false,
  hasPrevPage: false,
  messages: [],
  messagesCount: 0,
  messagesPerPage: 0,
  next: null,
  prev: null,
  totalPages: 1,
  loading: false,
  error: false,
}

export default function sentMessage(state = initialState, action) {
  switch (action.type) {
    case c.SENT_MESSAGES_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.SENT_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: action.payload.messages,
        messagesCount: action.payload.messagesCount,
        currentPage: action.payload.currentPage,
        messagesPerPage: action.payload.messagesPerPage,
        next: action.payload.next,
        prev: action.payload.prev,
        totalPages: action.payload.next,
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        error: false,
        loading: false,
      }
    }
    case c.SENT_MESSAGES_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}
