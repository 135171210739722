export const maintainers = [
  "Relatórios",
  "Pessoas",
  "Pagamentos",
  "Pendências",
  "Renovações",
  "Gerar Etiqueta",
]

export const paymentMethod = ["Cartão", "Carnê"]

export const plans = [
  { value: "350", label: "R$350,00/ano" },
  { value: "250", label: "R$250,00/ano" },
  { value: "150", label: "R$350,00/ano" },
]

export const accounting = ["Gerenciar", "Vencimentos", "Gerar Relatórios"]

export const communication = ["Envios", "Fluxo de Mensagens"]

export const settings = ["Igrejas", "Origens", "Planos", "Operadoras"]
