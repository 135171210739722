const getDateAndDay = () => {
  const date = new Date()
  const daysWeek = [
    "Domingo",
    "Segunda feira",
    "Terça feira",
    "Quarta feira",
    "Quinta feira",
    "Sexta feira",
    "Sábado",
  ]
  const dayWeek = daysWeek[date.getDay()]
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const formattedDate = `${dayWeek}, ${day.toString().padStart(2, "0")}/${(
    month + 1
  )
    .toString()
    .padStart(2, "0")}/${year}`

  return formattedDate
}

export { getDateAndDay }
