import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import {
  validateText,
  validateCardNumber,
  validateFirstName,
  validateCardDate,
  validateCVV,
  validateChargeDay,
  validateParcels,
  validateParcelsNotRequired,
  validateFirstBookletInstallment,
} from "../"

export function registerDonationResolver(annually, type) {
  let validateSchema

  if (type === "credit_card") {
    validateSchema = yup.object().shape({
      number: validateCardNumber(),
      name: validateFirstName(),
      expiry: validateCardDate(),
      cvc: validateCVV(),
      chargeDay: validateChargeDay(),
      parcels: annually ? validateParcels() : validateParcelsNotRequired(),
    })
  } else {
    validateSchema = yup.object().shape({
      dueDate: validateFirstBookletInstallment(),
    })
  }

  return yupResolver(validateSchema)
}

export function editDonationResolver(annually, type) {
  let validateSchema

  if (type === "credit_card") {
    validateSchema = yup.object().shape({
      chargeDay: validateChargeDay(),
      parcels: validateParcels(),
    })
  } else {
    validateSchema = yup.object().shape({
      dueDate: validateFirstBookletInstallment(),
    })
  }

  return yupResolver(validateSchema)
}
