export default {
  CREATE_ADMIN_REQUEST: "CREATE_ADMIN_REQUEST",
  CREATE_ADMIN_SUCCESS: "CREATE_ADMIN_SUCCESS",
  CREATE_ADMIN_FAILURE: "CREATE_ADMIN_FAILURE",

  GET_ADMINS_SUCCESS: "GET_ADMINS_SUCCESS",
  GET_ADMIN_SUCCESS: "GET_ADMIN_SUCCESS",
  GET_ADMIN_FAILURE: "GET_ADMIN_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  REMOVE_ADMIN_SUCCESS: "REMOVE_ADMIN_SUCCESS",
  CLEAR_ADMIN: "CLEAR_ADMIN",
}
