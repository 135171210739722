import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { validatePassword } from "../"

export default function changePasswordLoggedInResolver() {
  const validateSchema = yup.object().shape({
    oldPassword: validatePassword(),
    newPassword: validatePassword(),
    confirmNewPassword: validatePassword(),
  })

  return yupResolver(validateSchema)
}
