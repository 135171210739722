import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Input from "@components/UI/Input/DefaultInput"

const styles = (theme) => ({})

class AutoCompleteComponent extends Component {
  inputChange = (e, newValue) => {
    const { options, value, name, buttonName } = this.props

    if (!(newValue === "" && value.length !== 1)) {
      this.props.handleChange(name, newValue)
      if (!options.find((option) => option.name == newValue)) {
        this.props.handleButton(buttonName, true)
      } else {
        this.props.handleButton(buttonName, false)
      }
    }
  }
  render() {
    const { options, name, buttonName, value, label, errors } = this.props
    return (
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.name}
        onChange={(e, newValue) => {
          if (newValue) {
            this.props.handleChange(name, newValue.name)
            this.props.handleButton(buttonName, false)
          }
        }}
        inputValue={value}
        onInputChange={this.inputChange}
        noOptionsText="Sem opções"
        renderInput={(params) => (
          <Input {...params} label={label} name={name} errors={errors} />
        )}
      />
    )
  }
}

export default withStyles(styles)(AutoCompleteComponent)
