import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import { getMaintainer } from "@redux/actions/maintainerActions"
import MantainerInfo from "@components/Mantainers/Register/MaintainerInfo"
import PaymentMethod from "@components/Mantainers/Register/PaymentMethod"
import Loading from "@components/UI/Loading/Loading"

const styles = (theme) => ({})

class AddMantainersCard extends Component {
  componentDidMount() {
    const id = this.props.match.params.id
    this.props.dispatch(getMaintainer(id))
  }

  render() {
    const { isLoading } = this.props
    return (
      <Grid>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <MantainerInfo />
            <PaymentMethod type="edit" />
          </Fragment>
        )}
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  const { maintainer, isLoading } = state.get("maintainer")
  return {
    maintainer,
    isLoading,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(AddMantainersCard))
