import formatDate from "@api/format/team/formatDate"

const tablePeoplesBody = (maintainers) => {
  if (maintainers) {
    const maintainersFormatted = maintainers.map((maintainer) => {
      const phoneFormatted = maintainer.phone
        ? `(${maintainer.phone.ddi}) ${maintainer.phone.ddd} ${maintainer.phone.number}`
        : ""
      const typeMethod = () => {
        if (maintainer.donationStatus.typeUser == "credit-card-maintainer") {
          return "Cartão de crédito"
        } else if (maintainer.donationStatus.typeUser == "booklet-maintainer") {
          return "Boleto"
        } else {
          return ""
        }
      }
      const register = () => {
        return maintainer.donationStatus.dateUpdated == "uninformed"
          ? ""
          : maintainer.donationStatus.dateUpdated
      }
      return {
        id: maintainer._id,
        name: `${maintainer.firstName} ${
          maintainer.lastName == undefined || maintainer.lastName == null
            ? ""
            : maintainer.lastName
        } `,
        email: maintainer.email,
        phone: phoneFormatted,
        register: register(),
        method: typeMethod(),
      }
    })
    return maintainersFormatted
  } else {
    return []
  }
}

export default tablePeoplesBody
