import React, { useEffect, useState } from "react"

import MaskedInput from "react-text-mask"

const cpf = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
]

const cnpj = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
]

export default function CPFCNPJMask(props) {
  const { inputRef, placeholder, ...other } = props
  const [isCpf, setIsCpf] = useState(true)

  const onlyNumbers = (sttr) => {
    if (sttr) {
      return sttr.replace(/[^0-9]/g, "")
    }

    return ""
  }

  useEffect(() => {
    const numbers = onlyNumbers(props.value).length

    if (numbers <= 11 && !isCpf) {
      setIsCpf(true)
    }
    if (numbers > 11 && isCpf) {
      setIsCpf(false)
    }
  }, [props.value, isCpf])

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={isCpf ? cpf : cnpj}
      placeholderChar={"\u2000"}
    />
  )
}
