//Mantainer

export const tablePeoplesHead = [
  "#",
  "Nome",
  "Email",
  "Telefone",
  "Cadastro",
  "Método",
]

// Bloqueados

export const tableBlockedHead = ["#", "Nome", "Email", "Telefone", "Cadastro"]

export const tablePaymentHead = [
  "#",
  "Nome",
  "Vencimento",
  "Pagamento",
  // "Parcela",
  "Valor",
  "Método",
]

export const tablePendenciesHead = [
  "#",
  "Nome",
  "Vencimento",
  // "Parcela Atrasada",
  "Método",
  "Valor",
]

export const tableRenovationsHead = [
  "#",
  "Nome",
  "Vencimento",
  // "Parcela",
  "Pagas",
  "Método",
  "Valor",
]

export const tableCanceledHead = [
  "#",
  "Nome",
  "Email",
  // "Telefone",
  "Cancelamento",
]

// Accounting

export const tableManagementHead = [
  "#",
  "Nome",
  "Vencimento",
  "Pagamento",
  "Parcela",
  "Valor",
  "Método",
]

export const tableOverdueHead = [
  "#",
  "Nome",
  "Vencimento",
  "Pagamento",
  "Parcela",
  "Valor",
  "Método",
  "Situação",
]

// Communication

export const tableShipsmentsHead = [
  "#",
  "Email",
  "Telefone",
  "Situação",
  "Texto",
  "Data e Hora",
]

// Team

export const tableTeamAllHead = [
  "#",
  "Nome",
  "Email",
  "Telefone",
  "Cadastro",
  "Tipo",
]

export const tableTeamFilteredHead = [
  "#",
  "Nome",
  "Email",
  "Telefone",
  "Cadastro",
]

export const tableAddTeamHead = ["#", "Nome", "Email"]

// Settings

export const tableOpitionsHead = ["#", "Nome"]

export const tablePlansHead = ["#", "Nome", "Valor", "Frequência"]
