import React, { useEffect, useState, Fragment } from "react"
import { useDispatch } from "react-redux"
import moment from "moment"
import { RequestSDK } from "@sdk"
import getErrorMessage from "@api/utils/getErrorMessage"

import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

import Chart from "./Chart"
import Loading from "@components/UI/Loading/Loading"

import { alert } from "@redux/actions/alertActions"

const useStyles = makeStyles((theme) => ({
  charts: {
    marginTop: 24,
  },
  grid: {
    width: "48%",
  },
}))

export default function CardDonationsActive(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [dataDoe, setDataDoe] = useState([])
  const [dataAdmin, setDataAdmin] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const request = (initialDate, finalDate, origin) => {
    setIsLoading(true)
    RequestSDK.maintainers
      .getReports(initialDate, finalDate, origin)
      .then((response) => {
        formatDataObject(
          response.data,
          origin == "doe" ? (d) => setDataDoe(d) : (d) => setDataAdmin(d)
        )
        dispatch(alert("success", "Filtro realizado com sucesso!"))
      })
      .catch((err) => dispatch(alert("error", getErrorMessage(err))))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    var startDate = moment()
    var oldDate = moment()
    var oldDate = oldDate.subtract(10, "days")
    startDate = startDate.format("YYYY-MM-DD")
    oldDate = oldDate.format("YYYY-MM-DD")
    request(oldDate, startDate, "doe")
    request(oldDate, startDate, "admin")
  }, [])

  const formatDataObject = (response, cb) => {
    const datas = Object.keys(response)
    const dataFormatted = datas.map((d) => {
      let date = new Date(d).toLocaleDateString()
      return {
        name: date,
        qtd: response[d],
      }
    })
    cb(dataFormatted)
  }

  return (
    <Grid container>
      <Grid
        container
        justify="space-between"
        alignContent="space-between"
        className={classes.charts}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Grid item className={classes.grid}>
              <Chart data={dataDoe} title="Doe" type="doe" request={request} />
            </Grid>
            <Grid item className={classes.grid}>
              <Chart
                data={dataAdmin}
                title="Admin"
                type="admin"
                request={request}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Grid>
  )
}
