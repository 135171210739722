import { format } from "date-fns"

export default (d) => {
  if (d) {
    const date = new Date(d)
    if (date) {
      return {
        date: format(date, "dd/MM/yyyy"),
        time: format(date, "H:mm"),
      }
    }
  }
  return null
}
