export class AuthenticationRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async login(data) {
    return this.requestApi({
      url: `/authentication/login`,
      method: "post",
      body: { ...data, role: "admin" },
    })
  }

  async getUser() {
    return this.requestApi({
      url: "/authentication/me",
      method: "get",
    })
  }

  async forgotPassword(body) {
    return this.requestApi({
      url: `/authentication/forgot-password`,
      method: "post",
      body,
    })
  }

  async recoverPassword(body) {
    return this.requestApi({
      url: `/authentication/recover-password`,
      method: "post",
      body,
    })
  }

  async changePassword(body) {
    return this.requestApi({
      url: `/authentication/change-password`,
      method: "patch",
      body,
    })
  }
}
