import formatDateAndTime from "@api/format/formatDateAndTime"
import formatPhone from "@api/format/formatPhone"

export const tableShipsmentsEmail = (messages) => {
  return messages.map((message) => {
    const dateTime = formatDateAndTime(message.createdAt)
    return {
      email: message.email,
      text: message.title,
      date: `${dateTime.date} ${dateTime.time}`,
    }
  })
}

export const tableShipsmentsSMS = (messages) => {
  return messages.map((message) => {
    const dateTime = formatDateAndTime(message.createdAt)
    return {
      phone: formatPhone(message.phone),
      date: `${dateTime.date} ${dateTime.time}`,
    }
  })
}
