import React, { Component, Fragment } from "react"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import hexToRgba from "hex-to-rgba"

import Button from "@material-ui/core/Button"

import colors from "@styles/colors"

const styles = (theme) => ({
  button: {
    color: colors.darkLightText,
    backgroundColor: "#fff",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Quicksand",
    borderRadius: 100,
    margin: "0 2px",
    [theme.breakpoints.down("md")]: {
      fontSize: 8,
      padding: "5px 1px",
      margin: 0,
    },
    "&:hover": {
      backgroundColor: "#fff",
      color: colors.darkLightText,
    },
  },
  buttonSelected: {
    backgroundColor: colors.primaryColor,
    color: colors.whiteText,
    "&:hover": {
      backgroundColor: colors.primaryColor,
      color: colors.whiteText,
    },
  },
  buttonSelectedCanceled: {
    backgroundColor: hexToRgba(colors.red, "0.4"),
    color: colors.whiteText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.red, "0.4"),
      color: colors.whiteText,
    },
  },
})

class SecondaryFiltersButtons extends Component {
  handleRender = (key) => {
    const { classes, filter, change } = this.props

    return (
      <Button
        disableElevation
        variant="contained"
        className={classNames(
          classes.button,
          filter === key
            ? key === "Cancelados"
              ? classes.buttonSelectedCanceled
              : classes.buttonSelected
            : null
        )}
        onClick={() => change(key)}
      >
        {key}
      </Button>
    )
  }

  handleRenderButton = (key) => {
    const { disable } = this.props

    if (disable) {
      return !disable.includes(key) ? this.handleRender(key) : null
    }

    return this.handleRender(key)
  }

  render() {
    return (
      <Fragment>
        {this.handleRenderButton("Todos")}
        {/* {this.handleRenderButton("Cartão")}
        {this.handleRenderButton("Carnê")}
        {this.handleRenderButton("Porta em Porta")}
        {this.handleRenderButton("Cancelados")} */}
      </Fragment>
    )
  }
}

export default withStyles(styles)(SecondaryFiltersButtons)
