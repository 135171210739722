import c from "../constants/alertConstants"

export const alert = (type, message) => ({
  type: c.OPEN_ALERT,
  payload: { type, message },
})

export const closeAlert = () => ({
  type: c.CLOSE_ALERT,
})
