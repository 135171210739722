import c from "@redux/constants/donationConstants"
import getFormattedDonationBody from "@api/format/getFormattedDonationBody"

import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"
import { RequestSDK } from "@sdk"

export const setField = (field, value) => {
  return {
    type: c.SET_DONATION_FIELD,
    payload: {
      field,
      value,
    },
  }
}

export const clearFields = () => {
  return {
    type: c.CLEAR_DONATION_FIELDS,
  }
}

export const registerDonation = (donationData, callbackSubmitFunction) => {
  return (dispatch) => {
    dispatch(donationRequest())
    const donationFormatted = getFormattedDonationBody(donationData)
    RequestSDK.donations
      .registerDonation(donationFormatted)
      .then((resp) => {
        dispatch(alert("success", "Doação realizada!"))
        callbackSubmitFunction()
      })
      .catch((error) => {
        dispatch(donationError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const editDonation = (donationData, type, id, callbackFunction) => {
  return (dispatch) => {
    dispatch(donationRequest())
    const donationFormatted = getFormattedDonationBody(donationData)

    RequestSDK.donations
      .editDonation(donationFormatted)
      .then((resp) => {
        dispatch(donationSuccess(donationFormatted))
        if (typeof callbackFunction === "function") {
          callbackFunction()

          if (type === "booklet")
            dispatch(
              alert("success", "Data de vencimento alterada com sucesso")
            )
        } else {
          if (type === "renovation") {
            dispatch(alert("success", "Doação renovada!"))
            window.location.replace(`/app/painel-mantenedor/${id}`)
          } else if (type === "edit") {
            dispatch(alert("success", "Doação editada"))
            window.location.replace(`/app/painel-mantenedor/${id}`)
          } else {
            dispatch(alert("success", "Doação realizada!"))
            window.location.replace("/app/mantenedores")
          }
        }
      })
      .catch((error) => {
        dispatch(donationError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const getTransactions = (id, page = 1, perPage = 5) => {
  return (dispatch) => {
    dispatch(transactionRequest())
    RequestSDK.donations
      .getTransactions(id, page, perPage)
      .then((resp) => {
        dispatch(transactionsSuccess(resp.data))
      })
      .catch((error) => {
        dispatch(donationError(error.response))
      })
  }
}

export const getDonationById = (id) => {
  return (dispatch) => {
    dispatch(donationRequest())
    RequestSDK.donations
      .getDonationById(id)
      .then((resp) => {
        dispatch(donationSuccess(resp.data))
      })
      .catch((error) => {
        dispatch(donationError(error.response))
      })
  }
}

export const getCurrentDonation = (id) => {
  return (dispatch) => {
    dispatch(donationRequest())
    RequestSDK.donations
      .getCurrentDonation(id)
      .then((resp) => {
        dispatch(currentDonationSuccess(resp.data))
      })
      .catch((error) => {
        dispatch(donationError(error.response))
      })
  }
}

export const deleteDonation = (
  gatewayId,
  donationType,
  deleteDonationCallback
) => {
  return (dispatch) => {
    RequestSDK.donations
      .deleteDonation(gatewayId, donationType)
      .then((resp) => {
        deleteDonationCallback()
        dispatch(alert("success", "Doação deletada com sucesso!"))
      })
      .catch((error) => {
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const donationRequest = () => {
  return {
    type: c.DONATION_REQUEST,
  }
}

export const transactionRequest = () => {
  return {
    type: c.TRANSACTIONS_REQUEST,
  }
}

export const transactionsSuccess = (payload) => {
  return {
    type: c.TRANSACTIONS_SUCCESS,
    payload,
  }
}

export const donationSuccess = (payload) => {
  return {
    type: c.DONATION_SUCCESS,
    payload,
  }
}

export const currentDonationSuccess = (payload) => {
  return {
    type: c.CURRENT_DONATION_SUCCESS,
    payload,
  }
}

export const donationError = (error) => {
  return {
    type: c.DONATION_FAILURE,
    error,
  }
}
