import { alert } from "@redux/actions/alertActions"
import generateTags from "@api/labels/GenerateLabel"
import { RequestSDK } from "@sdk"

export const generateLabel = (emails) => {
  return (dispatch) => {
    return RequestSDK.tags
      .generateLabel(emails)
      .then((response) => {
        generateTags(response.data)
      })
      .catch((error) => {})
  }
}

export const filterGenerateLabel = (
  initialDate,
  finalDate,
  type,
  callbackSuccess
) => {
  return (dispatch) => {
    return RequestSDK.maintainers
      .getLabelByFilter(initialDate, finalDate, type)
      .then((response) => {
        callbackSuccess(response.data)
      })
      .catch((error) => {
        dispatch(alert("error", "Ocorreu um erro!"))
      })
  }
}
