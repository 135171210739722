import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"

import Table from "@components/UI/Table/GeneralTable"
import TableRow from "./TableRow"
import {
  getPayments,
  setPaymentDonationTypeBooklet,
  setPaymentDonationTypeSubscription,
} from "@redux/actions/maintainerActions"
import { getDateAndDay } from "@api/utils/getDateAndDay"
import Loading from "@components/UI/Loading/Loading"
import AddButton from "@components/UI/Button/AddButton"
import Searchbar from "@components/UI/Input/SearchInput"
import FiltersButtonsSelect from "@components/UI/Filters/MaintainerFilterButtons"

//Dados estáticos
import { tableRenovationsHead } from "@api/tables/tablesHead"
import tableRenovationsBody from "@api/tables/Maintainers/Renovations/tableRenovationsBody"
import getURLParamsDonation from "@api/utils/getURLParamsDonation"
import { MenuItem, Select } from "@material-ui/core"

const styles = (theme) => ({})

class Renovations extends Component {
  state = {
    page: this.props.match.params.p - 1 || 0,
    rowsPerPage: 5,
    currentTab: 0,
    inputValue: "",
    filter: "Todos",
    valueFilter: "Todos os dias",
    options: [
      { name: "Todos os dias" },
      { name: "1-5" },
      { name: "5-10" },
      { name: "10-15" },
      { name: "15-20" },
      { name: "20-25" },
      { name: "25-31" },
    ],
  }

  componentDidMount() {
    const { page, rowsPerPage } = this.state
    this.props.history.replace(
      `${getURLParamsDonation(this.props.match.path)}/${this.props.currentPage}`
    )
    this.props.dispatch(
      getPayments("subscription", page + 1, rowsPerPage, "active", "renovation")
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paymentsDonationType !== this.props.paymentsDonationType) {
      const { page, rowsPerPage } = this.state
      this.props.history.replace(
        `${getURLParamsDonation(this.props.match.path)}/${
          this.props.currentPage
        }`
      )
      this.props.dispatch(
        getPayments(
          "subscription",
          page + 1,
          rowsPerPage,
          "active",
          "renovation"
        )
      )
    }
    if (prevProps.currentPage !== this.props.currentPage) {
      const page = this.props.currentPage - 1
      this.setState({ page })
      this.props.history.replace(
        `${getURLParamsDonation(this.props.match.path)}/${
          this.props.currentPage
        }`
      )
    }
    if (prevProps.rowsPerPage !== this.props.rowsPerPage) {
      this.setState({ rowsPerPage: this.props.rowsPerPage })
    }
    if (this.props.match.params.p == ":p") {
      this.props.history.replace(
        `${getURLParamsDonation(this.props.match.path)}/${
          this.props.currentPage
        }`
      )
    }
  }

  handleChangePage = (newPage) => {
    const { page, rowsPerPage } = this.state
    if (newPage > page) {
      const hasNextPage = this.props.hasNextPage
      const next = this.props.next
      if (hasNextPage && next <= this.props.totalPages) {
        this.props.history.replace(
          `${getURLParamsDonation(this.props.match.path)}/${next}`
        )
        this.props.dispatch(
          getPayments("subscription", next, rowsPerPage, "active", "renovation")
        )
      }
    } else {
      const hasPrevPage = this.props.hasPrevPage
      const prev = this.props.prev
      if (hasPrevPage) {
        this.props.history.replace(
          `${getURLParamsDonation(this.props.match.path)}/${prev}`
        )
        this.props.dispatch(
          getPayments("subscription", prev, rowsPerPage, "active", "renovation")
        )
      }
    }
  }

  handleChangeRowsPerPage = (event) => {
    this.props.dispatch(
      getPayments(
        "subscription",
        this.props.currentPage,
        parseInt(event.target.value, 10),
        "active",
        "renovation"
      )
    )
  }

  handleClickRow = (row) => {
    this.props.history.push(`/app/painel-mantenedor/${row.id}`)
  }

  handleClick = () => {
    this.props.history.push("/app/adiciona-mantenedor")
  }

  handleChangeSearchKey = ({ target }) =>
    this.setState({ inputValue: target.value })

  handleSearch = () => {
    const { inputValue } = this.state
  }

  handleChangeSelect = (e) => {
    this.setState({ valueFilter: e.target.value })
    if (e.target.value !== this.state.valueFilter) {
      this.props.dispatch(
        getPayments(
          "subscription",
          1,
          10,
          "active",
          "renovation",
          e.target.value
        )
      )
    }
  }

  handleChangeFilter = (filter) => this.setState({ filter })

  handleChangeSelectPayment = (e) => {
    if (e.target.value === "subscription") {
      this.props.dispatch(setPaymentDonationTypeSubscription())
    } else {
      this.props.dispatch(setPaymentDonationTypeBooklet())
    }
  }

  render() {
    const { classes, donations, isLoading, rowsCount } = this.props
    const {
      page,
      rowsPerPage,
      inputValue,
      filter,
      valueFilter,
      options,
    } = this.state

    return (
      <Grid container justify="center">
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                className={classes.AddButton}
              >
                <AddButton onCLick={this.handleClick} />
              </Grid>
              <Grid item xs={12} sm={11} md={11} lg={11}>
                <Searchbar
                  value={inputValue}
                  changeKey={this.handleChangeSearchKey}
                  search={this.handleSearch}
                  typeSearch="nome, email ou telefone"
                  disabled={true}
                >
                  <FiltersButtonsSelect
                    value={valueFilter}
                    filter={filter}
                    options={options}
                    changeSelect={this.handleChangeSelect}
                    change={this.handleChangeFilter}
                  />
                  <Select
                    id="payment-type-select"
                    value="subscription"
                    disabled
                    onChange={this.handleChangeSelectPayment}
                    variant="outlined"
                    style={{ height: 45 }}
                  >
                    <MenuItem value="subscription">Cartão de Crédito</MenuItem>
                    <MenuItem value="booklet">Carnê</MenuItem>
                  </Select>
                </Searchbar>
              </Grid>
            </Grid>
            <Table
              title={getDateAndDay()}
              headConfig={tableRenovationsHead}
              data={tableRenovationsBody(donations)}
              count={rowsCount}
              pagination="back"
              currentPage={page}
              rowsPerPage={rowsPerPage}
              changePage={this.handleChangePage}
              changeRows={this.handleChangeRowsPerPage}
              row={<TableRow handleClickRow={this.handleClickRow} />}
            />
          </Fragment>
        )}
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  const {
    paymentsDonationType,
    donations,
    isLoading,
    rowsPerPage,
    totalPages,
    rowsCount,
    currentPage,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
  } = state.get("maintainer")
  return {
    paymentsDonationType,
    donations,
    isLoading,
    rowsPerPage,
    totalPages,
    rowsCount,
    currentPage,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Renovations))
