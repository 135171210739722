export default {
  DONATION_REQUEST: "DONATION_REQUEST",
  DONATION_SUCCESS: "DONATION_SUCCESS",
  DONATION_FAILURE: "DONATION_FAILURE",

  TRANSACTIONS_REQUEST: "TRANSACTIONS_REQUEST",
  TRANSACTIONS_SUCCESS: "TRANSACTIONS_SUCCESS",
  CURRENT_DONATION_SUCCESS: "CURRENT_DONATION_SUCCESS",

  SET_DONATION_FIELD: "SET_DONATION_FIELD",
  CLEAR_DONATION_FIELDS: "CLEAR_DONATION_FIELDS",
}
