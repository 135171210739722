export default {
  SET_MANTAINER_FIELD: "SET_MANTAINER_FIELD",
  CLEAR_REGISTER_FIELDS: "CLEAR_REGISTER_FIELDS",

  CREATE_MANTAINER_REQUEST: "CREATE_MANTAINER_REQUEST",
  CREATE_MANTAINER_SUCCESS: "CREATE_MANTAINER_SUCCESS",
  CREATE_MANTAINER_FAILURE: "CREATE_MANTAINER_FAILURE",

  GET_MANTAINER_DATA: "GET_MANTAINER_DATA",
}
