export class TagsRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async generateLabel(emails) {
    return this.requestApi({
      url: `/tags`,
      method: "post",
      body: {
        users: emails,
        searchBy: "email",
      },
    })
  }
}
