import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import colors from "@styles/colors"

import construction from "@static/images/peoples.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  container: {
    flex: 1,
    marginTop: 40,
    padding: "0 5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  image: {
    width: 577,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  firstText: {
    color: colors.darkText,
    marginTop: 30,
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Quicksand",
  },
  secondText: {
    color: colors.grayLightText,
    marginTop: 15,
    fontSize: 16,
    fontWeight: 400,
  },
}))

export default function ConstructionPage() {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.container}>
        <img
          className={classes.image}
          src={construction}
          alt="Página em construção"
        />
        <Typography className={classes.firstText}>
          Essa área ainda está em construção.
        </Typography>
        <Typography className={classes.secondText}>
          mas não se preocupe, logo logo ela fica pronta
        </Typography>
      </Grid>
    </Grid>
  )
}
