export default {
  GET_MAINTAINER_REQUEST: "GET_MAINTAINER_REQUEST",
  GET_MAINTAINER_SUCCESS: "GET_MAINTAINER_SUCCESS",
  GET_MAINTAINER_FAILURE: "GET_MAINTAINER_FAILURE",

  GET_PEOPLES_SUCCESS: "GET_PEOPLES_SUCCESS",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_BLOCKED_SUCCESS: "GET_BLOCKED_SUCCESS",
  CLEAR_FIELDS: "CLEAR_FIELDS",

  CHANGE_PAYMENT_DONATION_TYPE_TO_SUBSCRIPTION:
    "CHANGE_PAYMENT_DONATION_TYPE_TO_SUBSCRIPTION",
  CHANGE_PAYMENT_DONATION_TYPE_TO_BOOKLET:
    "CHANGE_PAYMENT_DONATION_TYPE_TO_BOOKLET",
}
