import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import hexToRgba from "hex-to-rgba"
import TableOptionButton from "@components/UI/Table/TableOptionButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  tableCell: {
    fontWeight: 400,
    fontFamily: "Quicksand",
    fontSize: 15,
    color: colors.grayText,
    border: "none",
  },
  tableIcon: {
    padding: 8,
  },
  markedWithRed: {
    backgroundColor: hexToRgba(colors.red, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.red, "0.1"),
    },
  },
  markedWithGreen: {
    backgroundColor: hexToRgba(colors.green, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.green, "0.1"),
    },
  },
})

class CustomTableRow extends Component {
  handleReset = () => {} //Implementar

  handleShowInfoRow = () => {} //Implementar

  handleDeleteRow = () => {} //Implementar

  handleClickRow = () => {
    this.props.handleClickRow(this.props.row)
  }

  render() {
    const { classes, index, row } = this.props

    return (
      <TableRow
        key={index}
        classes={{
          root: classes.root,
        }}
        onClick={this.handleClickRow}
      >
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {index}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.name}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.email}
        </TableCell>
        {/* <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.phone}
        </TableCell> */}
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.canceledData}
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(CustomTableRow)
