export const formatDonationStatus = (status) => {
  switch (status) {
    case "waiting_payment":
      return "Aguardamento pagamento"
    case "pending_payment":
      return "Pagamento pendente"
    case "unpaid":
      return "Não pago"
    case "paid":
      return "Pago"
    case "opened":
      return "Aberto"
    case "expired":
      return "Expirado"
    case "canceled":
      return "Cancelado"
    default:
      return "Não identificado"
  }
}
