import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { useSelector, useDispatch } from "react-redux"
import {
  deletePlan,
  editPlan,
  getPlansPaginated,
} from "@redux/actions/plansActions"

import { useHistory, useParams } from "react-router-dom"

import Table from "@components/UI/Table/GeneralTable"
import TableRow from "./TableRow"
import Loading from "@components/UI/Loading/Loading"

//Dados estáticos
import { tablePlansHead } from "@api/tables/tablesHead"

const useStyles = makeStyles((theme) => ({}))

export default function Plans(props) {
  const {
    plans,
    allLoaded,
    loading: isLoading,
    currentPage,
    rowsCount,
    rowsPerPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    next,
    prev,
  } = useSelector((state) => state.get("plans"))
  const dispatch = useDispatch()

  const classes = useStyles()

  const history = useHistory()
  const { p } = useParams()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [planId, setPlanId] = useState(null)
  const [planName, setPlanName] = useState("")

  const [page, setPage] = useState(parseInt(p) - 1 >= 0 ? parseInt(p) - 1 : 0)

  useEffect(() => {
    if (!modalIsOpen) {
      setPlanName("")
    }
  }, [modalIsOpen])

  useEffect(() => {
    if (planName) {
      setModalIsOpen(true)
    }
  }, [planName])

  useEffect(() => {
    const pattern = /^\d+$/

    if (!pattern.test(p)) {
      history.replace("/app/configuracoes/planos/1")
    }
  }, [p])

  useEffect(() => {
    if (currentPage !== page + 1 || plans.length === 0 || allLoaded) {
      dispatch(getPlansPaginated(page + 1, rowsPerPage, props.inputValue))
    }
  }, [])

  const handleChangePage = (newPage) => {
    if (newPage > page) {
      if (hasNextPage && next <= totalPages) {
        setPage(next)
        history.replace(`/app/configuracoes/planos/${next}`)
      }
    } else {
      if (hasPrevPage) {
        setPage(prev)
        history.replace(`/app/configuracoes/planos/${prev}`)
      }
    }
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch(getPlansPaginated(currentPage, parseInt(event.target.value, 10)))
  }

  const handleEditRow = (value) => {
    history.push(`/app/editar-plano/${value.id}`)
    // setPlanName(value.name)
    // setPlanId(value.id)
  }

  // const handleCloseModal = () => {
  //   setModalIsOpen(false)
  // }

  const handleDeleteRow = (rowId) => {
    const paginatedData = {
      page: page + 1,
      rowsPerPage,
      searchValue: props.inputValue,
    }

    dispatch(deletePlan(rowId, paginatedData))
  }

  // const handleSubmit = (value) => {
  //   dispatch(editPlan(planId, value))
  // }

  const formatPlans = (plans) => {
    let frequency = ""

    const plansFormatted = plans.map((plan) => {
      if (plan.frequency === "Monthly") {
        frequency = "Mensal"
      } else {
        frequency = "Anual"
      }

      const { amount, id, name } = plan

      return {
        amount,
        frequency,
        id,
        name,
      }
    })

    return plansFormatted
  }

  return (
    <Grid container justify="center">
      {isLoading ? (
        <Loading />
      ) : (
        <Table
          headConfig={tablePlansHead}
          data={formatPlans(plans)}
          count={rowsCount}
          currentPage={page}
          rowsPerPage={rowsPerPage}
          changePage={handleChangePage}
          changeRows={handleChangeRowsPerPage}
          pagination="back"
          row={
            <TableRow
              handleEditRow={handleEditRow}
              handleDeleteRow={handleDeleteRow}
            />
          }
        />
      )}
    </Grid>
  )
}
