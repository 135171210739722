import React, { Component } from "react"
import { Avatar } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import DefaultButton from "./DefaultButton"
import backArrow from "@static/icons/backArrow.svg"

import colors from "@styles/colors"

const styles = (theme) => ({
  button: {
    color: colors.primaryColor,
    width: 180,
    height: 48,
    fontSize: 14,
    margin: 10,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
})

class BackButton extends Component {
  render() {
    const { classes, children, ...rest } = this.props
    return (
      <DefaultButton
        className={classes.button}
        variant="outlined"
        startIcon={<Avatar src={backArrow} className={classes.small} />}
        {...rest}
      >
        {children}
      </DefaultButton>
    )
  }
}

export default withStyles(styles)(BackButton)
