import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { validateText, validatePlanValue } from "../"

export default function planResolver() {
  const validateSchema = yup.object().shape({
    name: validateText(),
    amount: validatePlanValue(),
    frequency: validateText(),
  })

  return yupResolver(validateSchema)
}
