import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"
import { clearFields } from "@redux/actions/registerMaintainerActions"

import PrimaryButton from "@components/UI/Button/PrimaryButton"
import SecondaryButton from "@components/UI/Button/SecondaryButton"

const styles = (theme) => ({
  modal: {
    padding: 32,
    maxWidth: 550,
  },
  title: {
    fontFamily: "Quicksand",
    fontSize: 30,
  },
  description: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  buttons: {
    paddingTop: 24,
    paddingBottom: 16,
  },
  button: {
    width: 180,
  },
  textDescription: {
    fontSize: 18,
  },
  field: {
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(28),
    },
  },
})

class ModalCancel extends Component {
  handleCancel = () => {
    this.props.history.push("/app/configuracoes")
    this.props.dispatch(clearFields())
  }

  render() {
    const { open, handleClose, classes, title, children } = this.props
    return (
      <Grid container justify="center" alignItems="center">
        <Dialog
          PaperProps={{
            style: { borderRadius: 7 },
          }}
          open={open}
          onClose={handleClose}
        >
          <Grid
            container
            className={classes.modal}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.title}>{title}</Typography>
            <Grid container item md={12} className={classes.description}>
              <Typography className={classes.textDescription}>
                {children}
              </Typography>
            </Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              md={12}
              className={classes.buttons}
            >
              <SecondaryButton className={classes.button} onClick={handleClose}>
                NÃO CANCELAR
              </SecondaryButton>
              <PrimaryButton
                className={classes.button}
                onClick={this.handleCancel}
              >
                CANCELAR
              </PrimaryButton>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  const { maintainer } = state.get("maintainer")
  return {
    maintainer,
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(ModalCancel))
)
