import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import Sidebar from "@components/Sidebar/Sidebar"
import Content from "@components/Content/Content"

const styles = (theme) => ({
  root: {
    display: "flex",
  },
})

class ContentRender extends Component {
  render() {
    const { classes } = this.props

    return (
      <Grid container className={classes.root}>
        <Sidebar />
        <Content />
      </Grid>
    )
  }
}

export default withStyles(styles)(ContentRender)
