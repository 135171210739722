export class OriginsRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async getOrigins(search, page, perPage) {
    return this.requestApi({
      url: `/origins`,
      method: "get",
      params: {
        q: search,
        page,
        perPage,
      },
    })
  }

  async getOriginsByFilter(search) {
    return this.requestApi({
      url: `/origins`,
      method: "get",
      params: {
        q: search,
      },
    })
  }

  async getOrigin(id) {
    return this.requestApi({
      url: `/origins/${id}`,
      method: "get",
    })
  }

  async createOrigin(name) {
    return this.requestApi({
      url: `/origins`,
      method: "post",
      body: {
        name,
      },
    })
  }

  async deleteOrigin(id) {
    return this.requestApi({
      url: `/origins/${id}`,
      method: "delete",
    })
  }

  async editOrigin(id, name) {
    return this.requestApi({
      url: `/origins/${id}`,
      method: "put",
      body: { name },
    })
  }
}
