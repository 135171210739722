import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import Searchbar from "@components/UI/Input/SearchInput"
import Table from "@components/UI/Table/GeneralTable"
import FiltersButtons from "@components/UI/Filters/PrimaryFiltersButtons"
import TableRow from "./TableRow"

//Dados estáticos
import { tableManagementHead } from "@api/tables/tablesHead"
import { tableManagementBody } from "@api/tables/tablesBody"

const styles = (theme) => ({})

class Management extends Component {
  state = {
    inputValue: "",
    filter: "Todos", //É necessário mudar o tipo do filtro de acordo com o back
    page: 0,
    rowsPerPage: 10,
  }

  handleChangeFilter = (filter) => this.setState({ filter })

  handleChangeSearchKey = ({ target }) =>
    this.setState({ inputValue: target.value })

  handleSearch = () => {}

  handleChangePage = (newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) =>
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })

  render() {
    const { classes } = this.props
    const { inputValue, filter, page, rowsPerPage } = this.state

    return (
      <Grid container justify="center">
        <Searchbar
          value={inputValue}
          changeKey={this.handleChangeSearchKey}
          search={this.handleSearch}
        >
          <FiltersButtons
            disable={["Cancelados"]}
            filter={filter}
            change={this.handleChangeFilter}
          />
        </Searchbar>
        <Table
          title="Quarta feira, 00/00/0000"
          headConfig={tableManagementHead}
          data={tableManagementBody} //Alterar para dados do back
          currentPage={page}
          rowsPerPage={rowsPerPage}
          changePage={this.handleChangePage}
          changeRows={this.handleChangeRowsPerPage}
          row={<TableRow />}
        />
      </Grid>
    )
  }
}

export default withStyles(styles)(Management)
