export class AdminsRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async createAdmin(email) {
    return this.requestApi({
      url: `/admins`,
      method: "post",
      body: { email },
    })
  }

  async createAdmins(emails) {
    return this.requestApi({
      url: `/admins/multiple`,
      method: "post",
      body: { emails },
    })
  }

  async getAdmin(email) {
    return this.requestApi({
      url: `/admins/${email}`,
      method: "get",
    })
  }
}
