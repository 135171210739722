import React, { Component } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Grid from "@material-ui/core/Grid"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    minWidth: 188,
    maxHeight: 64,
    maxWidth: 1920,
    paddingTop: 15,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  tab: {
    marginRight: 10,
    textTransform: "none",
    color: colors.darkText,
    border: `1px solid ${colors.darkText}`,
    borderRadius: 70,
    fontWeight: 500,
    fontFamily: "Quicksand",
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  tabSelected: {
    opacity: 1,
    border: "none",
    color: colors.whiteText,
    backgroundColor: colors.orange,
    borderRadius: 70,
  },
})

const StyledTabs = withStyles({
  indicator: {
    display: "none",
  },
})((props) => <Tabs {...props} />)

class AmountTab extends Component {
  handleChange = (e, newValue) => {
    const { options } = this.props
    this.props.handleChange(options[newValue].id)
  }
  translateFrequency = (value) => {
    if (value === "Annually") {
      return "ano"
    } else if (value === "Monthly") {
      return "mês"
    }
  }

  render() {
    const { classes, current, options, justify = "center" } = this.props
    return (
      <Grid container justify={justify} className={classes.root}>
        <Grid item className={classes.tabsContainer}>
          <StyledTabs
            value={current === -1 ? false : current}
            disabled={current === -1}
            onChange={this.handleChange}
          >
            {options.map((o, i) => (
              <Tab
                label={`R$${o.amount}/${this.translateFrequency(o.frequency)}`}
                className={classNames(
                  classes.tab,
                  i === current ? classes.tabSelected : null
                )}
              />
            ))}
          </StyledTabs>
        </Grid>
      </Grid>
    )
  }
}

AmountTab.propTypes = {
  content: PropTypes.array,
}

export default withStyles(styles)(AmountTab)
