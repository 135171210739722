import { format } from "date-fns"

export default (d) => {
  if (d) {
    const date = new Date(d)
    if (date == "Invalid Date") {
      return {
        date: "00/00/0000",
      }
    }
    if (date) {
      return {
        date: format(date, "dd/MM/yyyy"),
      }
    }
  }
  return null
}
