import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { tableTeamAllHead } from "@api/tables/tablesHead"
import tableTeamAllBody from "@api/tables/Team/tableTeamAllBody"
import { getUsers } from "@redux/actions/teamActions"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import Table from "@components/UI/Table/GeneralTable"
import TableRow from "@components/Team/TableRow"

import Loading from "@components/UI/Loading/Loading"

const useStyles = makeStyles((theme) => ({}))

export default function TeamTable(props) {
  const { roles, route } = props

  const {
    all,
    isLoading,
    usersCount,
    userPerPage: rowsPerPage,
    currentPage,
    hasNextPage,
    hasPrevPage,
    next,
    prev,
    totalPages,
  } = useSelector((state) => state.get("admin"))

  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()
  const { p } = useParams()

  const [page, setPage] = useState(parseInt(p) >= 1 ? parseInt(p) : 1)

  useEffect(() => {
    const pattern = /^\d+$/

    if (!pattern.test(p)) {
      history.replace(`/app/time/${route}/1`)
    }
  }, [p])

  useEffect(() => {
    dispatch(getUsers(page, rowsPerPage, roles))
  }, [page])

  const handleChangePage = (newPage) => {
    if (newPage >= page) {
      if (hasNextPage && next <= totalPages) {
        setPage(next)
        history.replace(`/app/time/${route}/${next}`)
      }
    } else {
      if (hasPrevPage) {
        setPage(prev)
        history.replace(`/app/time/${route}/${prev}`)
      }
    }
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch(getUsers(currentPage, parseInt(event.target.value, 10), roles))
  }

  const handleClickRow = (row) => {
    history.push(`/app/painel-mantenedor/${row.id}`)
  }

  return (
    <Grid container justify="center">
      {isLoading ? (
        <Loading />
      ) : (
        <Table
          className={classes.root}
          headConfig={tableTeamAllHead}
          data={tableTeamAllBody(all)}
          count={usersCount}
          currentPage={page - 1}
          rowsPerPage={rowsPerPage}
          changePage={handleChangePage}
          changeRows={handleChangeRowsPerPage}
          pagination="back"
          row={
            <TableRow
              tableCellOptions={["name", "email", "phone", "register", "type"]}
              handleClickRow={handleClickRow}
              button={false}
            />
          }
        />
      )}
    </Grid>
  )
}
