import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Paper } from "@material-ui/core"

import { createPlan, getPlan, editPlan } from "@redux/actions/plansActions"

import { useSelector, useDispatch } from "react-redux"

import { useForm } from "react-hook-form"
import planResolver from "@api/validation/resolvers/planResolver"

import { useParams } from "react-router-dom"

import PlanForm from "@components/Settings/Plans/PlanForm"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Modal from "@components/UI/Modal/ModalCancelPlan"
import CancelButton from "@components/UI/Button/CancelButton"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 32px",
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  paper: {
    width: "100%",
    borderRadius: 6,
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 53px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
}))

export default function AddPlan() {
  const classes = useStyles()

  const { plan, error } = useSelector((state) => state.get("plans"))
  const dispatch = useDispatch()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const { id: routeId } = useParams()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: planResolver(),
  })

  const convertFrequencyFromApiToSelect = (frequency) => {
    if (frequency === "Annually") {
      return "Anual"
    } else {
      return "Mensal"
    }
  }

  const convertFrequencyType = (frequency) => {
    if (frequency === "Anual") {
      return "Annually"
    } else {
      return "Monthly"
    }
  }

  useEffect(() => {
    if (routeId) {
      dispatch(getPlan(routeId))
    }
  }, [routeId])

  useEffect(() => {
    if (plan && routeId) {
      reset({
        name: plan.name,
        amount: plan.amount,
        frequency: convertFrequencyFromApiToSelect(plan.frequency),
      })
    }
  }, [plan])

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleSubmitPlan = ({ name, amount, frequency }) => {
    let body = {
      name,
    }

    if (routeId !== undefined) {
      dispatch(editPlan(routeId, body))
    } else {
      body = {
        name,
        amount: parseFloat(amount).toFixed(2),
        frequency: convertFrequencyType(frequency),
        paymentMethods: ["credit_card", "boleto"],
      }

      dispatch(createPlan(body))
    }
  }

  return (
    <Grid container justify="center" className={classes.root}>
      <Modal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        title="Tem certeza que deseja cancelar esse plano?"
      >
        Todos os dados preenchidos serão perdidos
      </Modal>
      <Paper
        component="form"
        onSubmit={handleSubmit(handleSubmitPlan)}
        className={classes.paper}
      >
        <PlanForm
          control={control}
          errors={errors}
          editPlan={routeId ? true : false}
        />
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <CancelButton onClick={handleOpenModal}>CANCELAR</CancelButton>

          <PrimaryButton type="submit" style={{ fontSize: 16 }}>
            {routeId ? "EDITAR" : "ADICIONAR"}
          </PrimaryButton>
        </Grid>
      </Paper>
    </Grid>
  )
}
