import formatDate from "@api/format/team/formatDate"
import { formatDonationAmount } from "@api/format/formatDonationAmount"

const tablePendenciesBody = (maintainers) => {
  if (maintainers) {
    const pendenciesFormatted = maintainers.map((maintainer) => {
      const typeMethod = () => {
        if (maintainer.paymentMethod === "credit_card") {
          return "Cartão de crédito"
        } else if (maintainer.paymentMethod === "boleto") {
          return "Boleto"
        } else {
          return "Não informado"
        }
      }

      if (maintainer.paymentMethod === "boleto") {
        if (maintainer.status === "opened") {
          return {
            id: maintainer.maintainer._id,
            name: `${maintainer.maintainer.firstName} ${
              maintainer.maintainer.lastName == undefined ||
              maintainer.maintainer.lastName == null
                ? ""
                : maintainer.maintainer.lastName
            } `,
            overdue: maintainer.nextBookletToPay.dueDate
              ? formatDate(maintainer.nextBookletToPay.dueDate).date
              : "",
            amount: maintainer.nextBookletToPay.amount
              ? formatDonationAmount(maintainer.nextBookletToPay.amount)
              : "R$ 00.00",
            method: typeMethod(),
          }
        }
      }

      return {
        id: maintainer.maintainer._id,
        name: `${maintainer.maintainer.firstName} ${
          maintainer.maintainer.lastName == undefined ||
          maintainer.maintainer.lastName == null
            ? ""
            : maintainer.maintainer.lastName
        } `,
        overdue: maintainer.currentPeriodEnd
          ? formatDate(maintainer.currentPeriodEnd).date
          : "",
        amount: maintainer.plan ? `R$ ${maintainer.plan.amount}` : "R$ 00.00",
        method: typeMethod(),
      }
    })

    return pendenciesFormatted
  } else {
    return []
  }
}

export default tablePendenciesBody
