export default {
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",

  CHANGE_USER_REQUEST: "CHANGE_USER_REQUEST",
  CHANGE_USER_SUCCESS: "CHANGE_USER_SUCCESS",
  CHANGE_USER_FAILURE: "CHANGE_USER_FAILURE",

  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
}
