import getErrorMessage from "@api/utils/getErrorMessage"
import c from "@redux/constants/plansConstants"
import { alert } from "@redux/actions/alertActions"
import { SportsEsportsOutlined } from "@material-ui/icons"
import { RequestSDK } from "@sdk"

export const plansRequest = () => {
  return {
    type: c.PLANS_REQUEST,
  }
}

export const plansSuccess = (payload) => {
  return {
    type: c.PLANS_SUCCESS,
    payload,
  }
}

export const getPlansSuccess = (payload) => {
  return {
    type: c.GET_PLAN_SUCCESS,
    payload,
  }
}

export const plansFailure = () => {
  return {
    type: c.PLANS_FAILURE,
  }
}

export const getPlans = () => {
  return (dispatch) => {
    dispatch(plansRequest())
    RequestSDK.plans
      .getPlans()
      .then((res) => {
        const formattedPlans = res.data.map((plan) => {
          return {
            id: plan._id,
            name: plan.name,
            amount: plan.amount,
            frequency: plan.frequency,
            trialDays: plan.trialDays,
          }
        })
        dispatch(plansSuccess(formattedPlans))
      })
      .catch((err) => {
        dispatch(plansFailure(err.response))
      })
  }
}

export const plansPaginatedRequest = () => {
  return {
    type: c.PLANS_PAGINATED_REQUEST,
  }
}

export const plansPaginatedSuccess = (payload) => {
  return {
    type: c.PLANS_PAGINATED_SUCCESS,
    payload,
  }
}

export const plansPaginatedFailure = () => {
  return {
    type: c.PLANS_PAGINATED_FAILURE,
  }
}

export const getPlansPaginated = (page, perPage, search) => {
  return (dispatch) => {
    dispatch(plansPaginatedRequest())

    RequestSDK.plans
      .getPlansPaginated(page, perPage, search)
      .then((response) => {
        const data = response.data

        const formattedPlans = data.plans.map((plan) => {
          return {
            id: plan._id,
            name: plan.name,
            amount: plan.amount,
            frequency: plan.frequency,
            trialDays: plan.trialDays,
          }
        })

        const formattedPayload = {
          ...data,
          plans: formattedPlans,
        }

        dispatch(plansPaginatedSuccess(formattedPayload))
      })
      .catch((error) => {
        dispatch(plansPaginatedFailure(error.response))
      })
  }
}

export const getStandardPlans = () => {
  return (dispatch) => {
    dispatch(plansRequest())
    RequestSDK.plans
      .getStandardPlans()
      .then((response) => {
        const data = response.data

        const formattedPlans = data.map((plan) => {
          return {
            name: plan.name,
            amount: plan.amount,
            id: plan._id,
            frequency: plan.frequency,
            trialDays: plan.trialDays,
          }
        })

        dispatch(plansSuccess(formattedPlans))
      })
      .catch((err) => {
        dispatch(plansFailure(err.response))
      })
  }
}

export const getPlan = (planId) => {
  return (dispatch) => {
    RequestSDK.plans
      .getPlan(planId)
      .then((res) => {
        dispatch(getPlansSuccess(res.data))
      })
      .catch((err) => {
        dispatch(plansFailure(err.response))
      })
  }
}

// implementar criacao plano
export const createPlan = (plan) => {
  return (dispatch) => {
    dispatch(plansRequest())
    RequestSDK.plans
      .createPlan(plan)
      .then((res) => {
        dispatch(createPlanSuccess())
        dispatch(getPlans())
        dispatch(alert("success", "Plano criado com sucesso!"))
        window.location.replace("/app/configuracoes/planos/1")
      })
      .catch((err) => {
        dispatch(plansFailure(err.response))
        dispatch(alert("error", getErrorMessage(err)))
      })
  }
}

export const createPlanSuccess = () => {
  return {
    type: c.CREATE_PLAN_SUCCESS,
  }
}

export const deletePlan = (planId, paginatedPlan) => {
  return (dispatch) => {
    RequestSDK.plans
      .deletePlan(planId)
      .then((res) => {
        dispatch(deletePlanSuccess())

        if (paginatedPlan)
          dispatch(
            getPlansPaginated(
              paginatedPlan.page,
              paginatedPlan.rowsPerPage,
              paginatedPlan.searchValue
            )
          )
        else dispatch(getStandardPlans())

        dispatch(alert("success", "Plano deletado com sucesso!"))
      })
      .catch((err) => {
        dispatch(plansFailure(err.response))
        dispatch(alert("error", getErrorMessage(err)))
      })
  }
}

export const deletePlanSuccess = () => {
  return {
    type: c.DELETE_PLAN_SUCCESS,
  }
}

export const editPlan = (planId, plan) => {
  return (dispatch) => {
    RequestSDK.plans
      .editPlan(planId, plan)
      .then((res) => {
        dispatch(editPlanSuccess())
        dispatch(alert("success", "Plano editado com sucesso!"))
        window.location.replace("/app/configuracoes/planos/1")
      })
      .catch((err) => {
        dispatch(plansFailure(err.response))
        dispatch(alert("error", getErrorMessage(err)))
      })
  }
}

export const editPlanSuccess = () => {
  return {
    type: c.EDIT_PLAN_SUCCESS,
  }
}
