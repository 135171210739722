import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Grid from "@material-ui/core/Grid"

import Searchbar from "@components/UI/Input/SearchInput"
import FiltersButtons from "@components/UI/Filters/CommunicationFilterButtons"
import Table from "@components/UI/Table/GeneralTable"
import TableRow from "./TableRow"
import { getDateAndDay } from "@api/utils/getDateAndDay"
import { isNumber } from "@api/utils/isNumber"

import Loading from "@components/UI/Loading/Loading"

import { tableShipsmentsHeadEmail } from "@api/tables/Communication/tableShipsmentsHeadEmail"
import { tableShipsmentsHeadSMS } from "@api/tables/Communication/tableShipsmentsHeadSMS"
import { tableShipsmentsEmail } from "@api/tables/Communication/tableShipsmentsEmail"
import { tableShipsmentsSMS } from "@api/tables/Communication/tableShipsmentsSMS"
import {
  getSentMessages,
  searchEmail,
  searchPhone,
} from "@redux/actions/sentMessagesActions"
import ConstructionPage from "@components/UI/ConstructionPage"

const styles = (theme) => ({})

class Shipments extends Component {
  state = {
    inputValue: "",
    filter: "Email",
    page: this.props.match.params.p - 1 || 0,
    rowsPerPage: this.props.messagesPerPage || 5,
  }

  componentDidMount() {
    const { page, rowsPerPage, filter } = this.state
    this.props.history.replace(
      `/app/comunicacao/envios/${this.props.currentPage}`
    )

    this.props.dispatch(
      getSentMessages(page + 1, rowsPerPage, filter.toLowerCase())
    )
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      const page = this.props.currentPage - 1
      this.setState({ page })
      this.props.history.replace(
        `/app/comunicacao/envios/${this.props.currentPage}`
      )
    }
    if (prevProps.messagesPerPage !== this.props.messagesPerPage) {
      this.setState({ rowsPerPage: this.props.messagesPerPage })
    }
  }

  handleChangeFilter = (filter) => {
    this.setState({ filter })
    if (filter != this.state.filter) {
      this.setState({ inputValue: "" })
      this.props.dispatch(getSentMessages(1, 10, filter.toLowerCase()))
    }
  }

  handleChangeSearchKey = ({ target }) =>
    this.setState({ inputValue: target.value })

  handleSearch = () => {
    const { inputValue, page, rowsPerPage } = this.state
    if (inputValue.length > 0) {
      if (isNumber(inputValue)) {
        this.props.dispatch(searchPhone(inputValue))
      } else {
        this.props.dispatch(searchEmail(inputValue))
      }
    } else {
      this.props.dispatch(getSentMessages(page, rowsPerPage))
    }
  }

  handleChangePage = (newPage) => {
    const { page, rowsPerPage, filter } = this.state
    if (newPage > page) {
      const hasNextPage = this.props.hasNextPage
      const next = this.props.next
      if (hasNextPage && next <= this.props.totalPages) {
        this.props.history.replace(`/app/comunicacao/envios/${next}`)
        this.props.dispatch(
          getSentMessages(next, rowsPerPage, filter.toLowerCase())
        )
      }
    } else {
      const hasPrevPage = this.props.hasPrevPage
      const prev = this.props.prev
      if (hasPrevPage) {
        this.props.history.replace(`/app/comunicacao/envios/${prev}`)
        this.props.dispatch(
          getSentMessages(prev, rowsPerPage, filter.toLowerCase())
        )
      }
    }
  }

  handleChangeRowsPerPage = (event) => {
    const { filter } = this.state
    this.props.dispatch(
      getSentMessages(0, parseInt(event.target.value, 10), filter.toLowerCase())
    )
  }

  renderContentMessages = () => {
    const { messages, messagesCount } = this.props
    const { page, rowsPerPage, filter } = this.state
    const filters = {
      SMS: (
        <Table
          title={getDateAndDay()}
          subtitle={`Total: ${messagesCount} mensagens`}
          headConfig={tableShipsmentsHeadSMS}
          data={tableShipsmentsSMS(messages)}
          count={messagesCount}
          currentPage={page}
          pagination="back"
          rowsPerPage={rowsPerPage}
          changePage={this.handleChangePage}
          changeRows={this.handleChangeRowsPerPage}
          row={<TableRow type="sms" />}
        />
      ),
      Email: (
        <Table
          title={getDateAndDay()}
          subtitle={`Total: ${messagesCount} mensagens`}
          headConfig={tableShipsmentsHeadEmail}
          data={tableShipsmentsEmail(messages)}
          count={messagesCount}
          currentPage={page}
          pagination="back"
          rowsPerPage={rowsPerPage}
          changePage={this.handleChangePage}
          changeRows={this.handleChangeRowsPerPage}
          row={<TableRow type="email" />}
        />
      ),
      Whatsapp: <ConstructionPage />,
    }

    return filters[filter]
  }

  render() {
    const { classes, messages, loading, messagesCount } = this.props
    const { inputValue, page, rowsPerPage, filter } = this.state
    return (
      <Grid container justify="center">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Searchbar
              value={inputValue}
              changeKey={this.handleChangeSearchKey}
              search={this.handleSearch}
              typeSearch="email ou telefone"
            >
              <FiltersButtons
                filter={filter}
                change={this.handleChangeFilter}
              />
            </Searchbar>
            {this.renderContentMessages()}
          </Fragment>
        )}
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    messages,
    loading,
    messagesCount,
    currentPage,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
    messagesPerPage,
  } = state.get("sentMessages")
  return {
    messages,
    loading,
    messagesCount,
    currentPage,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
    messagesPerPage,
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(Shipments))
)
