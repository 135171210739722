import formatDate from "@api/format/team/formatDate"

const tableTeamAllBody = (users) => {
  if (users) {
    const formatted = users.map((u) => {
      const options = {
        admin: "administrador",
        maintainer: "mantenedor",
      }

      let typesFormatted = ""
      if (u.roles.length > 0) {
        u.roles.forEach((value) => {
          typesFormatted += typesFormatted != "" ? ", " : "" + options[value]
        })
      }
      const phoneFormatted =
        u.user && u.user.phone
          ? `(${u.user.phone.ddi}) ${u.user.phone.ddd} ${u.user.phone.number} `
          : ""
      return {
        id: u._id,
        name: u.user ? `${u.user.firstName} ${u.user.lastName}` : "",
        email: u.email,
        phone: phoneFormatted,
        register: formatDate(u.createdAt).date,
        type: typesFormatted,
      }
    })
    return formatted
  } else {
    return []
  }
}

export default tableTeamAllBody
