import c from "../constants/userConstants"
import getFormattedTeamBody from "@api/format/getFormattedTeamBody"
import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"
import { RequestSDK } from "@sdk"

export const createUser = (data) => {
  return (dispatch) => {
    dispatch(createUserRequest(data))
    const teamFormatted = getFormattedTeamBody(data)
    RequestSDK.users
      .createUser(teamFormatted)
      .then(() => {
        dispatch(createUserSuccess(teamFormatted))
      })
      .catch((error) => {
        dispatch(createUserFailure(error.response))
      })
  }
}

export const createUserRequest = (data) => {
  return {
    type: c.CREATE_USER_REQUEST,
    data,
  }
}

export const createUserSuccess = (payload) => {
  return {
    type: c.CREATE_USER_SUCCESS,
    payload,
  }
}

export const createUserFailure = (error) => {
  return {
    type: c.CREATE_USER_FAILURE,
    error,
  }
}

export const getUsersSearch = () => {
  return (dispatch) => {
    return RequestSDK.users
      .getUsersSearch()
      .then((response) => {
        dispatch(getAllUsersSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getAllUsersFailure(error.response))
      })
  }
}

export const getAllUsersSuccess = (payload) => {
  return {
    type: c.GET_ALL_USERS_SUCCESS,
    payload,
  }
}

export const getAllUsersFailure = (error) => {
  return {
    type: c.GET_ALL_USERS_FAILURE,
    error,
  }
}

export const changeUser = (data, email) => {
  return (dispatch) => {
    dispatch(changeUserRequest(data))
    const teamFormatted = getFormattedTeamBody(data)
    return RequestSDK.users
      .changeUser(teamFormatted, email)
      .then(() => {
        dispatch(changeUserSuccess(teamFormatted))
      })
      .catch((error) => {
        dispatch(changeUserFailure(error.response))
      })
  }
}

export const changeUserRequest = (data) => {
  return {
    type: c.CHANGE_USER_REQUEST,
    data,
  }
}

export const changeUserSuccess = (payload) => {
  return {
    type: c.CHANGE_USER_SUCCESS,
    payload,
  }
}

export const changeUserFailure = (error) => {
  return {
    type: c.CHANGE_USER_FAILURE,
    error,
  }
}

export const deleteUser = (user) => {
  return (dispatch) => {
    const roles = user.type.filter((type) => type !== "admin")
    RequestSDK.users
      .deleteUser(user.email, roles)
      .then((response) => {
        dispatch(deleteUserSuccess(response.data))
        // dispatch(getAllUsers())
      })
      .catch((error) => {
        dispatch(deleteUserFailure(error.response))
      })
  }
}

export const blockedUser = (id, blocked, onToggleBlockSuccess) => {
  return (dispatch) => {
    RequestSDK.users
      .toggleBlockUser(id, blocked)
      .then((response) => {
        if (onToggleBlockSuccess) {
          onToggleBlockSuccess()
        }
        if (blocked) {
          dispatch(alert("success", "Usuário bloqueado com sucesso!"))
        } else {
          dispatch(alert("success", "Usuário desbloqueado com sucesso!"))
        }
      })
      .catch((error) => {
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const deleteUserSuccess = (payload) => {
  return {
    type: c.DELETE_USER_SUCCESS,
    payload,
  }
}

export const deleteUserFailure = (error) => {
  return {
    type: c.DELETE_USER_FAILURE,
    error,
  }
}
