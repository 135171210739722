import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import ConstructionPage from "@components/UI/ConstructionPage"

const styles = (theme) => ({
  root: {
    display: "flex",
  },
})

class Home extends Component {
  render() {
    const { classes } = this.props

    return (
      <Grid container className={classes.root}>
        <ConstructionPage />
      </Grid>
    )
  }
}

export default withStyles(styles)(Home)
