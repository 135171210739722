import { formatCardNumber, formatcardValidity, formatAmount } from "./format"
import store from "@redux/configureStore"

const getFormattedDonationBody = (body) => {
  const { maintainerId } = store.getState().get("maintainer")

  return {
    donationType: body.donationType,
    gatewayId: body.gatewayId,
    installmentNumber: body.installmentNumber || undefined,
    charge: {
      description: body.description || undefined,
      dueDate: body.dueDate || undefined,
      chargeDay: body.chargeDay === "" ? undefined : body.chargeDay,
      chargeNextMonth: body.chargeNextMonth,
      installments: body.parcels,
      amount: body.amount ? `${parseFloat(body.amount).toFixed(2)}` : undefined,
      trialDays: body.trialDays,
      frequency: body.frequency,
      paymentTypes: body.paymentTypes,
      planId: null,
    },
    maintainerId: maintainerId,
    card: body.number
      ? {
          holderName: body.name,
          cardNumber: formatCardNumber(body.number),
          securityCode: body.cvc,
          expirationMonth: formatcardValidity(body.expiry).month,
          expirationYear: formatcardValidity(body.expiry).year,
          cardToken: "",
        }
      : undefined,
    renew: body.renew || undefined,
  }
}
export default getFormattedDonationBody
