import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import axios from "axios"

import Title from "./Title"
import Input from "@components/UI/Input/FormInput"
// import Input from "@components/UI/Input/DefaultInput"
import ZipcodeMask from "@components/Masks/ZipcodeMask"
import getStateName from "@api/utils/getStateName"

const useStyles = makeStyles({
  root: {
    marginBottom: 30,
  },
})

export default function AddressForm(props) {
  const classes = useStyles()
  const [error, setError] = useState(false)

  const { errors, control, getValues, reset, watch, clearErrors } = props

  const zipcode = watch("zipcode")

  useEffect(() => {
    const zipcodeLength = zipcode?.trim().length

    if (zipcodeLength === 9) {
      fetchZipcode()
    }
  }, [zipcode])

  const setErrorZipcode = () => {
    setError(true)

    const values = getValues()

    reset({
      ...values,
      street: "",
      neighborhood: "",
      number: "",
      state: "",
      city: "",
    })
  }

  const fetchZipcode = () => {
    if (zipcode) {
      setError(false)

      let zipcodeFormatted = zipcode.replaceAll("-", "")

      axios
        .get(`https://viacep.com.br/ws/${zipcodeFormatted}/json`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.data.erro) {
            setErrorZipcode()
          } else {
            const values = getValues()

            reset({
              ...values,
              street: resp.data.logradouro || "",
              neighborhood: resp.data.bairro || "",
              state: getStateName(resp.data.uf) || "",
              city: resp.data.localidade || "",
            })

            clearErrors(["street", "neighborhood", "state", "city"])
          }
        })
        .catch(() => {
          setErrorZipcode()
        })
    }
  }

  return (
    <Grid className={classes.root}>
      <Title text="Endereço" />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Input
            name="zipcode"
            label="CEP"
            inputComponent={ZipcodeMask}
            control={control}
            error={error}
            errors={errors}
            helperText={error ? "CEP incorreto" : ""}
            onBlur={!zipcode && error && setError(false)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input control={control} name="street" label="Rua" errors={errors} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Input
            control={control}
            name="number"
            label="Número"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            control={control}
            name="neighborhood"
            label="Bairro"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input control={control} name="complement" label="Complemento" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input control={control} name="city" label="Cidade" errors={errors} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Input
            control={control}
            name="state"
            label="Estado"
            errors={errors}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
