import React from "react"
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { Grid, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import colors from "@styles/colors"
import FilterChart from "./FilterChart"

const useStyles = makeStyles((theme) => ({
  chart: {
    padding: 24,
    marginBottom: 24,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    color: colors.darkFullText,
    fontSize: 21,
    fontWeight: "normal",
  },
}))

export default function Chart({ data, title, type, request }) {
  const classes = useStyles()

  const handleSubmit = (initialDate, finalDate) => {
    request(initialDate, finalDate, type)
  }

  return (
    <Paper className={classes.chart}>
      <Grid container justify="center">
        <FilterChart handleSubmit={handleSubmit} />
      </Grid>
      <Grid container justify="center" alignContent="center">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{ top: 5, right: 5, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="qtd" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
        <Grid item>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
