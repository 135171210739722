import c from "@redux/constants/maintainerConstants"
import getFormattedMaintainer from "@api/format/getFormattedMaintainer"
import getFormattedEditMaintainer from "@api/format/getFormattedEditMaintainer"
import getFormattedMantainerBody from "@api/format/getFormattedMaintainerBody"
import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"
import { RequestSDK } from "@sdk"

export const setPaymentDonationTypeSubscription = () => {
  return {
    type: c.CHANGE_PAYMENT_DONATION_TYPE_TO_SUBSCRIPTION,
  }
}

export const setPaymentDonationTypeBooklet = () => {
  return {
    type: c.CHANGE_PAYMENT_DONATION_TYPE_TO_BOOKLET,
  }
}

export const getMaintainer = (id) => {
  return (dispatch) => {
    dispatch(getMaintainerRequest())
    RequestSDK.users
      .getMaintainer(id)
      .then((resp) => {
        const formatMaintainer = getFormattedMaintainer(resp.data)
        const id = resp.data._id

        dispatch(getMaintainerSuccess(formatMaintainer, id))
      })
      .catch((error) => {
        dispatch(getMaintainerError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const getPeoples = (page, perPage, search = "") => {
  return (dispatch) => {
    const searchParam = search ? `?value=${search}` : ""
    dispatch(getMaintainerRequest())
    RequestSDK.users
      .getPeople(page, perPage, searchParam)
      .then((resp) => {
        dispatch(getPeoplesSuccess(resp.data))
      })
      .catch((error) => {
        dispatch(getMaintainerError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const getBlocked = (page, perPage, search = "") => {
  return (dispatch) => {
    const searchParam = search ? `?value=${search}` : ""
    dispatch(getMaintainerRequest())
    RequestSDK.users
      .getBlocked(page, perPage, searchParam)
      .then((resp) => {
        dispatch(getBlockedSuccess(resp.data))
      })
      .catch((error) => {
        dispatch(getMaintainerError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const getEditMaintainer = (id) => {
  return (dispatch) => {
    dispatch(getMaintainerRequest())
    RequestSDK.maintainers
      .getMaintainer(id)
      .then((resp) => {
        const formatMaintainer = getFormattedEditMaintainer(resp.data)
        dispatch(getMaintainerSuccess(formatMaintainer, id))
      })
      .catch((error) => {
        dispatch(getMaintainerError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const editMaintainer = (id, maintainerData, type, history) => {
  return (dispatch) => {
    const maintainerFormatted = getFormattedMantainerBody(maintainerData)

    RequestSDK.maintainers
      .editMaintainer(id, maintainerFormatted)
      .then((resp) => {
        dispatch(alert("success", "Usuário editado com sucesso!"))

        if (type === "edit") {
          history.replace(`/app/painel-mantenedor/${id}`)
        } else {
          history.replace(`/app/mantenedor/${id}/renovar-doacao`)
        }
      })
      .catch((error) => {
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const getPayments = (
  donationType = "subscription",
  page,
  perPage,
  subscriptionStatus,
  projectType,
  dayInterval
) => {
  return (dispatch) => {
    dispatch(getMaintainerRequest())
    RequestSDK.donations
      .getPayments(
        donationType,
        page,
        perPage,
        subscriptionStatus,
        projectType,
        dayInterval
      )
      .then((resp) => {
        dispatch(getPaymentSuccess(resp.data))
      })
      .catch((error) => {
        dispatch(getMaintainerError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const getPaymentSuccess = (payload) => {
  return {
    type: c.GET_PAYMENTS_SUCCESS,
    payload,
  }
}

export const addMaintainer = () => {
  return (dispatch) => {
    dispatch(clearFields())
  }
}

export const clearFields = () => {
  return {
    type: c.CLEAR_FIELDS,
  }
}

export const getMaintainerRequest = () => {
  return {
    type: c.GET_MAINTAINER_REQUEST,
  }
}

export const getPeoplesSuccess = (payload) => {
  return {
    type: c.GET_PEOPLES_SUCCESS,
    payload,
  }
}

export const getBlockedSuccess = (payload) => {
  return {
    type: c.GET_BLOCKED_SUCCESS,
    payload,
  }
}

export const getMaintainerSuccess = (payload, maintainerId) => {
  return {
    type: c.GET_MAINTAINER_SUCCESS,
    payload,
    maintainerId,
  }
}

export const getMaintainerError = (error) => {
  return {
    type: c.GET_MAINTAINER_FAILURE,
    error,
  }
}
