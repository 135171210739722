export const formatAmount = (a) => {
  let amount = parseFloat(a)
  const amountString = Math.floor(amount).toString()
  return amountString.slice(0, -2) + "." + amountString.slice(-2)
}

export const formatCpf = (cpf) => {
  return cpf.replace(/[^0-9]/g, "")
}

export const formatZipcode = (zipcode) => {
  return zipcode.replaceAll("-", "")
}

export const formatPhone = (phone) => {
  const phoneFormatted = phone
    .replaceAll("-", "")
    .replaceAll(" ", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
  const phoneDDI = phoneFormatted.slice(0, -11)
  const phoneDDD = phoneFormatted.slice(2, 4)
  const phoneNumber = phoneFormatted.slice(-9)
  return {
    phoneDDI,
    phoneDDD,
    phoneNumber,
  }
}

export const formatDate = (date) => {
  return date.split("/").reverse().join("-")
}

export const formatCardNumber = (cardNumber) => {
  return cardNumber.replaceAll(" ", "").trim()
}

export const formatcardValidity = (cardValidity) => {
  const d = cardValidity.split("/")
  return {
    month: d[0],
    year: "20" + d[1],
  }
}
