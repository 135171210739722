import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Grid, Paper, Typography } from "@material-ui/core"

import Loading from "@components/UI/Loading/Loading"
import TableOptionButton from "@components/UI/Table/TableOptionButton"
import colors from "@styles/colors"
import { getSentMessagesById } from "@redux/actions/sentMessagesActions"
import formatDateAndTime from "@api/format/formatDateAndTime"
import classNames from "classnames"

const styles = (theme) => ({
  paper: {
    width: "100%",
    padding: 24,
  },
  titleDonation: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  defaultGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  messageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textSituation: {
    fontSize: 18,
    color: colors.secondaryColor,
  },
  text: {
    fontSize: 18,
    color: colors.darkText,
  },
  bodyMessage: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
})

class CardMessages extends Component {
  componentDidMount() {
    this.props.dispatch(getSentMessagesById(this.props.match.params.id))
  }

  showMessages = () => {
    const { messages, classes } = this.props
    if (messages) {
      let showMessages = messages

      if (messages.length > 4) {
        showMessages = messages.slice(0, 4)
      }
      return showMessages.map((message) => {
        const dateTime = formatDateAndTime(message.createdAt)
        return (
          <Grid container className={classes.messageContainer}>
            <Grid item md={4}>
              <Typography className={classes.textSituation}>
                {message.title}{" "}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography
                className={classNames(classes.text, classes.bodyMessage)}
              >
                {message.body}
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.row}>
              <Typography
                className={classes.text}
              >{`${dateTime.date} ${dateTime.time}`}</Typography>
            </Grid>
          </Grid>
        )
      })
    } else {
      return (
        <Grid container justify="center" alignItems="center">
          <Typography className={classes.text}>Sem mensagens</Typography>
        </Grid>
      )
    }
  }
  render() {
    const { classes, loading } = this.props
    return (
      <Paper elevation={3} className={classes.paper}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container>
            <Grid item md={12} className={classes.defaultGrid}>
              <Typography className={classes.titleDonation}>
                Mensagens
              </Typography>
              <Grid>
                {/* <TableOptionButton
                  isTable={false}
                  type="expandContainer"
                  funct={this.handleEditTableRow}
                /> */}
              </Grid>
            </Grid>
            {this.showMessages()}
          </Grid>
        )}
      </Paper>
    )
  }
}

const mapStateToProps = (state) => {
  const { messages, loading } = state.get("sentMessages")
  return {
    messages,
    loading,
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(CardMessages))
)
