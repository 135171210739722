import c from "@redux/constants/registerMaintainerConstants"
import m from "@redux/constants/maintainerConstants"

const initialState = {
  maintainer: {
    name: "",
    lastName: "",
    cpfCnpj: "",
    email: "",
    bornAt: "",
    phone: "",
    phone2: "",
    zipcode: "",
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    state: "",
    church: null,
    origin: null,
    obs: "",
    address: {
      street: "",
      streetNumber: "",
      neighborhood: "",
      city: "",
      zipcode: "",
    },
  },
  maintainerId: "",
  maintainers: [],
  blocked: [],
  donations: [],
  paymentsDonationType: "subscription",
  rowsPerPage: 10,
  totalPages: 1,
  rowsCount: 1,
  currentPage: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prev: null,
  next: null,
  isLoading: false,
  error: false,
  success: false,
  cancelled: false,
}

export default function maintainer(state = initialState, action) {
  switch (action.type) {
    case m.CHANGE_PAYMENT_DONATION_TYPE_TO_SUBSCRIPTION: {
      return {
        ...state,
        paymentsDonationType: "subscription",
      }
    }
    case m.CHANGE_PAYMENT_DONATION_TYPE_TO_BOOKLET: {
      return {
        ...state,
        paymentsDonationType: "booklet",
      }
    }
    case c.SET_MANTAINER_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    }
    case c.CREATE_MANTAINER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      }
    }
    // case c.REGISTER_STEP1: {
    //   return {
    //     ...state,
    //     maintainer: action.payload,
    //     step1Completed: true,
    //   }
    // }
    case c.CREATE_MANTAINER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    }
    case c.CREATE_MANTAINER_SUCCESS: {
      return {
        ...state,
        maintainer: action.payload,
        maintainerId: action.maintainerId,
        isLoading: false,
        success: true,
        error: false,
      }
    }

    case m.CLEAR_FIELDS: {
      return { ...initialState }
    }
    case c.GET_MANTAINER_DATA: {
      return {
        ...state,
      }
    }
    case m.GET_MAINTAINER_REQUEST: {
      return {
        ...state,
        error: false,
        isLoading: true,
      }
    }

    case m.GET_MAINTAINER_FAILURE: {
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    }

    case m.GET_MAINTAINER_SUCCESS: {
      return {
        ...state,
        maintainer: action.payload,
        maintainerId: action.maintainerId,
        isLoading: false,
        success: true,
        error: false,
      }
    }

    case m.GET_PEOPLES_SUCCESS: {
      return {
        ...state,
        maintainers: action.payload.users,
        rowsPerPage: action.payload.usersPerPage,
        totalPages: action.payload.totalPages,
        rowsCount: action.payload.usersCount,
        currentPage: action.payload.currentPage,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prev: action.payload.prev,
        next: action.payload.next,
        isLoading: false,
        error: false,
      }
    }

    case m.GET_BLOCKED_SUCCESS: {
      return {
        ...state,
        blocked: action.payload.users,
        rowsPerPage: action.payload.usersPerPage,
        totalPages: action.payload.totalPages,
        rowsCount: action.payload.usersCount,
        currentPage: action.payload.currentPage,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prev: action.payload.prev,
        next: action.payload.next,
        isLoading: false,
        error: false,
      }
    }

    case m.GET_PAYMENTS_SUCCESS: {
      return {
        ...state,
        donations: action.payload.donations,
        rowsPerPage: action.payload.donationsPerPage,
        totalPages: action.payload.totalPages,
        rowsCount: action.payload.donationsCount,
        currentPage: action.payload.currentPage,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prev: action.payload.prev,
        next: action.payload.next,
        isLoading: false,
        error: false,
      }
    }
    default:
      return state
  }
}
