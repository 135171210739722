import React, { Component } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Grid from "@material-ui/core/Grid"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    maxWidth: 1920,
    padding: 15,
    // marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  tab: {
    textTransform: "none",
    color: colors.darkText,
    fontWeight: 500,
    fontFamily: "Quicksand",
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  tabSelected: {
    opacity: 1,
    color: colors.whiteText,
    backgroundColor: colors.secondaryColor,
    borderRadius: 70,
  },
})

const StyledTabs = withStyles({
  indicator: {
    display: "none",
  },
})((props) => <Tabs {...props} />)

class NoRouterTab extends Component {
  render() {
    const {
      classes,
      options,
      current,
      handleChange,
      justify = "center",
    } = this.props

    return (
      <Grid container justify={justify} className={classes.root}>
        <Grid item className={classes.tabsContainer}>
          <StyledTabs value={current} onChange={handleChange}>
            {options.map((o, i) => (
              <Tab
                label={o}
                className={classNames(
                  classes.tab,
                  i === current ? classes.tabSelected : null
                )}
              />
            ))}
          </StyledTabs>
        </Grid>
      </Grid>
    )
  }
}

// GenricalTab.propTypes = {
//   content: PropTypes.array,
// }

export default withStyles(styles)(NoRouterTab)
