import React, { Component, Fragment } from "react"
import classNames from "classnames"
import { withStyles, Typography } from "@material-ui/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import hexToRgba from "hex-to-rgba"
import TableOptionButton from "@components/UI/Table/TableOptionButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  tableCell: {
    fontWeight: 400,
    fontFamily: "Quicksand",
    fontSize: 15,
    color: colors.grayText,
    border: "none",
  },
  tableCellYellow: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: hexToRgba(colors.yellow, "0.5"),
    borderRadius: 49,
    color: colors.grayText,
  },
  tableIcon: {
    padding: 8,
  },
  markedWithRed: {
    backgroundColor: hexToRgba(colors.red, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.red, "0.1"),
    },
  },
  markedWithGreen: {
    backgroundColor: hexToRgba(colors.green, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.green, "0.1"),
    },
  },
})

class CustomTableRow extends Component {
  handleShowInfoRow = () => {} //Implementar

  render() {
    const { classes, markedLines, index, row } = this.props

    return (
      <TableRow
        key={index}
        classes={{
          root: classes.root,
        }}
        className={
          markedLines //Fazer a lógica de cor baseado nas informações do back
            ? index % 2 === 0
              ? classes.markedWithRed
              : classes.markedWithGreen
            : null
        }
      >
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {index}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.tableCell}
        >
          {row.name}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.tableCell}
        >
          {row.overdue}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.tableCell}
        >
          {row.payment}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.tableCell}
        >
          <Typography className={classes.tableCellYellow}>
            {row.parcel}
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.tableCell}
        >
          {row.value}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.tableCell}
        >
          {row.method}
        </TableCell>
        <TableOptionButton type="info" funct={this.handleShowInfoRow} />
      </TableRow>
    )
  }
}

export default withStyles(styles)(CustomTableRow)
