import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

function generateLabel(users) {
  const text = (user) => {
    return `${user.firstName} ${user.lastName}\n Rua: ${user.address.street}, ${user.address.streetNumber}\n ${user.address.neighborhood}\n ${user.address.zipcode}  ${user.address.city} - ${user.address.state}`
  }

  const style = {
    fontSize: 10,
    lineHeight: 1.2,
  }

  let body = []
  const bodyFormatted = users.map((user, index) => {
    const pageBreak = (index + 1) % 31 == 0 ? "after" : ""
    return {
      text: text(user),
      style,
      pageBreak,
    }
  })

  for (let i = 0; i < bodyFormatted.length; i += 3) {
    if (i + 3 > bodyFormatted.length) {
      const j = i + 3 - bodyFormatted.length
      let bodyCompleted = bodyFormatted.slice(i, bodyFormatted.length)
      for (let k = 0; k < j; k++) {
        bodyCompleted.push({
          text: "",
          style,
        })
      }
      body.push(bodyCompleted)
    } else {
      body.push(bodyFormatted.slice(i, i + 3))
    }
  }

  const details = [
    {
      style: "tableExample",
      table: {
        widths: ["*", "*", "*"],
        styles: {
          fontSize: 10,
        },
        body,
      },
      layout: {},
    },
  ]
  const docDefinitions = {
    pageSize: "A4",
    pageMargins: [10, 20, 10, 10],
    content: [details],
    defaultStyle: {
      fontFamily: "Quicksand",
    },
  }

  pdfMake.createPdf(docDefinitions).print()
}

export default generateLabel
