export default {
  CHURCH_REQUEST: "CHURCH_REQUEST",
  CHURCH_SUCCESS: "CHURCH_SUCCESS",
  CHURCH_FAILURE: "CHURCH_FAILURE",

  CHURCH_PAGINATED_REQUEST: "CHURCH_PAGINATED_REQUEST",
  CHURCH_PAGINATED_SUCCESS: "CHURCH_PAGINATED_SUCCESS",
  CHURCH_PAGINATED_FAILURE: "CHURCH_PAGINATED_FAILURE",

  CREATE_CHURCH_SUCCESS: "CREATE_CHURCH_SUCCESS",
  DELETE_CHURCH_SUCCESS: "DELETE_CHURCH_SUCCESS",
  EDIT_CHURCH_SUCCESS: "EDIT_CHURCH_SUCCESS",
}
