export const formatPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case "credit_card":
      return "Cartão de Crédito"
    case "boleto":
      return "Boleto"
    case "door_to_door":
      return "Porta em Porta"
    default:
      return "Não identificado"
  }
}
