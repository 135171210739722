import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { useHistory } from "react-router-dom"
import { Switch, Route, Redirect } from "react-router-dom"

import GenericalTab from "@components/UI/Tab/GenricalTab"
import Searchbar from "@components/UI/Input/SearchInput"
import AddButton from "@components/UI/Button/AddButton"

import teamPages from "@api/teams/teamPages"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  container: {
    flex: 1,
    padding: "0 5px",
    marginLeft: 56,
    marginRight: 20,
    [theme.breakpoints.up("sm") && theme.breakpoints.down("md")]: {
      marginLeft: 90,
      marginRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  genericalTab: {
    padding: 0,
    marginTop: 0,
  },
  AddButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
}))

export default function Team() {
  const classes = useStyles()

  const history = useHistory()

  const [currentTab, setCurrentTab] = useState(0)
  const [inputValue, setInputValue] = useState("")

  const handleClickAddButton = () => {
    history.push("/app/adicionar-time")
  }

  const handleRenderContent = () => {
    return (
      <Switch>
        {teamPages().map((route) => {
          return (
            <Route
              path={`/app/time/${route.path}`}
              component={route.component}
            />
          )
        })}
        <Redirect to="/app/time/todos/1" />
      </Switch>
    )
  }

  const handleChangeSearchKey = ({ target }) => setInputValue(target.value)

  const handleSearch = () => {}

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={1} className={classes.AddButton}>
          <AddButton onCLick={handleClickAddButton} />
        </Grid>
        <Grid item xs={12} sm={11}>
          <Searchbar
            value={inputValue}
            changeKey={handleChangeSearchKey}
            search={handleSearch}
            disabled={true}
          >
            <GenericalTab
              justify="flex-end"
              tabs={teamPages()}
              current={currentTab}
              className={classes.genericalTab}
            />
          </Searchbar>
        </Grid>

        {handleRenderContent()}
      </Grid>
    </Grid>
  )
}
