import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { validateText } from "../"

export default function modalResolver() {
  const validateSchema = yup.object().shape({
    modalText: validateText(),
  })

  return yupResolver(validateSchema)
}
