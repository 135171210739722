import c from "../constants/churchConstants"

const initialState = {
  churches: [],
  loading: false,
  error: false,
  rowsPerPage: 5,
  totalPages: 1,
  rowsCount: 1,
  currentPage: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prev: null,
  next: null,
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case c.CHURCH_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.CHURCH_SUCCESS: {
      return {
        ...state,
        churches: action.payload,
        loading: false,
        error: false,
      }
    }
    case c.CHURCH_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }

    case c.CHURCH_PAGINATED_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.CHURCH_PAGINATED_SUCCESS: {
      return {
        ...state,
        churches: action.payload.churches,
        rowsPerPage: action.payload.churchesPerPage,
        totalPages: action.payload.totalPages,
        rowsCount: action.payload.churchesCount,
        currentPage: action.payload.currentPage,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prev: action.payload.prev,
        next: action.payload.next,
        loading: false,
        error: false,
      }
    }
    case c.CHURCH_PAGINATED_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }

    case c.CREATE_CHURCH_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }

    case c.DELETE_CHURCH_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }

    case c.EDIT_CHURCH_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
      }
    }

    default:
      return state
  }
}
