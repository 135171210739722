import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Tooltip } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    height: "100%",
  },
  button: {
    backgroundColor: colors.primaryColor,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
    width: 56,
    height: 56,
  },
  icon: {
    color: colors.whiteText,
    width: 50,
    height: 50,
  },
})

class AddButton extends Component {
  render() {
    const { classes, onCLick, title = "Adicionar" } = this.props
    return (
      <Tooltip title={title}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <IconButton className={classes.button} onClick={onCLick}>
            <AddIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Tooltip>
    )
  }
}

export default withStyles(styles)(AddButton)
