import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

import { closeAlert } from "@redux/actions/alertActions"

const styles = (theme) => ({})

class DefaultSnackbar extends Component {
  handleCloseSnack = () => {
    this.props.dispatch(closeAlert())
  }

  alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  render() {
    return (
      <Snackbar
        open={this.props.open}
        autoHideDuration={6000}
        onClose={this.handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <this.alert onClose={this.handleCloseSnack} severity={this.props.type}>
          {this.props.message}
        </this.alert>
      </Snackbar>
    )
  }
}

const mapStateToProps = (state) => {
  const { open, type, message } = state.get("alert")
  return {
    open,
    type,
    message,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(DefaultSnackbar))
