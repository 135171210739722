import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core"
import { ChevronLeft, ChevronRight } from "@material-ui/icons"
import Loading from "@components/UI/Loading/Loading"
import colors from "@styles/colors"
import {
  getTransactions,
} from "@redux/actions/donationActions"

import { formatTransactions } from "@api/format/formatTransactions"

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: 24,
  },
  titleDonation: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  defaultGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  donationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },
  amount: {
    fontSize: 18,
    color: colors.secondaryColor,
  },
  creditCardText: {
    fontSize: 18,
    color: colors.primaryColor,
    textAlign: "center !important",
  },
  defaultText: {
    fontSize: 18,
    color: colors.darkText,
    textAlign: "center !important",
  },
  installmentText: {
    fontSize: 15,
    color: colors.darkText,
    textAlign: "center !important",
    fontWeight: 600,
  },
  booklet: {
    fontSize: 18,
    color: colors.orange,
  },
  rowDonation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationContainer: {
    marginTop: 28,
    width: "100%",
    textAlign: "center",
  },
  icon: {
    color: colors.primaryColor,
    "&:disabled": {
      color: "#AAAAAA",
    },
  },
}))

export default function CardDonation(props) {
  const classes = useStyles()

  const { id } = useParams()

  const {
    transactionsLoader: isLoading,
    transactions,
    transactionsCount,
    hasPrevPage,
    hasNextPage,
  } = useSelector((state) => state.get("donation"))
  const { error } = useSelector((state) => state.get("maintainer"))
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(getTransactions(id, page, 6))
  }, [id, page])

  const renderTransactions = () => {
    if (transactionsCount === 0) {
      return (
        <Grid container justify="center" alignItems="center">
          <Typography className={classes.defaultText}>
            Sem doações registradas!
          </Typography>
        </Grid>
      )
    }

    const transactionsFormatted = formatTransactions(transactions)

    return transactionsFormatted.map((transaction, index) => {
      return (
        <Grid
          container
          className={classes.donationContainer}
          key={`transaction-${index}`}
        >
          {transaction.paymentMethod === "Boleto" && (
            <Grid item md={1} className={classes.rowDonation}>
              <Typography className={classes.installmentText}>
                {transaction.installmentNumber} de 12
              </Typography>
            </Grid>
          )}
          <Grid item md={2} className={classes.rowDonation}>
            <Typography className={classes.amount}>
              {transaction.amount}
            </Typography>
          </Grid>

          <Grid item md={2} className={classes.rowDonation}>
            <Typography className={classes.creditCardText}>
              {transaction.paymentMethod}
            </Typography>
          </Grid>

          <Grid item md={3} className={classes.rowDonation}>
            <Typography className={classes.defaultText}>
              {transaction.status}
            </Typography>
          </Grid>

          <Grid item md={2} className={classes.rowDonation}>
            <Typography className={classes.defaultText}>
              {transaction.date}
            </Typography>
          </Grid>
        </Grid>
      )
    })
  }

  const handlePrevPage = () => {
    setPage((prev) => prev - 1)
  }

  const handleNextPage = () => {
    setPage((prev) => prev + 1)
  }

  return (
    <Paper elevation={3} className={classes.paper}>

      {isLoading ? (
        <Loading />
      ) : !error ? (
        <>
          <Grid container>
            <Grid item md={12} className={classes.defaultGrid}>
              <Typography className={classes.titleDonation}>Doações (entradas financeiras)</Typography>
            </Grid>

            {renderTransactions()}

            <Grid item className={classes.paginationContainer}>
              <Tooltip title="Página anterior">
                <IconButton
                  onClick={handlePrevPage}
                  disabled={!hasPrevPage}
                  className={classes.icon}
                >
                  <ChevronLeft />
                </IconButton>
              </Tooltip>

              <Tooltip title="Próxima página">
                <IconButton
                  onClick={handleNextPage}
                  disabled={!hasNextPage}
                  className={classes.icon}
                >
                  <ChevronRight />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container justify="center" alignItems="center">
          <Typography className={classes.defaultText}>
            Sem doações registradas!
          </Typography>
        </Grid>
      )}
    </Paper>
  )
}
