import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import communicationPages from "@api/communication/communicationPages"

import { Switch, Route, Redirect } from "react-router-dom"

import GenericalTab from "@components/UI/Tab/GenricalTab"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  container: {
    flex: 1,
    padding: "0 5px",
    marginLeft: 56,
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))

export default function Communication() {
  const [currentTab, setCurrentTab] = useState(0)

  const classes = useStyles()

  const handleChangeCurrentTab = (e, newValue) => {
    setCurrentTab(newValue)
  }

  const handleRenderContent = () => {
    return (
      <Switch>
        {communicationPages.map((route) => (
          <Route
            path={`/app/comunicacao/${route.path}`}
            component={route.component}
          />
        ))}

        <Redirect to="/app/comunicacao/envios/1" />
      </Switch>
    )
  }

  return (
    <Grid container className={classes.root}>
      <GenericalTab tabs={communicationPages} current={currentTab} />

      <Grid container className={classes.container}>
        {handleRenderContent()}
      </Grid>
    </Grid>
  )
}
