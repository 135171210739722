import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

import { useDebounce } from "use-debounce"

import { useSelector, useDispatch } from "react-redux"

// import Autocomplete from "./Autocomplete"
import Autocomplete from "./AutocompleteForm"
import AddButton from "@components/UI/Button/AddButton"
import Modal from "@components/UI/Modal/Modal"

import { createOrigin } from "@redux/actions/originsActions"
import { RequestSDK } from "@sdk"

const useStyles = makeStyles({
  button: {},
})

export default function OriginForm(props) {
  const classes = useStyles()

  const [origins, setOrigins] = useState([])
  const [origin, setOrigin] = useState()

  const [isLoading, setIsLoading] = useState(false)
  const [debouncedText] = useDebounce(origin, 300)

  const dispatch = useDispatch()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [addOrigin, setAddOrigin] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const { control, errors, getValues, reset, trigger } = props

  const handleChangeDebounceText = (value) => {
    setIsLoading(true)
    setOrigin(value)
  }

  const loadOrigins = async () => {
    setIsLoading(true)

    RequestSDK.origins
      .getOriginsByFilter(debouncedText)
      .then((res) => {
        const data = res.data

        setOrigins(data.origins)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    loadOrigins()
  }, [debouncedText])

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleToggleButton = (value, inputValue) => {
    setAddOrigin(value)
    setInputValue(inputValue)
  }

  const submitCallbackFunction = (data) => {
    const values = getValues()

    setOrigin(data.name)

    reset({
      ...values,
      origin: data,
    })

    setInputValue("")

    trigger("origin")
  }

  const handleSubmit = (value) => {
    dispatch(createOrigin(value, false, submitCallbackFunction))
    setAddOrigin(false)
  }

  return (
    <Grid container justify="flex-start" spacing={4}>
      <Modal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        buttonTitle="ADICIONAR"
        title="Adicionar Origem"
        label="Origem"
        value={inputValue}
        name="origin"
      >
        Informe o nome da origem no campo abaixo:
      </Modal>
      <Grid item xs={12} sm={3} md={10}>
        <Autocomplete
          options={origins}
          control={control}
          handleChange={handleChangeDebounceText}
          handleToggleButton={handleToggleButton}
          isLoading={isLoading}
          name="origin"
          label="Origem"
          errors={errors}
        />
      </Grid>
      {addOrigin ? (
        <Grid item xs={12} sm={3} md={1} className={classes.button}>
          <AddButton onCLick={handleOpenModal} title="Adicionar origem" />
        </Grid>
      ) : null}
    </Grid>
  )
}
