import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import colors from "@styles/colors"
import CurrentMenuTitle from "@api/utils/getCurrentMenuTitle"
import SidebarContent from "@components/Sidebar/SidebarContent"

const drawerWidth = 80

const styles = (theme) => ({
  appBar: {
    backgroundColor: colors.primaryColor,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.primaryColor,
  },
})

function SidebarMobile(props) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { classes } = props

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <CurrentMenuTitle />
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SidebarContent />
      </Drawer>
    </>
  )
}

SidebarMobile.propTypes = {
  window: PropTypes.func,
}

export default withStyles(styles)(SidebarMobile)
