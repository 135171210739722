import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Paper, Typography } from "@material-ui/core"

import { useSelector, useDispatch } from "react-redux"

import { useForm } from "react-hook-form"
import {
  registerDonationResolver,
  editDonationResolver,
} from "@api/validation/resolvers/registerDonationResolver"

import { useHistory, useParams } from "react-router-dom"

import {
  registerDonation,
  editDonation,
  getCurrentDonation,
} from "@redux/actions/donationActions"
import { getPlan, getStandardPlans } from "@redux/actions/plansActions"

import { paymentMethod } from "@api/tabs/tabs"

import Title from "./Title"
import NoRouterTab from "@components/UI/Tab/NoRouterTab"
import AmountTab from "@components/UI/Tab/AmountTab"
import CreditCardForm from "./CreditCardForm"
import BookletPaymentForm from "./BookletPaymentForm"
import Switch from "@components/UI/Switch/CustomSwitch"
import CancelButton from "@components/UI/Button/CancelButton"
import BackButton from "@components/UI/Button/BackButton"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import CircularProgress from "@material-ui/core/CircularProgress"
import Input from "@components/UI/Input/AmountInput"
import Modal from "@components/UI/Modal/ModalCancel"

import colors from "@styles/colors"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  paper: {
    width: "100%",
    borderRadius: 6,
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 53px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  switch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
  },
  errorPlan: {
    color: colors.red,
    marginTop: 12,
    marginBottom: -24,
  },
  loading: {
    color: colors.whiteText,
    marginLeft: 16,
  },
}))

const frequenciesWithInstalments = ["Annually"]

export default function PaymentMethod(props) {
  const classes = useStyles()

  const { donation, donationLoader: isLoading } = useSelector((state) =>
    state.get("donation")
  )
  const { plans, plan } = useSelector((state) => state.get("plans"))
  const dispatch = useDispatch()

  const history = useHistory()
  const { id: routeId } = useParams()

  const { type } = props

  const [currentTab, setCurrentTab] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [filteredPlans, setFilteredPlans] = useState([])
  const [donationType, setDonationType] = useState("subscription")
  const [frequency, setFrequency] = useState("Annually")
  const [selectedAmount, setSelectedAmount] = useState(null)
  const [customAmount, setCustomAmount] = useState(null)
  const [bookletCustomAmount, setBookletCustomAmount] = useState(null)
  const [amountError, setAmountError] = useState(false)
  const [bookletAmountError, setBookletAmountError] = useState(false)
  const [gatewayId, setGatewayId] = useState()
  const [chargeNextMonth, setChargeNextMonth] = useState(false)

  const [switchCheck, setSwitchCheck] = useState(type === "add" ? false : true)

  const {
    control,
    reset,
    setError,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver:
      type === "add" || !switchCheck
        ? registerDonationResolver(
            frequency === "Annually",
            currentTab === 0 ? "credit_card" : "booklet"
          )
        : editDonationResolver(
            frequency === "Annually",
            currentTab === 0 ? "credit_card" : "booklet"
          ),
  })

  const getFilteredPlans = () => {
    const newPlans = plans.filter((o) => o.frequency === frequency)
    setFilteredPlans(newPlans)
  }

  useEffect(() => {
    if (routeId) {
      dispatch(getCurrentDonation(routeId))
      dispatch(getStandardPlans())
    }
  }, [routeId])

  useEffect(() => {
    if (type === "edit" || type === "renovation") {
      if (!donation) return

      if (donation.donationType === "booklet") {
        handleChangeTab(undefined, 1)

        return
      }

      const plan = donation ? donation.plan : {}

      const isCanceled = donation?.status === "canceled"

      if (!isCanceled) {
        const card = donation?.card

        if (card) {
          if (card?.last4CardNumber !== undefined && !isCanceled) {
            const values = getValues()

            reset({
              ...values,
              number: `**** **** **** ${card?.last4CardNumber}`,
            })
          }

          if (card?.expirationMonth !== undefined && !isCanceled) {
            const values = getValues()

            reset({
              ...values,
              expiry: `${card?.expirationMonth}/${card?.expirationYear.slice(
                2,
                4
              )}`,
            })
          }
        }

        const values = getValues()

        reset({
          ...values,
          chargeDay: donation?.chargeDay,
          parcels: plan?.installments,
        })

        setGatewayId(donation?.gatewayId)
        dispatch(getPlan(plan?.planId?._id))
      }
    }
  }, [donation])

  useEffect(() => {
    if (type === "edit" || type === "renovation") {
      const isCanceled = donation?.status === "canceled"

      if (!isCanceled) {
        setFrequency(plan.frequency)

        if (plan) {
          if (plan.isCustomized) {
            setCustomAmount(plan.amount)
          } else {
            setSelectedAmount(plan._id)
          }
        }
      }
    }
  }, [type, plan])

  useEffect(() => {
    getFilteredPlans()
  }, [frequency, plans])

  const handleChangeTab = (e, newValue) => {
    setCurrentTab(newValue)
    setDonationType(newValue === 0 ? "subscription" : "booklet")
  }

  const handleChangeAmount = (newValue) => {
    setCustomAmount("")
    setSelectedAmount(newValue)
    setAmountError(false)
  }

  const handleChangeAmountInput = (e, value) => {
    e.preventDefault()

    setSelectedAmount(null)
    setCustomAmount(value)
    setAmountError(false)
  }

  const handleChangeType = (value) => {
    if (!value) {
      setFrequency("Annually")
      setSelectedAmount(null)
    } else {
      setFrequency("Monthly")
      setSelectedAmount(null)
    }
  }

  const formatDonationType = () => {
    if (frequency === "Annually") {
      return false
    } else {
      return true
    }
  }

  const handleRenderContent = () => {
    switch (currentTab) {
      case 0:
        return (
          <CreditCardForm
            reset={reset}
            getValues={getValues}
            frequency={frequency}
            card={donation?.card}
            type={type}
            control={control}
            watch={watch}
            errors={errors}
            chargeNextMonth={chargeNextMonth}
            setChargeNextMonth={setChargeNextMonth}
            switchCheck={switchCheck}
            setSwitchCheck={setSwitchCheck}
          />
        )
      case 1:
        return (
          <BookletPaymentForm
            reset={reset}
            getValues={getValues}
            control={control}
            watch={watch}
            errors={errors}
            bookletCustomAmount={bookletCustomAmount}
            setBookletCustomAmount={setBookletCustomAmount}
            bookletAmountError={bookletAmountError}
            setBookletAmountError={setBookletAmountError}
          />
        )
      default:
        return (
          <CreditCardForm
            reset={reset}
            getValues={getValues}
            frequency={frequency}
            card={donation?.card}
            type={type}
            control={control}
            watch={watch}
            errors={errors}
            switchCheck={switchCheck}
            setSwitchCheck={setSwitchCheck}
          />
        )
    }
  }

  const searchPlan = (id) => {
    const index = filteredPlans.findIndex((plan) => plan.id === id)
    return index
  }

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const callbackSubmitFunction = () => {
    history.push("/app/mantenedores/pessoas/1")
  }

  const handleSubmitPayment = (data) => {
    let body

    if (currentTab === 0) {
      if (!customAmount && !selectedAmount) {
        setAmountError(true)

        return
      }

      let amount

      if (!customAmount) {
        if (selectedAmount !== null) {
          const plan = filteredPlans.filter(
            (plan) => plan.id === selectedAmount
          )[0]

          amount = plan.amount
        }
      } else {
        amount = parseFloat(customAmount).toFixed(2).toString()
      }

      let parcels = -1

      if (!frequenciesWithInstalments.includes(frequency)) {
        parcels = 1
      }

      const chargeDay = data.chargeDay?.trim() ? data.chargeDay?.trim() : null

      if (chargeDay) {
        const onlyNumbers = /^\d+$/.test(chargeDay)

        if (!onlyNumbers) {
          setError("chargeDay", {
            type: "manual",
            message: "Campo não contém só números",
          })

          return
        }

        if (!(Number(chargeDay) >= 1 && Number(chargeDay) <= 31)) {
          setError("chargeDay", {
            type: "manual",
            message: "Número do dia é inválido no calendário",
          })

          return
        }
      }

      const formattedChargeDay = chargeDay && Number(chargeDay)

      body = {
        ...data,
        cvc: data.cvc && data.cvc.trim(),
        chargeDay: formattedChargeDay,
        chargeNextMonth,
        amount,
        gatewayId,
        donationType,
        frequency,
        parcels: parcels === -1 ? data.parcels : parcels,
        paymentTypes: ["credit_card"],
        renew: type === "renovation" ? true : false,
      }

      if (type === "add") {
        delete body.gatewayId
      }

      if (data.registeredCard) {
        delete body.cvc
        delete body.name
        delete body.number
        delete body.expiry
      }

      delete body.registeredCard
    } else {
      if (!bookletCustomAmount) {
        setBookletAmountError(true)

        return
      }

      body = {
        donationType,
        amount: Number(bookletCustomAmount) * 12,
        parcels: 12,
        description: "Carnê de pagamento do mantenedor",
        frequency: "Monthly",
        dueDate: data.dueDate,
        paymentTypes: ["boleto"],
        gatewayId,
        planId: null,
      }

      if (type === "add") {
        delete body.gatewayId
      }
    }

    if (type === "add") {
      dispatch(registerDonation(body, callbackSubmitFunction))
    } else {
      dispatch(editDonation(body, type, routeId))
    }
  }

  return (
    <Grid container justify="center" className={classes.root}>
      <Modal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        title="Tem certeza que deseja cancelar esse cadastro?"
      >
        Todos os dados preenchidos serão perdidos
      </Modal>
      <Paper
        component="form"
        onSubmit={handleSubmit(handleSubmitPayment)}
        className={classes.paper}
      >
        <Title text="Pagamento" />
        <Grid container>
          <Grid item md={3} xs={12} sm={12}>
            <NoRouterTab
              options={paymentMethod}
              current={currentTab}
              handleChange={handleChangeTab}
            />
          </Grid>
          {currentTab === 0 ? (
            <>
              <Grid item md={12} xs={12} sm={12} className={classes.switch}>
                <Switch
                  firstText="Anual"
                  lastText="Mensal"
                  value={formatDonationType()}
                  onChange={handleChangeType}
                />
              </Grid>

              <Grid container>
                {filteredPlans.length > 0 ? (
                  <Grid item>
                    <AmountTab
                      justify="flex-start"
                      options={filteredPlans}
                      current={searchPlan(selectedAmount)}
                      handleChange={handleChangeAmount}
                    />
                  </Grid>
                ) : null}

                <Grid item>
                  <Input
                    value={customAmount}
                    name="customAmount"
                    label="Personalizado"
                    onChange={handleChangeAmountInput}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          <Grid container>
            {amountError && (
              <Typography className={classes.errorPlan}>
                Por favor, selecione um plano!
              </Typography>
            )}
          </Grid>

          <Grid container>{handleRenderContent()}</Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid>
              <BackButton onClick={handleOpenModal}>VOLTAR</BackButton>
              <CancelButton onClick={handleOpenModal}>CANCELAR</CancelButton>

              <PrimaryButton type="submit">
                CONCLUIR
                {isLoading ? (
                  <CircularProgress size={30} className={classes.loading} />
                ) : null}
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
