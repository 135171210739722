import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import GenericalTab from "@components/UI/Tab/GenricalTab"
import { Switch, Route, Redirect } from "react-router-dom"
import getPages from "@api/account/getPages"

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  container: {
    flex: 1,
    padding: "0 5px",
    marginLeft: 56,
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
})
class Accounting extends Component {
  state = {
    currentTab: 0,
  }

  handleChange = (e, newValue) => this.setState({ currentTab: newValue })

  handleRenderContent = () => {
    return (
      <Switch>
        {getPages().map((route) => (
          <Route
            path={`/app/contabilidade/${route.path}`}
            component={route.component}
          />
        ))}
        <Redirect to="/app/contabilidade/gerenciar" />
      </Switch>
    )
  }

  render() {
    const { classes } = this.props
    const { currentTab } = this.state

    return (
      <Grid container className={classes.root}>
        <GenericalTab
          tabs={getPages()}
          current={currentTab}
          className={classes.genericalTab}
        />
        <Grid container className={classes.container}>
          {this.handleRenderContent()}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Accounting)
