const colors = {
  primaryColor: "#16697A",
  secondaryColor: "#FFA62B",
  whiteText: "#F8F1F1",
  darkText: "#656161",
  darkLightText: "#988C8C",
  darkFullText: "#2D2B2B",
  grayText: "#707070",
  grayLightText: "#AFA6A6",
  red: "#DB2D00",
  orange: "#DB6400",
  green: "#32DB00",
  yellow: "#DBB700",
}

export default colors
