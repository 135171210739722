import moment from "moment"
import * as yup from "yup"

export const validateText = () => yup.string().required("Texto obrigatório")

export const validatePlanValue = () =>
  yup.number().required("Valor obrigatório")

export const validateCpf = () =>
  yup
    .string()
    .required("CPF obrigatório")
    .test("len", "Deve ter 11 ou 14 dígitos", (val) => {
      const formattedVal = val ? val.replace(/[^0-9]/g, "").trim() : ""

      return formattedVal.length === 11 || formattedVal.length === 14
    })

export const validateEmail = () =>
  yup.string().email("Formato de email inválido").required("Email obrigatório")

export const validatePassword = () =>
  yup
    .string()
    .min(6, "Senha deve ter pelo menos 6 dígitos")
    .required("Senha obrigatória")

export const validateFirstName = () => yup.string().required("Nome obrigatório")

export const validateLastName = () =>
  yup.string().required("Sobrenome obrigatório")

export const validateLastNameNotRequired = () => yup.string().nullable()

export const validateDateFormat = () =>
  yup
    .string()
    .required("Data obrigatória")
    .matches(/^\d{2}\/\d{2}\/\d{4}$/)

export const validatePhone = () =>
  yup
    .string()
    .required("Telefone obrigatório")
    .matches(/^\d{2}\s\(\d{2}\)\s\d{5}\-\d{4}$/)

export const validateSecondPhone = () =>
  yup
    .string()
    .matches(/^\d{2}\s\(\d{2}\)\s\d{5}\-\d{4}$/)
    .nullable()

export const validateCEP = () =>
  yup
    .string()
    .required("CEP obrigatório")
    .matches(/^\d{5}\-\d{3}$/)

export const validateStreet = () => yup.string().required("Rua obrigatória")

export const validateNumber = () => yup.string().required("Número obrigatório")

export const validateNeighborhood = () =>
  yup.string().required("Bairro obrigatório")

export const validateCompliment = () => yup.string().nullable()

export const validateCity = () => yup.string().required("Cidade obrigatória")

export const validateState = () => yup.string().required("Estado obrigatório")

export const validateChurch = () =>
  yup.object().shape({
    _id: yup.string().required(),
    name: yup.string().required("Igreja obrigatória"),
  })

export const validateOrigin = () =>
  yup.object().shape({
    _id: yup.string().required(),
    name: yup.string().required("Igreja obrigatória"),
  })

export const validateObs = () => yup.string().nullable()

export const validateCardNumber = () =>
  yup.string().required("Número do cartão obrigatório")

export const validateCardDate = () =>
  yup
    .string()
    .required("Validade do cartão obrigatória")
    .matches(/^\d{2}\/\d{2}$/)

export const validateCVV = () => yup.string().required("CVV obrigatório")

export const validateChargeDay = () => yup.string().nullable()

export const validateParcels = () =>
  yup.number().required("Parcelas obrigatórias")

export const validateParcelsNotRequired = () => yup.number().nullable()

export const validateFirstBookletInstallment = () =>
  yup
    .string()
    .required("Data de expiração obrigatório")
    .matches(
      /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/,
      "Data deve estar corretamente padronizada"
    )
    .test("value", "Deve ser no máximo 30 dias após a data atual", (val) => {
      if (!val) return false

      const inThirtyDays = moment().add("days", 30).format("DD/MM/YYYY")
      const thirtyDaysDate = moment(inThirtyDays, "DD/MM/YYYY")

      const today = moment().format("DD/MM/YYYY")
      const todayDate = moment(today, "DD/MM/YYYY")

      const userDate = moment(val, "DD/MM/YYYY")

      return (
        moment(userDate).isSameOrBefore(thirtyDaysDate) &&
        moment(userDate).isSameOrAfter(todayDate)
      )
    })
