import { ChurchesRequests } from "./ChurchesRequests"
import { MaintainersRequests } from "./MaintainersRequests"
import { AuthenticationRequests } from "./AuthenticationRequests"
import { OriginsRequests } from "./OriginsRequests"
import { DonationsRequests } from "./DonationsRequests"
import { UsersRequests } from "./UsersRequests"
import { MessagesRequests } from "./MessagesRequests"
import { CommunicationsRequests } from "./CommunicationsRequests"
import { AdminsRequests } from "./AdminsRequests"
import { TagsRequests } from "./TagsRequests"
import { PlansRequests } from "./PlansRequests"
import { requestApi } from "./request"

class RequestsSDKClass {
  constructor() {
    this.requestApi = requestApi
    this.churches = new ChurchesRequests(this.requestApi)
    this.maintainers = new MaintainersRequests(this.requestApi)
    this.authentication = new AuthenticationRequests(this.requestApi)
    this.origins = new OriginsRequests(this.requestApi)
    this.donations = new DonationsRequests(this.requestApi)
    this.users = new UsersRequests(this.requestApi)
    this.messages = new MessagesRequests(this.requestApi)
    this.communications = new CommunicationsRequests(this.requestApi)
    this.admins = new AdminsRequests(this.requestApi)
    this.tags = new TagsRequests(this.requestApi)
    this.plans = new PlansRequests(this.requestApi)
  }

  static getInstance(config) {
    if (!this.instance) {
      this.instance = new RequestsSDKClass(config)
    }
    return this.instance
  }
}

export const RequestSDK = RequestsSDKClass.getInstance()
