import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import colors from "@styles/colors"

const styles = (theme) => ({
  search: {
    width: 700,
    maxWidth: 600,
    height: 55,
    fontWeight: 500,
    fontFamily: "Quicksand",
    backgroundColor: colors.whiteText,
    borderRadius: 100,
    marginRight: 16,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 100,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: 460,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  autocomplete: {
    width: "100%",
  },
})

class SearchInput extends Component {
  render() {
    const { classes, changeKey, value, users } = this.props
    return (
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.email}
        getOptionDisabled={(option) => option.roles.includes("admin")}
        onSelect={(e) => changeKey(e)}
        className={classes.autocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            value={value}
            className={classes.search}
            onChange={(e) => changeKey(e)}
            label="Pesquisar"
            variant="outlined"
          />
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const { users } = state.get("user")
  return { users }
}

export default connect(mapStateToProps)(withStyles(styles)(SearchInput))
