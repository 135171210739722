export class DonationsRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async registerDonation(body) {
    return this.requestApi({
      url: `/donations`,
      method: "post",
      body,
    })
  }

  async editDonation(body) {
    return this.requestApi({
      url: `/donations`,
      method: "patch",
      body,
    })
  }

  async getTransactions(id, page, perPage) {
    return this.requestApi({
      url: `/donations/transactions/${id}`,
      method: "get",
      params: {
        page,
        perPage,
      },
    })
  }

  async getCurrentBooklet(id) {
    return this.requestApi({
      url: `/donations/current-booklet/${id}`,
      method: "get",
    })
  }

  async getDonationById(id) {
    return this.requestApi({
      url: `/donations/maintainers/${id}`,
      method: "get",
    })
  }

  async getCurrentDonation(id) {
    return this.requestApi({
      url: `/donations/current/${id}`,
      method: "get",
    })
  }

  async deleteDonation(gatewayId, donationType) {
    return this.requestApi({
      url: `/donations`,
      method: "delete",
      body: { gatewayId, donationType },
    })
  }

  async getPayments(
    donationType,
    page,
    perPage,
    subscriptionStatus,
    projectType,
    dayInterval
  ) {
    return this.requestApi({
      url: `/donations`,
      params: {
        donationType,
        subscriptionStatus,
        page,
        perPage,
        projectType,
        dayInterval: dayInterval !== "Todos os dias" ? dayInterval : undefined,
      },
      method: "get",
    })
  }
}
