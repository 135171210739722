import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Paper } from "@material-ui/core"

import { registerMaintainer } from "@redux/actions/registerMaintainerActions"
import {
  getEditMaintainer,
  editMaintainer,
} from "@redux/actions/maintainerActions"

import { useDispatch, useSelector } from "react-redux"

import { useHistory, useParams } from "react-router-dom"

import { useForm } from "react-hook-form"
import maintainerRegisterResolver from "@api/validation/resolvers/maintainerRegisterResolver"

import PersonalDataForm from "@components/Mantainers/Register/PersonalDataForm"
import AddressForm from "@components/Mantainers/Register/AddressForm"
import ComplementForm from "@components/Mantainers/Register/ComplementForm"
import ForwardButton from "@components/UI/Button/ForwardButton"
import Modal from "@components/UI/Modal/ModalCancel"
import CancelButton from "@components/UI/Button/CancelButton"
import Loading from "@components/UI/Loading/Loading"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  paper: {
    width: "100%",
    borderRadius: 6,
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 53px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
}))

export default function AddMantainers(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isCNPJ, setIsCNPJ] = useState(false)

  const { maintainer, isLoading } = useSelector((state) =>
    state.get("maintainer")
  )
  const { churches } = useSelector((state) => state.get("churches"))
  const { origins } = useSelector((state) => state.get("origins"))

  const { id: maintainerRouteId } = useParams()

  const { type } = props

  const dispatch = useDispatch()

  const {
    control,
    getValues,
    setValue,
    clearErrors,
    trigger,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: maintainerRegisterResolver(isCNPJ),
  })

  const classes = useStyles()
  const history = useHistory()

  const cpfCnpj = watch("cpfCnpj")

  useEffect(() => {
    if (type === "edit" || type === "renovation") {
      if (maintainerRouteId !== undefined) {
        dispatch(getEditMaintainer(maintainerRouteId))
      }
    } else {
      reset({})
    }
  }, [type])

  useEffect(() => {
    if ((type === "edit" || type === "renovation") && !isLoading) {
      reset({
        ...maintainer,
        phone2: maintainer.phone2 || undefined,
      })
    }
  }, [isLoading, type])

  useEffect(() => {
    const value = cpfCnpj?.replace(/[^0-9]/g, "")

    if (value?.length === 14) {
      setIsCNPJ(true)
    } else {
      setIsCNPJ(false)
    }
  }, [cpfCnpj])

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleOpenModal = () => {
    setModalIsOpen(true)
  }

  const registerSuccess = (maintainerId) => {
    history.push(`/app/mantenedor/${maintainerId}/doacao`)
  }

  const onSubmit = async (data) => {
    if (data.isCNPJ === true) {
      delete data.lastName
    }

    const formattedCpfCnpj = data.cpfCnpj.replace(/[^0-9]/g, "").trim()

    const body = {
      ...data,
      cpfCnpj: formattedCpfCnpj,
      church: data.church._id,
      origin: data.origin._id,
    }

    if (type === "edit" || type === "renovation") {
      dispatch(editMaintainer(maintainerRouteId, body, type, history))
    } else {
      dispatch(registerMaintainer(body, registerSuccess))
    }
  }

  return (
    <Grid container justify="center" className={classes.root}>
      <Modal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        title="Tem certeza que deseja cancelar esse cadastro?"
        type={props.type}
      >
        Todos os dados preenchidos serão perdidos
      </Modal>
      <Paper className={classes.paper}>
        {isLoading ? (
          <Loading />
        ) : (
          <Grid component="form" onSubmit={handleSubmit(onSubmit)}>
            <PersonalDataForm
              control={control}
              getValues={getValues}
              isCNPJ={isCNPJ}
              errors={errors}
              type={type}
            />
            <AddressForm
              control={control}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              reset={reset}
              clearErrors={clearErrors}
              errors={errors}
            />
            <ComplementForm
              control={control}
              errors={errors}
              trigger={trigger}
              getValues={getValues}
              reset={reset}
            />
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <CancelButton onClick={handleOpenModal}>CANCELAR</CancelButton>

              <ForwardButton type="submit">
                {props.type === "edit" ? "EDITAR" : "PRÓXIMO"}
              </ForwardButton>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  )
}
