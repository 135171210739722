import React, { useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"

import { useForm } from "react-hook-form"
import modalResolver from "@api/validation/resolvers/modalResolver"

import ButtonPrimary from "@components/UI/Button/PrimaryButton"
import ButtonSecondary from "@components/UI/Button/SecondaryButton"
import Input from "@components/UI/Input/FormInput"

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: 32,
    minWidth: 450,
    maxWidth: 550,
  },
  title: {
    fontFamily: "Quicksand",
    fontSize: 30,
  },
  description: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  buttons: {
    paddingTop: 24,
    paddingBottom: 16,
  },
  button: {
    width: 180,
  },
  textDescription: {
    fontSize: 18,
  },
  field: {
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(28),
    },
  },
}))

export default function Modal(props) {
  const classes = useStyles()

  const {
    children,
    value,
    open: isOpen,
    title,
    label,
    buttonTitle,
    handleClose: handleCloseModalProps,
    handleSubmit: handleSubmitProps,
  } = props

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: modalResolver(),
  })

  useEffect(() => {
    if (value) {
      reset({ modalText: value })
    }
  }, [value])

  const handleClose = () => {
    reset({})
    handleCloseModalProps()
  }

  const handleSubmitModal = ({ modalText }) => {
    handleSubmitProps(modalText)
    handleCloseModalProps()
    reset({})
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Dialog
        PaperProps={{
          style: { borderRadius: 7 },
        }}
        open={isOpen}
        onClose={handleClose}
      >
        <Grid
          container
          className={classes.modal}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Typography className={classes.title}>{title}</Typography>
          <Grid container item md={12} className={classes.description}>
            <Typography className={classes.textDescription}>
              {children}
            </Typography>
          </Grid>
          <Grid container item md={12}>
            <Input
              name="modalText"
              label={label}
              control={control}
              errors={errors}
              type="text"
              className={classes.field}
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            md={12}
            className={classes.buttons}
          >
            <ButtonSecondary className={classes.button} onClick={handleClose}>
              CANCELAR
            </ButtonSecondary>
            <ButtonPrimary
              className={classes.button}
              type="button"
              onClick={handleSubmit(handleSubmitModal)}
            >
              {buttonTitle ? buttonTitle : !value ? "ADICIONAR" : "EDITAR"}
            </ButtonPrimary>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  )
}
