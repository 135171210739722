//Options
import Peoples from "@components/Mantainers/Peoples"
import DonationsMaintainers from "@components/Mantainers/Donations"
import ConstructionPage from "@components/UI/ConstructionPage"
import GenerateLabel from "@components/Mantainers/GenerateLabel"
import Blocked from "@components/Mantainers/Blocked"
import Reports from "@components/Mantainers/Reports"

export default () => {
  return [
    {
      name: "Relatórios",
      path: "relatorios",
      component: Reports,
    },
    {
      name: "Pessoas",
      path: "pessoas/:p",
      component: Peoples,
    },
    {
      name: "Doações",
      path: "doacoes",
      component: DonationsMaintainers,
    },
    {
      name: "Bloqueados",
      path: "bloqueados/:p",
      component: Blocked,
    },
    {
      name: "Gerar Etiqueta",
      path: "gerar-etiqueta",
      component: GenerateLabel,
    },
  ]
}
