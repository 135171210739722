import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { Switch, Route, Redirect } from "react-router-dom"

//Tab
import GenericalTab from "@components/UI/Tab/GenricalTab"

import {
  createChurch,
  getChurchesPaginated,
} from "@redux/actions/churchActions"

import {
  createOrigin,
  getOriginsPaginated,
} from "@redux/actions/originsActions"
//Searchbar
import Searchbar from "@components/UI/Input/SearchInput"

import AddButton from "@components/UI/Button/AddButton"
import Modal from "@components/UI/Modal/Modal"

import getPages from "@api/plans/getPages"

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  container: {
    flex: 1,
    padding: "0 5px",
    marginLeft: 56,
    marginRight: 20,
    [theme.breakpoints.up("sm") && theme.breakpoints.down("md")]: {
      marginLeft: 90,
      marginRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  genericalTab: {
    padding: 0,
    marginTop: 0,
  },
  AddButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
})
class Options extends Component {
  state = {
    currentTab: 0,
    inputValue: "",
    filter: "all", //É necessário mudar o tipo do filtro de acordo com o back
    page: 0,
    rowsPerPage: 10,
    openChurches: false,
    openOrigins: false,
    openPlans: false,
  }

  handleChange = () => {
    this.props.history.replace(`/app/configuracoes/origens`)
  }

  handleRenderContent = () => {
    return (
      <Switch>
        {getPages().map((route) => {
          const Component = route.component

          return (
            <Route
              path={`/app/configuracoes/${route.path}`}
              component={() => <Component inputValue={this.state.inputValue} />}
            />
          )
        })}
        <Redirect to="/app/configuracoes/igrejas/1" />
      </Switch>
    )
  }

  handleClickOpen = () => {
    const location = this.props.location.pathname.split("/")
    const tab = location[location.length - 2]

    switch (tab) {
      case "igrejas":
        this.setState({ openChurches: true })
        break
      case "origens":
        this.setState({ openOrigins: true })
        break
      case "planos":
        this.props.history.push("/app/adiciona-plano")
        break
    }
  }

  handleClose = () => {
    const location = this.props.location.pathname.split("/")
    const tab = location[location.length - 2]

    switch (tab) {
      case "igrejas":
        this.setState({ openChurches: false })
        break
      case "origens":
        this.setState({ openOrigins: false })
        break
    }
    this.setState({ open: false })
  }

  getPath = () => {
    const path = this.props.location.pathname.split("/")
    return path[3]
  }

  handleSubmit = (value) => {
    const paginatedChurch = {
      page: this.props.churchesCurrentPage,
      rowsPerPage: this.props.churchesRowsPerPage,
      searchValue: this.state.inputValue,
    }

    const paginatedOrigin = {
      page: this.props.originsCurrentPage,
      rowsPerPage: this.props.originsRowsPerPage,
      searchValue: this.state.inputValue,
    }

    const request = {
      igrejas: createChurch(value, paginatedChurch),
      origens: createOrigin(value, paginatedOrigin),
    }
    this.props.dispatch(request[this.getPath()])
  }

  handleChangeSearchKey = ({ target }) => {
    this.setState({ inputValue: target.value })
  }

  handleSearch = () => {
    const { inputValue } = this.state

    const location = this.props.location.pathname.split("/")
    const tab = location[location.length - 2]

    switch (tab) {
      case "igrejas":
        this.props.dispatch(
          getChurchesPaginated(1, this.props.originsRowsPerPage, inputValue)
        )
        this.props.history.replace("/app/configuracoes/igrejas/1")

        break
      case "origens":
        this.props.dispatch(
          getOriginsPaginated(1, this.props.churchesRowsPerPage, inputValue)
        )
        this.props.history.replace("/app/configuracoes/origens/1")

        break
    }
  }

  render() {
    const { classes } = this.props
    const { currentTab, inputValue, openChurches, openOrigins } = this.state

    return (
      <Grid container className={classes.root}>
        <Modal
          open={openOrigins}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          currentTab={currentTab}
          title="Adicionar Origem"
          label="Origem"
        >
          Informe o nome da origem no campo abaixo:
        </Modal>
        <Modal
          open={openChurches}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          currentTab={currentTab}
          title="Adicionar Igreja"
          label="Igreja"
        >
          Informe o nome da igreja no campo abaixo:
        </Modal>

        <Grid container className={classes.container}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={1}
              className={classes.AddButton}
            >
              <AddButton onCLick={this.handleClickOpen} />
            </Grid>
            <Grid container item xs={12} sm={11} md={11}>
              <Searchbar
                value={inputValue}
                changeKey={this.handleChangeSearchKey}
                search={this.handleSearch}
              >
                <GenericalTab
                  justify="flex-start"
                  tabs={getPages()}
                  current={currentTab}
                  className={classes.genericalTab}
                />
              </Searchbar>
            </Grid>
          </Grid>

          {this.handleRenderContent()}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    rowsPerPage: churchesRowsPerPage,
    currentPage: churchesCurrentPage,
  } = state.get("churches")

  const {
    rowsPerPage: originsRowsPerPage,
    currentPage: originsCurrentPage,
  } = state.get("origins")

  return {
    churchesRowsPerPage,
    churchesCurrentPage,
    originsRowsPerPage,
    originsCurrentPage,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Options))
