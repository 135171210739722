import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, Dialog } from "@material-ui/core"

import Table from "@components/Mantainers/GenerateLabel/Table"
import TableRow from "@components/Team/TableRow"
import FiltersButtonsSelect from "@components/UI/Filters/MaintainerFilterButtons"
import ButtonPrimary from "@components/UI/Button/PrimaryButton"
import ButtonSecondary from "@components/UI/Button/SecondaryButton"
import Input from "@components/UI/Input/DefaultInput"

import { tableAddTeamHead } from "@api/tables/tablesHead"
import tableAddTeam from "@api/tables/Team/tableAddTeam"

import { alert } from "@redux/actions/alertActions"
import { filterGenerateLabel } from "@redux/actions/tagsActions"

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: 32,
  },
  title: {
    fontFamily: "Quicksand",
    fontSize: 30,
  },
  text: {
    fontFamily: "Quicksand",
    fontSize: 22,
  },
  description: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  buttons: {
    paddingTop: 24,
    paddingBottom: 16,
    gap: 12,
  },
  button: {
    width: 180,
  },
  field: {
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(28),
    },
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default function ModalGenerateLabel(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [valueFilter, setValueFilter] = useState("newMaintainers")
  const [initialDate, setInitialDate] = useState("")
  const [finalDate, setFinalDate] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [usersSelected, setUsersSelected] = useState([])
  const [options, setOptions] = useState([
    {
      name: "Novos Mantenedores",
      value: "newMaintainers",
    },
    {
      name: "Renovados",
      value: "maintenanceRenewal",
    },
    {
      name: "Pelo menos um pagamento",
      value: "atLeastOnePayment",
    },
  ])
  const { open, handleClose } = props

  const handleCloseModal = () => {
    setUsersSelected([])
    setInitialDate("")
    setFinalDate("")
    handleClose()
  }

  const callbackSuccess = (data) => {
    dispatch(alert("success", "Usuários inseridos com sucesso!"))
    setUsersSelected(data)
  }

  const handleSearchFilter = () => {
    setUsersSelected([])
    if (initialDate != "" && finalDate != "") {
      dispatch(
        filterGenerateLabel(
          initialDate,
          finalDate,
          valueFilter,
          callbackSuccess
        )
      )
    } else {
      dispatch(alert("error", "Preencha o(s) campo(s) de data"))
    }
  }

  const handleSubmit = () => {
    props.handleSubmit(usersSelected)
    setUsersSelected([])
    setInitialDate("")
    setFinalDate("")
  }

  const handleChangeSelect = (e) => {
    setValueFilter(
      options.find((option) => option.name == e.target.value).value
    )
  }

  const handleSelectDateInitial = (e) => {
    const dateIn = new Date(e.target.value)
    const dateEn = new Date(finalDate)
    if (dateIn > dateEn) {
      dispatch(
        alert("error", "Selecione uma data que seja anterior à data final")
      )
    } else {
      setInitialDate(e.target.value)
    }
  }

  const handleSelectDateEnd = (e) => {
    const dateIn = new Date(initialDate)
    const dateEn = new Date(e.target.value)
    if (dateIn > dateEn) {
      dispatch(
        alert("error", "Selecione uma data que seja posterior à data inicial")
      )
    } else {
      setFinalDate(e.target.value)
    }
  }

  const handleDelete = (row) => {
    const users = usersSelected.filter((user) => user.email !== row.email)
    setUsersSelected(users)
    dispatch(alert("success", "Usuário removido com sucesso!"))
  }

  const handleChangeFilter = (filter) => setValueFilter(filter)

  return (
    <Grid container justify="center" alignItems="center">
      <Dialog
        PaperProps={{
          style: { borderRadius: 7 },
        }}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleCloseModal}
      >
        <Grid
          container
          className={classes.modal}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid container justify="flex-start" alignItems="flex-start">
            <Typography className={classes.title}>Adicionar</Typography>
          </Grid>
          <Grid
            container
            md={12}
            justify="flex-start"
            alignItems="flex-start"
            className={classes.description}
          >
            <FiltersButtonsSelect
              isBlue
              value={options.find((u) => u.value == valueFilter).name}
              options={options}
              changeSelect={handleChangeSelect}
              change={handleChangeFilter}
            />
          </Grid>
          <Grid container justify="flex-start" alignItems="flex-start">
            <Grid
              container
              md={10}
              justify="space-between"
              alignItems="space-between"
            >
              <Grid item className={classes.gridItem}>
                <Input
                  name="Date"
                  type="date"
                  value={initialDate}
                  onChange={handleSelectDateInitial}
                  className={classes.field}
                />
              </Grid>
              <Grid item className={classes.gridItem}>
                <Typography className={classes.text}>a</Typography>
              </Grid>
              <Grid item className={classes.gridItem}>
                <Input
                  name="Date"
                  type="date"
                  value={finalDate}
                  onChange={handleSelectDateEnd}
                  className={classes.field}
                />
              </Grid>
              <Grid item className={classes.gridItem}>
                <ButtonPrimary
                  className={classes.button}
                  onClick={handleSearchFilter}
                >
                  BUSCAR
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Table
              headConfig={tableAddTeamHead}
              data={tableAddTeam(usersSelected)}
              buttonSend={false}
              currentPage={page}
              rowsPerPage={rowsPerPage}
              changePage={(newPage) => setPage(newPage)}
              changeRows={(event) => {
                setPage(0)
                setRowsPerPage(parseInt(event.target.value, 10))
              }}
              row={
                <TableRow
                  tableCellOptions={["name", "email"]}
                  handleDeleteRow={handleDelete}
                  handleClickRow={(id) => null}
                />
              }
            />
          </Grid>

          <Grid
            container
            justify="flex-end"
            alignItems="center"
            md={12}
            className={classes.buttons}
          >
            <ButtonSecondary
              className={classes.button}
              onClick={handleCloseModal}
            >
              CANCELAR
            </ButtonSecondary>

            <ButtonPrimary className={classes.button} onClick={handleSubmit}>
              ADICIONAR TODOS
            </ButtonPrimary>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  )
}
