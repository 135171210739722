import React, { useState, useEffect } from "react"
import { useDebounce } from "use-debounce"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import colors from "@styles/colors"
import { RequestSDK } from "@sdk"

const useStyles = makeStyles((theme) => ({
  search: {
    width: 700,
    maxWidth: 600,
    height: 55,
    fontWeight: 500,
    fontFamily: "Quicksand",
    backgroundColor: colors.whiteText,
    borderRadius: 100,
    marginRight: 16,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 100,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: 460,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  autocomplete: {
    width: "100%",
  },
}))

export default function SearchInput(props) {
  const classes = useStyles()
  const [value, setValue] = useState("")
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [debouncedText] = useDebounce(user, 300)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    loadUsers()
  }, [debouncedText])

  const onSelectLabel = (e) => {
    const { changeKey, search } = props
    setValue(e.target.value)
    changeKey(e)
    setValue("")
    if (e.target.value != "") {
      const user = users.find((u) => u.email == debouncedText.target.value)
      search(user, e.target.value)
    }
  }

  const handleChangeText = (value) => {
    setIsLoading(true)
    setUser(value)
  }

  const loadUsers = async () => {
    setIsLoading(true)
    RequestSDK.users
      .getUsersSearch(debouncedText?.target.value)
      .then((res) => {
        const data = res.data
        setUsers(data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Autocomplete
      options={isLoading ? [] : users}
      getOptionLabel={(option) => option.email}
      onSelect={onSelectLabel}
      loading={isLoading}
      loadingText="Carregando..."
      clearOnBlur={false}
      onInputChange={handleChangeText}
      className={classes.autocomplete}
      noOptionsText="Sem opções"
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          className={classes.search}
          onChange={onSelectLabel}
          label="Pesquisar"
          variant="outlined"
        />
      )}
    />
  )
}
