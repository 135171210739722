import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { useHistory, useParams } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import {
  deleteOrigin,
  editOrigin,
  getOriginsPaginated,
} from "@redux/actions/originsActions"

import Modal from "@components/UI/Modal/Modal"
import Table from "@components/UI/Table/GeneralTable"
import TableRow from "./TableRow"
import Loading from "@components/UI/Loading/Loading"

//Dados estáticos
import { tableOpitionsHead } from "@api/tables/tablesHead"

const useStyles = makeStyles((theme) => ({}))

export default function Origins(props) {
  const {
    origins,
    loading: isLoading,
    currentPage,
    rowsCount,
    rowsPerPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    next,
    prev,
  } = useSelector((state) => state.get("origins"))
  const dispatch = useDispatch()

  const classes = useStyles()

  const history = useHistory()
  const { p } = useParams()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [originId, setOriginId] = useState(null)
  const [originName, setOriginName] = useState("")

  const [page, setPage] = useState(parseInt(p) - 1 >= 0 ? parseInt(p) - 1 : 0)

  useEffect(() => {
    if (!modalIsOpen) {
      setOriginName("")
    }
  }, [modalIsOpen])

  useEffect(() => {
    if (originName) {
      setModalIsOpen(true)
    }
  }, [originName])

  useEffect(() => {
    const pattern = /^\d+$/

    if (!pattern.test(p)) {
      history.replace("/app/configuracoes/origens/1")
    }
  }, [p])

  useEffect(() => {
    if (currentPage !== page + 1 || origins.length === 0) {
      dispatch(getOriginsPaginated(page + 1, rowsPerPage, props.inputValue))
    }
  }, [page])

  const handleChangePage = (newPage) => {
    if (newPage > page) {
      if (hasNextPage && next <= totalPages) {
        setPage(next)
        history.replace(`/app/configuracoes/origens/${next}`)
      }
    } else {
      if (hasPrevPage) {
        setPage(prev)
        history.replace(`/app/configuracoes/origens/${prev}`)
      }
    }
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch(getOriginsPaginated(currentPage, parseInt(event.target.value, 10)))
  }

  const handleEditRow = (value) => {
    setOriginId(value.id)
    setOriginName(value.name)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const handleDeleteRow = (rowId) => {
    const paginatedData = {
      page: page + 1,
      rowsPerPage,
      searchValue: props.inputValue,
    }

    dispatch(deleteOrigin(rowId, paginatedData))
  }

  const handleSubmit = (value) => {
    const paginatedData = {
      page: page + 1,
      rowsPerPage,
      searchValue: props.inputValue,
    }

    dispatch(editOrigin(originId, value, paginatedData))
  }

  return (
    <Grid container justify="center">
      <Modal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        handleSubmit={(origin) => handleSubmit(origin)}
        title="Editar Origem"
        label="Origem"
        value={originName}
      >
        Informe o nome da origem no campo abaixo:
      </Modal>
      {isLoading ? (
        <Loading />
      ) : (
        <Table
          headConfig={tableOpitionsHead}
          data={origins}
          count={rowsCount}
          currentPage={page}
          rowsPerPage={rowsPerPage}
          changePage={handleChangePage}
          changeRows={handleChangeRowsPerPage}
          pagination="back"
          row={
            <TableRow
              handleEditRow={handleEditRow}
              handleDeleteRow={handleDeleteRow}
            />
          }
        />
      )}
    </Grid>
  )
}
