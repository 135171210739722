export class CommunicationsRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async getSentMessages(page, perPage, typeMessage) {
    return this.requestApi({
      url: `/communications/messages${typeMessage}`,
      method: "get",
      params: {
        page,
        limit: perPage,
      },
    })
  }

  async getSentMessagesById(id) {
    return this.requestApi({
      url: `/communications/clients/${id}`,
      method: "get",
    })
  }

  async searchEmail(recipientEmail) {
    return this.requestApi({
      url: `/communications/messages`,
      method: "get",
      params: {
        recipientEmail,
      },
    })
  }

  async searchPhone(recipientPhone) {
    return this.requestApi({
      url: `/communications/messages`,
      method: "get",
      params: {
        recipientPhone,
      },
    })
  }
}
