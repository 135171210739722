export class UsersRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async getMaintainer(id) {
    return this.requestApi({
      url: `/users/${id}`,
      method: "get",
    })
  }

  async getPeople(page, perPage, search) {
    return this.requestApi({
      url: `/users/micro-service/search/paginated${search}`,
      method: "get",
      params: {
        page,
        perPage,
      },
    })
  }

  async getBlocked(page, perPage, search) {
    return this.requestApi({
      url: `/users?blocked=true${search}`,
      method: "get",
      params: {
        page,
        perPage,
      },
    })
  }

  async getDonationById(id) {
    return this.requestApi({
      url: `/donations/maintainers/${id}`,
      method: "get",
    })
  }

  async getCurrentDonation(id) {
    return this.requestApi({
      url: `/donations/current/${id}`,
      method: "get",
    })
  }

  async deleteDonation(gatewayId, donationType) {
    return this.requestApi({
      url: `/donations`,
      method: "delete",
      body: { gatewayId, donationType },
    })
  }

  async createUser(data) {
    return this.requestApi({
      url: `/users`,
      method: "post",
      body: { ...data, role: "admin" },
    })
  }

  async getUsersSearch(value = "") {
    return this.requestApi({
      url: `/users/micro-service/search`,
      method: "get",
      params: {
        value,
      },
    })
  }

  async changeUser(body, email) {
    return this.requestApi({
      url: `/users/${email}`,
      method: "put",
      body,
    })
  }

  async deleteUser(email, roles) {
    return this.requestApi({
      url: `/users/roles`,
      method: "patch",
      body: {
        email,
        roles,
      },
    })
  }

  async toggleBlockUser(id, blocked) {
    return this.requestApi({
      url: `/users/blocked/${id}`,
      method: "patch",
      body: {
        blocked,
      },
    })
  }

  async getUsers(page, perPage, roles, qs) {
    return this.requestApi({
      url: "/users",
      method: "get",
      params: {
        page,
        perPage,
        role: roles,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
  }

  async removeAdmin(email, roles) {
    return this.requestApi({
      url: `/users/roles`,
      method: "patch",
      body: {
        email,
        roles,
      },
    })
  }
}
