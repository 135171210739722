export class MessagesRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async getMessages(search, page, perPage) {
    return this.requestApi({
      url: `/messages`,
      method: "get",
      params: {
        q: search,
        page,
        perPage,
      },
    })
  }

  async editMessage(id, body) {
    return this.requestApi({
      url: `/messages/${id}`,
      method: "patch",
      body,
    })
  }
}
