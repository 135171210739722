import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { validateText } from "../"

export default function communicationModalResolver(messageType) {
  const emailSchema = yup.object().shape({
    subject: validateText(),
  })

  const smsAndWhatsappSchema = yup.object().shape({
    message: validateText(),
  })

  return yupResolver(
    messageType === "email" ? emailSchema : smsAndWhatsappSchema
  )
}
