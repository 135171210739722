import React, { Component } from "react"
import { Grid, Typography, IconButton } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import colors from "@styles/colors"

const styles = (theme) => ({
  title: {
    fontFamily: "Quicksand",
    fontSize: 24,
    color: "#2D2B2B",
  },
  iconButton: {
    marginTop: 3,
    width: 35,
    height: 35,
  },
  editImage: {
    width: 25,
    height: 25,
  },
})

class Title extends Component {
  render() {
    const { text, classes, onClickEdit } = this.props
    return (
      <Grid container style={{ marginBottom: 15 }}>
        <Grid container justify="space-between" alignItems="center">
          <Typography className={classes.title}>{text}</Typography>
          {/* {onClickEdit ? (
            <IconButton onClick={onClickEdit} className={classes.iconButton}>
              <img src={editIcon} className={classes.editImage} />
            </IconButton>
          ) : null} */}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Title)
