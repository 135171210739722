import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Hidden from "@material-ui/core/Hidden"
import Grid from "@material-ui/core/Grid"

import colors from "@styles/colors"
import SidebarMobile from "./SidebarMobile"
import SidebarWeb from "./SidebarWeb"

const drawerWidth = 80

const styles = (theme) => ({
  drawer: {
    backgroundColor: colors.primaryColor,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
})

class Sidebar extends Component {
  render() {
    const { classes } = this.props

    return (
      <Grid container>
        <CssBaseline />
        <Grid className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <SidebarMobile />
          </Hidden>
          <Hidden xsDown implementation="css">
            <SidebarWeb />
          </Hidden>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Sidebar)
