import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Box, Typography } from "@material-ui/core"

import ModalCommunication from "./ModalCommunication"
import ModalCancel from "@components/UI/Modal/ModalCancel"

import colors from "@styles/colors"

import emailIcon from "@static/icons/mail.svg"
import messageIcon from "@static/icons/message.svg"
import whatsappIcon from "@static/icons/whatsapp.svg"

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    width: "100%",
    backgroundColor: "transparent",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0.8rem",
    borderRadius: "0.6rem",
    border: props.border,
    cursor: "pointer",
  }),

  left: {
    width: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  icon: {
    width: "28px",
    height: "28px",
    marginBottom: "0.4rem",
    marginTop: "0.1rem",
  },

  messageType: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Quicksand",
    color: colors.primaryColor,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },

  content: {
    width: "100%",
    display: "flex",
    gap: "0.7rem",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "0.8rem",
  },

  title: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Quicksand",
    color: colors.darkFullText,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },

  text: {
    fontSize: 15,
    fontWeight: 400,
    fontFamily: "Quicksand",
    color: colors.grayText,
    display: "-webkit-box",
    "-webkit-line-clamp": 5,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
  },

  textNotWritten: {
    fontSize: 15,
    fontWeight: 400,
    fontFamily: "Quicksand",
    color: colors.orange,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
  },
}))

export default function MessageItem(props) {
  const { messageType, isWritten, messageItem, inputValue } = props

  const styleProps = {
    border: isWritten
      ? `1px solid ${colors.primaryColor}`
      : `1px solid ${colors.orange}`,
  }
  const classes = useStyles(styleProps)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalCancelIsOpen, setModalCancelIsOpen] = useState(false)

  const returnMessageType = {
    email: "Email",
    sms: "SMS",
    whatsapp: "WPP",
  }

  const returnIconType = {
    email: (
      <img
        src={emailIcon}
        alt={returnMessageType["email"]}
        className={classes.icon}
      />
    ),
    sms: (
      <img
        src={messageIcon}
        alt={returnMessageType["sms"]}
        className={classes.icon}
      />
    ),
    whatsapp: (
      <img
        src={whatsappIcon}
        alt={returnMessageType["whatsapp"]}
        className={classes.icon}
      />
    ),
  }

  const handleClickMessage = () => {
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
    setModalCancelIsOpen(false)
  }

  const handleOpenModalCancel = () => {
    setModalCancelIsOpen(true)
  }

  const handleCloseModalCancel = () => {
    setModalCancelIsOpen(false)
  }

  return (
    <>
      <ModalCommunication
        title={returnMessageType[messageType]}
        open={modalIsOpen}
        handleClose={handleOpenModalCancel}
        messageType={messageType}
        messageItem={messageItem}
        inputValue={inputValue}
      >
        Informe o email/mensagem desejado:
      </ModalCommunication>
      <ModalCancel
        open={modalCancelIsOpen}
        handleClose={handleCloseModalCancel}
        handleCancel={handleCloseModal}
        title="Tem certeza que deseja cancelar?"
        type="cancel"
      >
        Os dados não serão salvos!
      </ModalCancel>

      <button
        type="button"
        className={classes.container}
        onClick={handleClickMessage}
      >
        <Box className={classes.left}>
          {returnIconType[messageType]}

          <Typography
            variant="h6"
            component="h6"
            className={classes.messageType}
          >
            {returnMessageType[messageType]}
          </Typography>
        </Box>

        {isWritten ? (
          <Box className={classes.content}>
            {messageType === "email" && (
              <Typography
                variant="body1"
                component="h6"
                className={classes.title}
              >
                Assunto: {messageItem.message.subject}
              </Typography>
            )}

            <Typography variant="body1" component="h6" className={classes.text}>
              {messageItem.message.type === "email"
                ? "Não é possível ver o preview do email"
                : messageItem.message.body}
            </Typography>
          </Box>
        ) : (
          <Box className={classes.content}>
            <Typography
              variant="body1"
              component="h6"
              className={classes.textNotWritten}
            >
              Não escrita
            </Typography>
          </Box>
        )}
      </button>
    </>
  )
}
