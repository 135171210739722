import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Switch } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"

import colors from "@styles/colors"

import Header from "@components/Header/Header"
import Routes from "@components/Routes/RoutesDashboard"

const styles = (theme) => ({
  root: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    marginLeft: 80,
    padding: 15,
    backgroundColor: colors.whiteText,
    [theme.breakpoints.only("md")]: {
      marginLeft: 0,
      paddingLeft: 100,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      paddingTop: 50,
    },
  },
  toolbar: theme.mixins.toolbar,
})

class Content extends Component {
  render() {
    const { classes } = this.props

    return (
      <Grid container className={classes.root}>
        <Hidden xsDown>
          <Header />
        </Hidden>
        <Switch>
          <Routes />
        </Switch>
      </Grid>
    )
  }
}

export default withStyles(styles)(Content)
