import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"

import colors from "@styles/colors"

const styles = (theme) => ({
  tableHeadCell: {
    fontWeight: 500,
    fontFamily: "Quicksand",
    fontSize: 24,
    color: colors.darkFullText,
    border: "none",
  },
})

class TableCustomHead extends Component {
  render() {
    const { classes, data } = this.props

    return (
      <TableHead>
        <TableRow>
          {data &&
            data.map((d) => (
              <TableCell className={classes.tableHeadCell}>{d}</TableCell>
            ))}
        </TableRow>
      </TableHead>
    )
  }
}

export default withStyles(styles)(TableCustomHead)
