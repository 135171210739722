const tableAddTeam = (users) => {
  if (users) {
    const usersFormatted = users.map((user) => {
      return {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      }
    })
    return usersFormatted
  }
}

export default tableAddTeam
