import formatDate from "@api/format/team/formatDate"

const tableCanceledBody = (maintainers) => {
  if (maintainers) {
    const canceledFormatted = maintainers.map((maintainer) => {
      return {
        id: maintainer.maintainer._id,
        name: `${maintainer.maintainer.firstName} ${
          maintainer.maintainer.lastName == undefined ||
          maintainer.maintainer.lastName == null
            ? ""
            : maintainer.maintainer.lastName
        } `,
        email: maintainer.maintainer.email,
        // phone: formatDate(maintainer.currentPeriodEnd).date,
        canceledData: formatDate(maintainer.lastDate)?.date,
      }
    })
    return canceledFormatted
  } else {
    return []
  }
}

export default tableCanceledBody
