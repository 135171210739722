import { formatCpf, formatPhone, formatDate } from "@api/format/format"

const getFormattedTeamBody = (body) => {
  return {
    email: body.email,
    firstName: body.firstName,
    lastName: body.lastName,
    password: body.password,

    profile: {
      documentNumber: formatCpf(body.cpf),
      bornAt: formatDate(body.bornAt),

      phone: {
        ddi: formatPhone(body.phone).phoneDDI,
        ddd: formatPhone(body.phone).phoneDDD,
        number: formatPhone(body.phone).phoneNumber,
      },
      phone2: {
        ddi: formatPhone(body.phone2).phoneDDI,
        ddd: formatPhone(body.phone2).phoneDDD,
        number: formatPhone(body.phone2).phoneNumber,
      },
    },
  }
}

export default getFormattedTeamBody
