import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { Controller } from "react-hook-form"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

const useStyles = makeStyles({})

export default function AutocompleteForm(props) {
  const classes = useStyles()

  const [currentInputText, setCurrentInputText] = useState("")

  const {
    label,
    name,
    handleToggleButton,
    control,
    options,
    handleChange,
    isLoading,
  } = props

  useEffect(() => {
    if (!isLoading) {
      if (!options.find((option) => option.name === currentInputText)) {
        handleToggleButton(true, currentInputText)
      } else {
        handleToggleButton(false, currentInputText)
      }

      if (!currentInputText) {
        handleToggleButton(false, currentInputText)
      }
    }
  }, [isLoading])

  const isWithError = () => {
    const { errors, name, cepError } = props

    if ((errors && errors[name]) || cepError) {
      return true
    }
    return false
  }

  const handleInputChange = (e, newValue) => {
    handleChange(newValue)
    setCurrentInputText(newValue)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={isLoading ? [] : options}
          loading={isLoading}
          loadingText="Carregando..."
          clearOnBlur={false}
          getOptionLabel={(option) => option.name}
          onInputChange={handleInputChange}
          onChange={(e, value) => {
            field.onChange(value)
          }}
          noOptionsText="Sem opções"
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={isWithError()}
            />
          )}
        />
      )}
    />
  )
}
