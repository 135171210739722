import React, { Component } from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { TextField, MenuItem } from "@material-ui/core"
import classNames from "classnames"

import { Controller } from "react-hook-form"

const useStyles = makeStyles({
  select: {
    marginTop: 20,
  },
})

export default function FormSelect(props) {
  const classes = useStyles()

  const { options, control, name, errors, className, ...rest } = props

  const isWithError = () => {
    if (errors && errors[name]) {
      return true
    }
    return false
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <TextField
          className={classNames(classes.select, className)}
          fullWidth
          select
          error={isWithError()}
          variant="outlined"
          onChange={onChange}
          value={value || ""}
          inputRef={ref}
          {...rest}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}
