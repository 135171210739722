import formatDateAndTime from "@api/format/formatDateAndTime"

//Mantainer
export const tablePeoplesBody = [
  {
    name: "Renato Figueiredo Pereira",
    email: "renatopereira2600@gmail.com",
    phone: "35999966441",
    register: "00/00/0000",
    method: "Cartão",
  },
  {
    name: "Renato Figueiredo Pereira",
    email: "renatopereira2600@gmail.com",
    phone: "35999966441",
    register: "00/00/0000",
    method: "Cartão",
  },
  {
    name: "Renato Figueiredo Pereira",
    email: "renatopereira2600@gmail.com",
    phone: "35999966441",
    register: "00/00/0000",
    method: "Cartão",
  },
  {
    name: "Renato Figueiredo Pereira",
    email: "renatopereira2600@gmail.com",
    phone: "35999966441",
    register: "00/00/0000",
    method: "Cartão",
  },
  {
    name: "Renato Figueiredo Pereira",
    email: "renatopereira2600@gmail.com",
    phone: "35999966441",
    register: "00/00/0000",
    method: "Cartão",
  },
]

export const tablePendenciesBody = [
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
]

export const tableRenovationsBody = [
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    paid: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    paid: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    paid: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    parcel: "00/00",
    paid: "00/00",
    method: "Cartão",
    value: "R$000.00",
  },
]

// Accounting

export const tableManagementBody = [
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
  },
]

export const tableOverdueBody = [
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
    situation: "Atraso",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
    situation: "Atraso",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
    situation: "Atraso",
  },
  {
    name: "Renato Figueiredo Pereira",
    overdue: "00/00/0000",
    payment: "00/00/0000",
    parcel: "00/00",
    value: "R$000.00",
    method: "Cartão",
    situation: "Atraso",
  },
]

// Communication
export const tableShipsmentsBody = (messages) => {
  return messages.map((message) => {
    const dateTime = formatDateAndTime(message.createdAt)
    return {
      email: message.email,
      phone: `(${message.phone.ddd}) ${message.phone.ddi} ${message.phone.number}`,
      situation: "",
      text: message.title,
      date: `${dateTime.date} ${dateTime.time}`,
    }
  })
}

// Settings

export const tableSettingsBody = [
  {
    name: "Renato Figueiredo Pereira",
  },
  {
    name: "Renato Figueiredo Pereira",
  },
  {
    name: "Renato Figueiredo Pereira",
  },
  {
    name: "Renato Figueiredo Pereira",
  },
]
