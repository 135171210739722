import c from "@redux/constants/registerMaintainerConstants"
import getFormattedMantainerBody from "@api/format/getFormattedMaintainerBody"
import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"
import { RequestSDK } from "@sdk"

export const setField = (field, value) => {
  return {
    type: c.SET_MANTAINER_FIELD,
    payload: {
      field,
      value,
    },
  }
}

export const clearFields = () => {
  return {
    type: c.CLEAR_REGISTER_FIELDS,
  }
}

export const registerMaintainer = (maintainerData, registerSuccess) => {
  return (dispatch) => {
    dispatch(registerMantainerRequest())
    const maintainerFormatted = getFormattedMantainerBody(maintainerData)

    RequestSDK.maintainers
      .createMaintainer(maintainerFormatted)
      .then((resp) => {
        const maintainerId = resp.data._id

        dispatch(registerMantainerSuccess(maintainerFormatted, maintainerId))
        dispatch(alert("success", "Cadastro feito com sucesso!"))
        registerSuccess(maintainerId)
      })
      .catch((error) => {
        dispatch(registerMantainerError(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const registerMantainerRequest = () => {
  return {
    type: c.CREATE_MANTAINER_REQUEST,
  }
}

export const registerMantainerSuccess = (payload, maintainerId) => {
  return {
    type: c.CREATE_MANTAINER_SUCCESS,
    payload,
    maintainerId,
  }
}

export const registerMantainerError = (error) => {
  return {
    type: c.CREATE_MANTAINER_FAILURE,
    error,
  }
}
