import moment from "moment"

import { formatDonationAmount } from "./formatDonationAmount"
import { formatDonationStatus } from "./formatDonationStatus"
import { formatPaymentMethod } from "./formatPaymentMethod"

export const formatTransactions = (transactions) => {
  const formattedTransactions = transactions.map((transaction) => ({
    date:
      transaction.paymentMethod === "credit_card"
        ? moment(new Date(transaction.dateUpdated)).format("DD/MM/YYYY")
        : moment(new Date(transaction.dueDate)).format("DD/MM/YYYY"),
    paymentMethod: formatPaymentMethod(transaction.paymentMethod),
    amount: formatDonationAmount(transaction.amount),
    status: formatDonationStatus(transaction.status),
    bookletId:
      transaction.paymentMethod === "boleto"
        ? transaction.bookletId
        : undefined,
    installmentNumber:
      transaction.paymentMethod === "boleto"
        ? transaction.installmentNumber
        : undefined,
    bookletLink:
      transaction.paymentMethod === "boleto"
        ? transaction.urlBoleto
        : undefined,
  }))

  return formattedTransactions
}
