import React, { useEffect, useRef } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useDispatch, useSelector } from "react-redux"
import { editMessage } from "@redux/actions/messageActions"

import { useForm } from "react-hook-form"
import communicationModalResolver from "@api/validation/resolvers/communicationModalResolver"

import EmailEditor from "react-email-editor"

import ButtonPrimary from "@components/UI/Button/PrimaryButton"
import ButtonSecondary from "@components/UI/Button/SecondaryButton"
import Input from "@components/UI/Input/FormInput"

import colors from "@styles/colors"

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: 24,
    width: "100%",
  },
  title: {
    fontFamily: "Quicksand",
    color: colors.darkFullText,
    fontSize: 26,
    fontWeight: 400,
  },
  description: {
    paddingTop: "0.5rem",
    paddingBottom: "0.2rem",
  },
  buttons: {
    paddingTop: "1.5rem",
  },
  button: {
    width: 180,
    fontSize: 14,
  },
  textDescription: {
    fontSize: 18,
    fontFamily: "Quicksand",
    color: colors.darkFullText,
    fontWeight: 400,
  },
}))

export default function ModalCommunication(props) {
  const classes = useStyles()

  const {
    children,
    open: isOpen,
    title,
    messageType,
    messageItem,
    handleClose: handleCloseModalProps,
    inputValue,
  } = props

  const emailEditorRef = useRef(null)

  const { editLoading, currentPage, messagesPerPage } = useSelector((state) =>
    state.get("messages")
  )
  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: communicationModalResolver(messageType),
  })

  const handleClose = () => {
    reset({})
    handleCloseModalProps()
  }

  useEffect(() => {
    if (isOpen) {
      if (messageItem.message.written) {
        if (messageItem.message.type === "email") {
          reset({ subject: messageItem.message.subject })
        } else {
          reset({ message: messageItem.message.body })
        }
      }
    }
  }, [isOpen, messageItem])

  const handleSubmitModal = (data) => {
    const paginatedInfo = {
      page: currentPage,
      rowsPerPage: messagesPerPage,
      searchValue: inputValue,
    }

    if (messageType === "email") {
      let subject = data.subject

      emailEditorRef.current.editor.exportHtml((emailData) => {
        const { design: jsonTemplate, html } = emailData
        const emailHtml = html
        const jsonMail = jsonTemplate

        const body = {
          type: "email",
          subject,
          body: emailHtml,
          template: JSON.stringify(jsonMail),
        }

        dispatch(
          editMessage(messageItem.messageId, body, handleClose, paginatedInfo)
        )
      })
    } else {
      const body = {
        type: messageItem.message.type,
        body: data.message,
      }

      dispatch(
        editMessage(messageItem.messageId, body, handleClose, paginatedInfo)
      )
    }
  }

  const onLoad = () => {
    setTimeout(() => {
      const templateJson = messageItem.message.template

      if (templateJson && emailEditorRef.current)
        emailEditorRef.current.editor.loadDesign(JSON.parse(templateJson))
    }, 100)
  }

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 7 },
      }}
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={messageType === "email" ? "lg" : "md"}
    >
      <Grid
        className={classes.modal}
        onSubmit={handleSubmit(handleSubmitModal)}
        component="form"
      >
        <Typography className={classes.title}>
          {title === "WPP" ? "WhatsApp" : title}
        </Typography>

        <Grid container item md={12} className={classes.description}>
          <Typography className={classes.textDescription}>
            {children}
          </Typography>
        </Grid>

        {messageType === "email" ? (
          <>
            <Input
              name="subject"
              label="Assunto"
              control={control}
              errors={errors}
              type="text"
            />

            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              style={{ marginTop: "1rem" }}
            />
          </>
        ) : (
          <Input
            name="message"
            label="Mensagem"
            control={control}
            errors={errors}
            type="text"
            multiline
            rows={4}
            rowsMax={6}
            inputProps={{ maxLength: messageType === "sms" ? 256 : null }}
          />
        )}

        <Grid
          container
          className={classes.buttons}
          justify="flex-end"
          alignItems="center"
          md={12}
        >
          <ButtonSecondary className={classes.button} onClick={handleClose}>
            CANCELAR
          </ButtonSecondary>

          <ButtonPrimary
            className={classes.button}
            style={{ marginLeft: "1rem" }}
            type="submit"
          >
            {editLoading ? (
              <CircularProgress size={20} style={{ color: "#fff" }} />
            ) : (
              "CONCLUIR"
            )}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </Dialog>
  )
}
