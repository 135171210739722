import pencil from "@static/icons/pencil.svg"
import user from "@static/icons/user.svg"
import close from "@static/icons/close.svg"
import receipt from "@static/icons/receipt.svg"
import reset from "@static/icons/reset.svg"
import lock from "@static/icons/lock.svg"
import unlock from "@static/icons/unlock.svg"
import arrows from "@static/icons/arrows.svg"
import add from "@static/icons/add.svg"
import replay from "@static/icons/replay.svg"

const types = {
  edit: {
    icon: pencil,
    class: "iconPrimary",
    alt: "Editar",
  },
  blockMaintainer: {
    icon: lock,
    class: "iconOrange",
    alt: "Bloquear",
  },
  renovation: {
    icon: replay,
    class: "iconSecondaryOrange",
    alt: "Renovar",
  },
  unlockMaintainer: {
    icon: unlock,
    class: "iconOrange",
    alt: "Desbloquear",
  },
  expandContainer: {
    icon: arrows,
    class: "iconSecondaryOrange",
    alt: "Expandir",
  },
  add: {
    icon: add,
    class: "iconPrimary",
    alt: "Adicionar",
  },
  info: {
    icon: user,
    class: "iconPrimary",
    alt: "Informações",
  },
  del: {
    icon: close,
    class: "iconOrange",
    alt: "Excluir",
  },
  receipt: {
    icon: receipt,
    class: "iconPrimary",
    alt: "Receita",
  },
  reset: {
    icon: reset,
    class: "iconPrimary",
    alt: "Reverter",
  },
  close: {
    icon: close,
    class: "iconPrimary",
    alt: "Fechar",
  },
}

export default types
