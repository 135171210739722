import React, { useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

import { useSelector, useDispatch } from "react-redux"
import { getMaintainer } from "@redux/actions/maintainerActions"

import { useParams } from "react-router-dom"

import MantainerInfo from "@components/Mantainers/Register/MaintainerInfo"
import PaymentMethod from "@components/Mantainers/Register/PaymentMethod"
import Loading from "@components/UI/Loading/Loading"

const useStyles = makeStyles((theme) => ({}))

export default function AddMantainersCard(props) {
  const classes = useStyles()

  const { isLoading } = useSelector((state) => state.get("maintainer"))
  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(getMaintainer(id))
    }
  }, [id])

  return (
    <Grid>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MantainerInfo />
          <PaymentMethod type="add" />
        </>
      )}
    </Grid>
  )
}
