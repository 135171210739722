export default {
  PLANS_REQUEST: "PLANS_REQUEST",
  PLANS_SUCCESS: "PLANS_SUCCESS",
  PLANS_FAILURE: "PLANS_FAILURE",

  PLANS_PAGINATED_REQUEST: "PLANS_PAGINATED_REQUEST",
  PLANS_PAGINATED_SUCCESS: "PLANS_PAGINATED_SUCCESS",
  PLANS_PAGINATED_FAILURE: "PLANS_PAGINATED_FAILURE",

  CREATE_PLAN_SUCCESS: "CREATE_PLAN_SUCCESS:",
  DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
  EDIT_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",

  GET_PLAN_SUCCESS: "GET_PLAN_SUCCESS",
  SET_PLANS_FIELD: "SET_PLANS_FIELD",
  CLEAR_PLANS_FIELDS: "CLEAR_PLANS_FIELDS",
}
