export class ChurchesRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async getChurches(search, page = "", perPage = "") {
    return this.requestApi({
      url: `/churches`,
      method: "get",
      params: {
        q: search,
        page,
        perPage,
      },
    })
  }

  async getChurch(id) {
    return this.requestApi({
      url: `/churches/${id}`,
      method: "get",
    })
  }

  async createChurch(name) {
    return this.requestApi({
      url: `/churches`,
      method: "post",
      body: {
        name,
      },
    })
  }

  async deleteChurch(id) {
    return this.requestApi({
      url: `/churches/${id}`,
      method: "delete",
    })
  }

  async editChurch(id, name) {
    return this.requestApi({
      url: `/churches/${id}`,
      method: "put",
      body: { name },
    })
  }
}
