export default {
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RECOVER_PASSWORD_REQUEST: "RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  RECOVER_PASSWORD_FAILURE: "RECOVER_PASSWORD_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
}
