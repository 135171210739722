import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import {
  validateCity,
  validateCompliment,
  validateCpf,
  validateDateFormat,
  validateEmail,
  validateFirstName,
  validateLastName,
  validateCEP,
  validateNeighborhood,
  validateNumber,
  validatePhone,
  validateSecondPhone,
  validateState,
  validateStreet,
  validateLastNameNotRequired,
  validateChurch,
  validateOrigin,
  validateObs,
} from "../"

export default function maintainerRegisterResolver(isCNPJ) {
  const validateSchema = yup.object().shape({
    cpfCnpj: validateCpf(),
    email: validateEmail(),
    name: validateFirstName(),
    lastName: isCNPJ ? validateLastNameNotRequired() : validateLastName(),
    bornAt: validateDateFormat(),
    phone: validatePhone(),
    phone2: validateSecondPhone(),
    zipcode: validateCEP(),
    street: validateStreet(),
    number: validateNumber(),
    neighborhood: validateNeighborhood(),
    compliment: validateCompliment(),
    city: validateCity(),
    state: validateState(),
    church: validateChurch(),
    origin: validateOrigin(),
    obs: validateObs(),
  })

  return yupResolver(validateSchema)
}
