import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

import AmountInput from "@components/UI/Input/AmountInput"
import Input from "@components/UI/Input/FormInput"

import colors from "@styles/colors"
import { formatDonationAmount } from "@api/format/formatDonationAmount"
import DateMask from "@components/Masks/DateMask"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1920,
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  errorPlan: {
    color: colors.red,
    marginTop: 8,
  },
  installmentInfo: {
    marginTop: 10,
    fontFamily: "Raleway",
    fontWeight: 500,
    fontSize: 20,
    color: colors.darkFullText,
  },
  value: {
    color: colors.primaryColor,
    fontWeight: 600,
  },
  inputForms: {
    display: "flex",
    flexDirection: "column",
  },
  inputs: {
    paddingLeft: 4,
  },
  fontLabel: {
    "& .MuiTypography-body1": {
      fontFamily: "Raleway",
    },
  },
}))

export default function BookletPaymentForm(props) {
  const classes = useStyles()

  const {
    reset,
    getValues,
    control,
    errors,
    watch,
    bookletCustomAmount,
    setBookletCustomAmount,
    bookletAmountError,
    setBookletAmountError,
  } = props

  const handleChangeAmountInput = (e, value) => {
    e.preventDefault()

    setBookletCustomAmount(value)
    setBookletAmountError(false)
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} md={5} sm={10} className={classes.inputForms}>
        <AmountInput
          value={bookletCustomAmount}
          name="customAmount"
          label="Valor"
          onChange={handleChangeAmountInput}
        />

        {bookletAmountError && (
          <Typography className={classes.errorPlan}>
            Por favor, informe um valor válido!
          </Typography>
        )}

        <Typography className={classes.installmentInfo}>
          12 parcelas de{" "}
          <span className={classes.value}>
            {formatDonationAmount(bookletCustomAmount)}
          </span>
        </Typography>

        <Input
          name="dueDate"
          control={control}
          label="Data de Vencimento da 1ª parcela"
          inputComponent={DateMask}
          errors={errors}
          helperText="Data deve ser no máximo 30 dias após a data atual"
        />
      </Grid>
    </Grid>
  )
}
