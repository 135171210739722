import c from "../constants/userConstants"

const initialState = {
  user: {
    cpf: "",
    email: "",
    firstName: "",
    lastName: "",
    // password: "",
    bornAt: "",
    phone: "",
    phone2: "",
  },
  users: [],
  error: false,
  success: false,
  isLoading: false,
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case c.CREATE_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case c.CREATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        success: true,
      }
    }
    case c.CREATE_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
      }
    }
    case c.GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        success: true,
      }
    }
    case c.GET_ALL_USERS_FAILURE: {
      return {
        ...state,
        error: action.error,
      }
    }
    case c.CHANGE_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case c.CHANGE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        success: true,
      }
    }
    case c.CHANGE_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
      }
    }
    case c.DELETE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        success: true,
      }
    }
    case c.DELETE_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
      }
    }
    default:
      return state
  }
}
