import { RequestSDK } from "@sdk"

export const emailAlreadyExists = async (email) => {
  const response = await RequestSDK.maintainers.emailAlreadyExists(email)

  return response.data.alreadyExists
}

export const docAlreadyExists = async (doc) => {
  const response = await RequestSDK.maintainers.docAlreadyExists(doc)

  return response.data.alreadyExists
}
