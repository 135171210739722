const formatPhone = (phone) => {
  if (phone && phone.number) {
    return `${phone.ddi} (${phone.ddd}) ${phone.number.slice(
      0,
      5
    )}-${phone.number.slice(5, phone.number.length)}`
  }
  return ""
}

export default formatPhone
