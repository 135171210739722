import formatDate from "@api/format/team/formatDate"

const tableBlockedBody = (maintainers) => {
  if (maintainers) {
    const maintainersFormatted = maintainers.map((maintainer) => {
      const phoneFormatted = maintainer.user.phone
        ? `(${maintainer.user.phone.ddi}) ${maintainer.user.phone.ddd} ${maintainer.user.phone.number}`
        : ""

      return {
        id: maintainer._id,
        name: `${maintainer.user.firstName} ${
          maintainer.user.lastName == undefined ||
          maintainer.user.lastName == null
            ? ""
            : maintainer.user.lastName
        } `,
        email: maintainer.email,
        phone: phoneFormatted,
        register: formatDate(maintainer.createdAt).date,
      }
    })
    return maintainersFormatted
  } else {
    return []
  }
}

export default tableBlockedBody
