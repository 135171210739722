import React from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import Modal from "@components/UI/Modal/Modal"

import Title from "./Title"
import Input from "@components/UI/Input/FormInput"
// import Input from "@components/UI/Input/DefaultInput"
import Autocomplete from "./Autocomplete"
import AddButton from "@components/UI/Button/AddButton"

import ChurchForm from "./ChurchForm"
import OriginForm from "./OriginForm"

const useStyles = makeStyles({
  root: {
    marginBottom: 30,
  },
})

export default function ComplementForm(props) {
  const classes = useStyles()

  const { errors, control, reset, getValues, trigger } = props

  return (
    <Grid className={classes.root}>
      <Title text="Complemento" />
      <Grid container spacing={1} style={{ marginTop: 30 }}>
        <Grid item xs={12} sm={3} md={4}>
          <ChurchForm
            control={control}
            errors={errors}
            reset={reset}
            getValues={getValues}
            trigger={trigger}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={4}>
          <OriginForm
            control={control}
            errors={errors}
            reset={reset}
            getValues={getValues}
            trigger={trigger}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="obs"
            label="Observação"
            style={{ marginTop: 0 }}
            control={control}
            errors={errors}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
