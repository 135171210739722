import React, { Component } from "react"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"

const styles = (theme) => ({
  button: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    borderRadius: 100,
  },
})

class DefaultButton extends Component {
  render() {
    const { classes, className, children, ...rest } = this.props
    return (
      <Button
        disableElevation
        className={classNames(classes.button, className)}
        {...rest}
      >
        {children}
      </Button>
    )
  }
}

export default withStyles(styles)(DefaultButton)
