import axios from "axios"
const domains = {
  core: process.env.REACT_APP_API_DOMAIN,
}
const errors = {
  domainError: "Preencha o campo domínio!",
  urlError: "Preencha o campo url!",
  methodError: "Preencha o campo método!",
}
const verifyDomain = (domain) => {
  if (domain) return Object.keys(domains).includes(domain)
  throw new Error(errors.domainError)
}
const verifyErrors = ({ url, method }) => {
  if (!url) throw new Error(errors.urlError)
  if (!method) throw new Error(errors.methodError)
}
export const requestApi = (content) => {
  const {
    domain = "core",
    url,
    method = "get",
    headers: hr,
    params: pr,
    body,
  } = content
  try {
    verifyDomain(domain)
    verifyErrors(content)
    const baseURL = domains[domain]
    const jwt = process.browser
      ? "JWT " + localStorage.getItem("access_token")
      : ""
    const headers = {
      Platform: "web",
      Authorization: jwt,
      "Content-Type": "application/json",
      ...hr,
    }
    const params = pr || {}
    return axios({
      method,
      baseURL,
      url,
      headers,
      params,
      data: body || undefined,
    })
  } catch (err) {
    return err
  }
}
