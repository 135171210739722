import c from "../constants/authConstants"

const initialState = {
  user: null,
  token: localStorage.getItem("access_token") || null,
  email: "",
  loading: false,
  error: false,
  errorUser: false,
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case c.LOGIN_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true,
      }
    }
    case c.LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        email: action.payload.email,
        loading: false,
        error: false,
      }
    }
    case c.LOGIN_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      }
    }

    case c.CLEAR_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case c.GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        errorUser: false,
      }
    }

    case c.GET_USER_FAILURE: {
      return {
        ...state,
        errorUser: true,
      }
    }

    case c.CLEAR_TOKEN: {
      return {
        ...state,
        token: null,
      }
    }

    default:
      return state
  }
}
