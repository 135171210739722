import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Grid, Typography } from "@material-ui/core"
import SearchBar from "@components/Mantainers/GenerateLabel/SearchBar"
import Table from "@components/Mantainers/GenerateLabel/Table"
import ModalGenerateLabel from "./ModalGenerateLabel"
import AddButton from "@components/UI/Button/AddButton"

import { tableAddTeamHead } from "@api/tables/tablesHead"
import tableAddTeam from "@api/tables/Team/tableAddTeam"

import { getUsersSearch } from "@redux/actions/userActions"
import { generateLabel } from "@redux/actions/tagsActions"

import { alert } from "@redux/actions/alertActions"

import TableRow from "@components/Team/TableRow"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    padding: "0 20px",
  },
  container: {
    paddingLeft: 15,
  },
  text: {
    color: colors.darkText,
    fontSize: 25,
    marginTop: 40,
  },
})

class GenerateLabel extends Component {
  state = {
    value: "",
    usersSelected: [],
    inputValue: "",
    modalIsOpen: false,
    page: 0,
    rowsPerPage: 10,
  }

  searchValue = (user, value) => {
    const { usersSelected } = this.state
    if (user) {
      if (usersSelected.find((u) => u.email == value)) {
        this.props.dispatch(alert("error", "Usuário já foi selecionado"))
      } else {
        this.props.dispatch(alert("success", "Usuário inserido!"))
        this.setState({
          usersSelected: usersSelected.concat(user),
          value: "",
        })
      }
    }
  }

  handleSearch = (user, valueInput) => {
    const { value } = this.state
    if (valueInput) {
      this.searchValue(user, valueInput)
    } else {
      this.searchValue(user, value)
    }
  }

  handleDelete = (row) => {
    const { usersSelected } = this.state
    const users = usersSelected.filter((user) => user.email !== row.email)
    this.setState({ usersSelected: users })
    this.props.dispatch(alert("success", "Usuário removido com sucesso!"))
  }

  handleSubmit = () => {
    const { usersSelected } = this.state
    if (usersSelected.length == 0) {
      this.props.dispatch(alert("error", "Nenhum usuário selecionado!"))
    } else {
      const emails = usersSelected.map((user) => user.email)
      this.props.dispatch(generateLabel(emails))
      this.setState({ value: "" })
    }
  }

  removeDuplicates = (inArray) => {
    var arr = inArray.concat()
    for (var i = 0; i < arr.length; ++i) {
      for (var j = i + 1; j < arr.length; ++j) {
        if (arr[i].email === arr[j].email) {
          arr.splice(j, 1)
        }
      }
    }
    return arr
  }

  handleSubmitModal = (users) => {
    const { usersSelected } = this.state
    const usersAll = this.removeDuplicates(usersSelected.concat(users))

    this.setState({
      modalIsOpen: false,
      usersSelected: usersAll,
    })
  }

  handleClick = () => {
    this.setState({ modalIsOpen: true })
  }

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false })
  }

  handleChangeSearchKey = ({ target }) => {
    this.setState({ value: target.value })
  }

  handleChangePage = (newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) =>
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })

  render() {
    const { classes } = this.props
    const {
      usersSelected,
      inputValue,
      modalIsOpen,
      page,
      rowsPerPage,
    } = this.state
    return (
      <Grid container className={classes.root}>
        <ModalGenerateLabel
          open={modalIsOpen}
          handleClose={this.handleCloseModal}
          handleSubmit={this.handleSubmitModal}
        />

        <Grid container className={classes.container}>
          <Typography variant="h2" gutterBottom className={classes.text}>
            Pesquise e selecione os usuários para gerar as etiquetas
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={1} md={1} lg={1} className={classes.AddButton}>
            <AddButton onCLick={this.handleClick} />
          </Grid>
          <Grid item xs={12} sm={11} md={11} lg={11}>
            <SearchBar
              value={inputValue}
              changeKey={this.handleChangeSearchKey}
              search={this.handleSearch}
              teste={this.handleSearchTeste}
            />
          </Grid>
        </Grid>

        <Table
          headConfig={tableAddTeamHead}
          data={tableAddTeam(usersSelected)}
          currentPage={page}
          rowsPerPage={rowsPerPage}
          changePage={this.handleChangePage}
          changeRows={this.handleChangeRowsPerPage}
          handleSubmit={this.handleSubmit}
          row={
            <TableRow
              tableCellOptions={["name", "email"]}
              handleDeleteRow={this.handleDelete}
              handleClickRow={(id) => null}
            />
          }
        />
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const { users } = state.get("user")
  return { users }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(GenerateLabel))
)
