import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { getPayments } from "@redux/actions/maintainerActions"
import { getPeoples } from "@redux/actions/maintainerActions"
import Grid from "@material-ui/core/Grid"
import { Switch, Route, Redirect } from "react-router-dom"

//Tab
import GenericalTab from "@components/UI/Tab/GenricalTab"

import getPages from "@api/maintainers/getPages"

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  container: {
    flex: 1,
    padding: "0 5px",
    marginLeft: 56,
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
})
class Mantainers extends Component {
  state = {
    currentTab: 0,
    inputValue: "",
    filter: "Todos",
    valueFilter: "Todos os dias",
    options: [
      { name: "Todos os dias" },
      { name: "1-5" },
      { name: "5-10" },
      { name: "10-15" },
      { name: "15-20" },
      { name: "20-25" },
      { name: "25-31" },
    ],
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      this.setState({ valueFilter: "Todos os dias" })
    }
  }

  handleChangeSelect = (e) => {
    this.setState({ valueFilter: e.target.value })
    if (e.target.value !== this.state.valueFilter) {
      switch (this.getPath()) {
        case "pagamentos":
          this.props.dispatch(
            getPayments(1, 10, "active", "payment", e.target.value)
          )
          break
        case "pendencias":
          this.props.dispatch(
            getPayments(1, 10, "pending", "pending", e.target.value)
          )
          break
        case "renovacoes":
          this.props.dispatch(
            getPayments(1, 10, "active", "renovation", e.target.value)
          )
          break
      }
    }
  }

  handleChangeSearchKey = ({ target }) =>
    this.setState({ inputValue: target.value })

  handleSearch = () => {
    const { inputValue } = this.state
    const search = {
      pessoas: getPeoples(1, 10, inputValue),
      pagamentos: getPayments(1, 10, "active", "payment", inputValue),
      pendencias: getPayments(1, 10, "pending", "pending"),
      renovacoes: getPayments(1, 10, "active", "renovation"),
    }
    if (this.getPath() == "pessoas") {
      this.props.dispatch(search[this.getPath()])
    }
  }

  handleChange = (e, newValue) => this.setState({ currentTab: newValue })

  handleChangeFilter = (filter) => this.setState({ filter })

  handleRenderContent = () => {
    return (
      <Switch>
        {getPages().map((route) => (
          <Route
            path={`/app/mantenedores/${route.path}`}
            component={route.component}
          />
        ))}
        <Redirect to="/app/mantenedores/relatorios" />
      </Switch>
    )
  }

  handleClick = () => {
    this.props.history.replace(`/app/adiciona-mantenedor`)
  }

  getPath = () => {
    const path = this.props.location.pathname.split("/")
    return path[3]
  }

  render() {
    const { classes } = this.props
    const { currentTab, inputValue, filter, valueFilter, options } = this.state
    return (
      <Grid container className={classes.root}>
        <GenericalTab tabs={getPages()} current={currentTab} />

        <Grid container className={classes.container}>
          {this.handleRenderContent()}
        </Grid>
      </Grid>
    )
  }
}

export default connect()(withRouter(withStyles(styles)(Mantainers)))
