import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"

import colors from "@styles/colors"
import SidebarContent from "@components/Sidebar/SidebarContent"

const drawerWidth = 80

const styles = (theme) => ({
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.primaryColor,
    border: "none",
  },
})

class SidebarWeb extends Component {
  render() {
    const { classes } = this.props

    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
      >
        <SidebarContent />
      </Drawer>
    )
  }
}

export default withStyles(styles)(SidebarWeb)
