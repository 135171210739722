export default {
  ORIGIN_REQUEST: "ORIGIN_REQUEST",
  ORIGIN_SUCCESS: "ORIGIN_SUCCESS",
  ORIGIN_FAILURE: "ORIGIN_FAILURE",

  ORIGIN_PAGINATED_REQUEST: "ORIGIN_PAGINATED_REQUEST",
  ORIGIN_PAGINATED_SUCCESS: "ORIGIN_PAGINATED_SUCCESS",
  ORIGIN_PAGINATED_FAILURE: "ORIGIN_PAGINATED_FAILURE",

  CREATE_ORIGIN_SUCCESS: "CREATE_ORIGIN_SUCCESS",
  DELETE_ORIGIN_SUCCESS: "DELETE_ORIGIN_FAILURE",
  EDIT_ORIGIN_SUCCESS: "DELETE_ORIGIN_FAILURE",
}
