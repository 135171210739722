import React, { Component } from "react"
import { withStyles, Grid } from "@material-ui/core"
import { connect } from "react-redux"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import hexToRgba from "hex-to-rgba"
import TableOptionButton from "@components/UI/Table/TableOptionButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  tableCell: {
    fontWeight: 400,
    fontFamily: "Quicksand",
    fontSize: 15,
    color: colors.grayText,
    border: "none",
  },
  tableCellYellow: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: hexToRgba(colors.yellow, "0.5"),
    borderRadius: 49,
    color: colors.grayText,
  },
  tableIcon: {
    padding: 8,
  },
  markedWithRed: {
    backgroundColor: hexToRgba(colors.red, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.red, "0.1"),
    },
  },
  markedWithGreen: {
    backgroundColor: hexToRgba(colors.green, "0.1"),
    borderRadius: 49,
    color: colors.grayText,
    "&:hover": {
      backgroundColor: hexToRgba(colors.green, "0.1"),
    },
  },
})

class CustomTableRow extends Component {
  render() {
    const { classes, index, row, handleEditRow, handleDeleteRow } = this.props
    return (
      <TableRow
        key={index}
        classes={{
          root: classes.root,
        }}
      >
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {index}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCell}
        >
          {row.name}
        </TableCell>
        <Grid container justify="flex-end">
          <TableOptionButton type="edit" funct={() => handleEditRow(row)} />
          <TableOptionButton type="del" funct={() => handleDeleteRow(row.id)} />
        </Grid>
      </TableRow>
    )
  }
}

export default connect()(withStyles(styles)(CustomTableRow))
