import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography, Switch } from "@material-ui/core"

import colors from "@styles/colors"

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 36,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2.5,
    color: colors.primaryColor,
    "&$checked": {
      transform: "translateX(24px)",
      color: colors.primaryColor,
      "& + $track": {
        opacity: 1,
        backgroundColor: colors.whiteText,
        borderColor: colors.main,
      },
    },
  },
  thumb: {
    width: 30,
    height: 30,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${colors.primaryColor}`,
    borderRadius: 16,
    opacity: 1,
    backgroundColor: colors.whiteText,
  },
  checked: {},
}))(Switch)

const styles = (theme) => ({
  text: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 10,
    color: colors.primaryColor,
  },
})

class CustomSwitch extends React.Component {
  handleChange = (e) => {
    this.props.onChange(e.target.checked)
  }

  render() {
    const { classes, value, firstText, lastText } = this.props
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-start"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Grid
          item
          className={classes.text}
          style={{
            opacity: value ? 0.5 : 1,
          }}
        >
          {firstText}
        </Grid>
        <Grid item>
          <AntSwitch checked={value} onChange={this.handleChange} />
        </Grid>
        <Grid
          item
          className={classes.text}
          style={{
            opacity: value ? 1 : 0.5,
          }}
        >
          {lastText}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(CustomSwitch)
