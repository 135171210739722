import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Grid, Typography } from "@material-ui/core"
import SearchBar from "@components/Team/AddTeam/SearchBar"
import Table from "@components/Team/AddTeam/Table"

import { tableAddTeamHead } from "@api/tables/tablesHead"
import tableAddTeam from "@api/tables/Team/tableAddTeam"

import { getUsersSearch } from "@redux/actions/userActions"
import { createAdmins } from "@redux/actions/teamActions"

import { alert } from "@redux/actions/alertActions"

import TableRow from "@components/Team/TableRow"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    padding: "0 20px",
  },
  container: {
    paddingLeft: 15,
  },
  text: {
    color: colors.darkText,
    fontSize: 25,
    marginTop: 40,
  },
})

class AddTeam extends Component {
  state = {
    value: "",
    usersSelected: [],
  }

  componentDidMount() {
    this.props.dispatch(getUsersSearch())
  }

  handleChangeSearchKey = ({ target }) => this.setState({ value: target.value })

  handleSearch = () => {
    const { users } = this.props
    const { value, usersSelected } = this.state
    if (value.length > 0) {
      const user = users.find((user) => user.email === value)
      if (user) {
        if (usersSelected.find((u) => u == user)) {
          this.props.dispatch(alert("error", "Usuário já foi selecionado"))
        } else {
          this.props.dispatch(
            alert("success", "Usuário cadastrado com sucesso!")
          )
          this.setState({ usersSelected: usersSelected.concat(user) })
        }
      }
    }
  }

  handleDelete = (row) => {
    const { usersSelected } = this.state
    const users = usersSelected.filter((user) => user.email !== row.email)
    this.setState({ usersSelected: users })
    this.props.dispatch(alert("success", "Usuário removido com sucesso!"))
  }

  handleSubmit = () => {
    const { usersSelected } = this.state
    if (usersSelected.length == 0) {
      this.props.dispatch(alert("error", "Nenhum usuário selecionado!"))
    } else {
      const emails = usersSelected.map((user) => user.email)
      this.props.dispatch(createAdmins(emails))
      this.props.history.push("/app/time/administradores/1")
    }
  }

  render() {
    const { classes } = this.props
    const { value, usersSelected } = this.state
    return (
      <Grid container className={classes.root}>
        <Grid container className={classes.container}>
          <Typography variant="h2" gutterBottom className={classes.text}>
            Pesquise e selecione os usuários que se tornarão colaboradores
          </Typography>
        </Grid>
        <SearchBar
          value={value}
          changeKey={this.handleChangeSearchKey}
          search={this.handleSearch}
        />
        <Table
          headConfig={tableAddTeamHead}
          data={tableAddTeam(usersSelected)}
          handleSubmit={this.handleSubmit}
          row={
            <TableRow
              tableCellOptions={["name", "email"]}
              handleDeleteRow={this.handleDelete}
            />
          }
        />
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const { users } = state.get("user")
  return { users }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(AddTeam)))
