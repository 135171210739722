import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

import TableHead from "@components/UI/Table/TableHead"
import Button from "@components/UI/Button/PrimaryButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: 15,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  papper: {
    width: "100%",
    borderRadius: 6,
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 53px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  table: {
    width: "100%",
  },
  titles: {
    height: 40,
  },
  title: {
    fontWeight: 500,
    fontFamily: "Quicksand",
    color: colors.darkText,
    fontSize: 18,
  },
  pagination: {
    marginTop: 20,
    fontWeight: 400,
    fontFamily: "Quicksand",
    color: colors.grayText,
  },
  button: {
    padding: 24,
    paddingBottom: 0,
    paddingRight: 0,
  },
})

class TableTeam extends Component {
  handleRenderRow = (row, index) => {
    return React.cloneElement(this.props.row, { row, index })
  }

  render() {
    const { classes, headConfig, data } = this.props
    return (
      <Grid container justify="center" className={classes.root}>
        <Paper className={classes.papper}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead data={headConfig} />
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <Fragment>{this.handleRenderRow(row, index + 1)}</Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            className={classes.button}
          >
            <Button onClick={this.props.handleSubmit}>CONCLUIR</Button>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

export default withStyles(styles)(TableTeam)
