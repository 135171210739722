import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import { useSelector } from "react-redux"

import ForgotPasswordInput from "@components/ForgotPassword/ForgotPasswordInput"
import ForgotPasswordSuccess from "@components/ForgotPassword/ForgotPasswordSuccess"

import Loading from "@components/UI/Loading/Loading"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  paperContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 550,
    },
  },
}))

export default function ForgotPassword(props) {
  const classes = useStyles()

  const { success } = useSelector((state) => state.get("password"))

  return (
    <Grid container className={classes.root}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={11} className={classes.paperContainer}>
          {success ? <ForgotPasswordSuccess /> : <ForgotPasswordInput />}
        </Grid>
      </Grid>
    </Grid>
  )
}
