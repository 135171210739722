import c from "../constants/passwordConstants"
import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"
import { RequestSDK } from "@sdk"

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(forgotPasswordRequest(data))
    RequestSDK.authentication
      .forgotPassword(data)
      .then((response) => {
        dispatch(forgotPasswordSuccess(response.data))
        dispatch(alert("success", "Email de recuperação enviado com sucesso!"))
      })
      .catch((error) => {
        dispatch(forgotPasswordFailure(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const forgotPasswordRequest = (data) => {
  return {
    type: c.FORGOT_PASSWORD_REQUEST,
    data,
  }
}

export const forgotPasswordSuccess = (payload) => {
  return {
    type: c.FORGOT_PASSWORD_SUCCESS,
    payload,
  }
}

export const forgotPasswordFailure = (error) => {
  return {
    type: c.FORGOT_PASSWORD_FAILURE,
    error,
  }
}

export const recoverPassword = (data, callback) => {
  return (dispatch) => {
    dispatch(recoverPasswordRequest(data))
    RequestSDK.authentication
      .recoverPassword(data)
      .then((response) => {
        dispatch(recoverPasswordSuccess(response.data))
        dispatch(alert("success", "Senha alterada com sucesso!"))

        if (callback) callback()
      })
      .catch((error) => {
        dispatch(recoverPasswordFailure(error.response))
        dispatch(alert("error", getErrorMessage(error)))
      })
  }
}

export const recoverPasswordRequest = (data) => {
  return {
    type: c.RECOVER_PASSWORD_REQUEST,
    data,
  }
}

export const recoverPasswordSuccess = (payload) => {
  return {
    type: c.RECOVER_PASSWORD_SUCCESS,
    payload,
  }
}

export const recoverPasswordFailure = (error) => {
  return {
    type: c.RECOVER_PASSWORD_FAILURE,
    error,
  }
}

export const changePassword = (data, callback) => {
  return (dispatch) => {
    dispatch(changePasswordRequest(data))

    RequestSDK.authentication
      .changePassword(data)
      .then((response) => {
        dispatch(changePasswordSuccess(response.data))
        dispatch(alert("success", "Senha alterada com sucesso!"))

        if (callback) callback()
      })
      .catch((error) => {
        dispatch(changePasswordFailure(error.response))
        dispatch(alert("error", "Senha inválido!"))
      })
  }
}

export const changePasswordRequest = (data) => {
  return {
    type: c.CHANGE_PASSWORD_REQUEST,
    data,
  }
}

export const changePasswordSuccess = (payload) => {
  return {
    type: c.CHANGE_PASSWORD_SUCCESS,
    payload,
  }
}

export const changePasswordFailure = (error) => {
  return {
    type: c.CHANGE_PASSWORD_FAILURE,
    error,
  }
}
