import React, { Component } from "react"
import { Avatar } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import DefaultButton from "./DefaultButton"
import forwardArrow from "@static/icons/forwardArrow.svg"

import colors from "@styles/colors"

const styles = (theme) => ({
  button: {
    color: colors.whiteText,
    width: 180,
    height: 48,
    backgroundColor: colors.primaryColor,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
})

class ForwardButton extends Component {
  render() {
    const { classes, children, ...rest } = this.props
    return (
      <DefaultButton
        className={classes.button}
        endIcon={<Avatar src={forwardArrow} className={classes.small} />}
        {...rest}
      >
        {children}
      </DefaultButton>
    )
  }
}

export default withStyles(styles)(ForwardButton)
