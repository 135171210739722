import formatZipcode from "./formatZipcode"
import formatPhone from "./formatPhone"

const getFormattedEditMaintainer = (body) => {
  if (body.user) {
    const church = body.complementary.church
    const origin = body.complementary.origin
    return {
      name: body.user.firstName,
      lastName: body.user.lastName,
      cpfCnpj: body.documentNumber,
      email: body.email,
      bornAt: body.user.bornAt,
      phone: formatPhone(body.user.phone),
      phone2: formatPhone(body.user.secondPhone),
      zipcode: formatZipcode(body.user.address.zipcode),
      street: body.user.address.street,
      neighborhood: body.user.address.neighborhood,
      number: body.user.address.streetNumber,
      complement: body.user.address.complementary,
      city: body.user.address.city,
      state: body.user.address.state,
      church: church,
      origin: origin,
      obs: body.complementary.observation,
      // address: { zipcode: "" },
    }
  }
  return null
}

export default getFormattedEditMaintainer
