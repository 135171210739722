import c from "../constants/passwordConstants"

const initialState = {
  user: null,
  token: null,
  email: "",
  loading: false,
  error: false,
  success: false,
}

export default function password(state = initialState, action) {
  switch (action.type) {
    case c.FORGOT_PASSWORD_REQUEST: {
      return {
        ...state,
        targetEmail: action.data.email,
        emailSended: false,
        loading: true,
        error: false,
      }
    }
    case c.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        emailSended: true,
        loading: false,
        success: true,
      }
    }
    case c.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    }

    case c.RECOVER_PASSWORD_REQUEST: {
      return {
        ...state,
        emailUpdated: false,
        loading: true,
        error: false,
      }
    }
    case c.RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        emailUpdated: true,
        loading: false,
      }
    }
    case c.RECOVER_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    }

    case c.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        emailUpdated: false,
        loading: true,
        error: false,
      }
    }
    case c.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        emailUpdated: true,
        loading: false,
      }
    }
    case c.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    }

    default:
      return state
  }
}
