import c from "../constants/sentMessagesConstants"
import { alert } from "@redux/actions/alertActions"
import getErrorMessage from "@api/utils/getErrorMessage"
import { RequestSDK } from "@sdk"

export const getSentMessages = (page, rowsPerPage, typeMessage) => {
  const typeMessageParam = typeMessage ? `?typeMessage=${typeMessage}` : ""
  return (dispatch) => {
    dispatch(sentMessagesRequest())
    RequestSDK.communications
      .getSentMessages(page, rowsPerPage, typeMessageParam)
      .then((response) => {
        dispatch(sentMessagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(sentMessagesFailure(error.response))
      })
  }
}

export const getSentMessagesById = (id) => {
  return (dispatch) => {
    dispatch(sentMessagesRequest())
    RequestSDK.communications
      .getSentMessagesById(id)
      .then((response) => {
        dispatch(sentMessagesSuccess(response.data.data))
      })
      .catch((error) => {
        dispatch(sentMessagesFailure(error.response))
      })
  }
}

export const searchEmail = (search) => {
  return (dispatch) => {
    dispatch(sentMessagesRequest())
    RequestSDK.communications
      .searchEmail(search)
      .then((response) => {
        dispatch(alert("success", "Pesquisa realizada"))
        dispatch(sentMessagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(alert("error", getErrorMessage(error)))
        dispatch(sentMessagesFailure(error.response))
      })
  }
}

export const searchPhone = (search) => {
  return (dispatch) => {
    dispatch(sentMessagesRequest())
    RequestSDK.communications
      .searchPhone(search)
      .then((response) => {
        dispatch(alert("success", "Pesquisa realizada"))
        dispatch(sentMessagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(alert("error", getErrorMessage(error)))
        dispatch(sentMessagesFailure(error.response))
      })
  }
}

export const sentMessagesRequest = () => {
  return {
    type: c.SENT_MESSAGES_REQUEST,
  }
}

export const sentMessagesSuccess = (payload) => {
  return {
    type: c.SENT_MESSAGES_SUCCESS,
    payload,
  }
}

export const sentMessagesFailure = () => {
  return { type: c.SENT_MESSAGES_FAILURE }
}
