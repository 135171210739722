import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"

import DefaultButton from "./DefaultButton"

import colors from "@styles/colors"

const styles = (theme) => ({
  button: {
    color: colors.whiteText,
    width: 180,
    height: 50,
    fontSize: 12,
    backgroundColor: colors.secondaryColor,
    "&:hover": {
      backgroundColor: colors.secondaryColor,
    },
  },
})

class SecondaryButton extends Component {
  render() {
    const { children, ...rest } = this.props
    return <DefaultButton {...rest}>{children}</DefaultButton>
  }
}

export default withStyles(styles)(SecondaryButton)
