import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import { getUser, logout } from "@redux/actions/authActions"
import { connect } from "react-redux"

import colors from "@styles/colors"
import CurrentMenuTitle from "@api/utils/getCurrentMenuTitle"
import DropDown from "@components/UI/Menu/CustomDropdown"
import TableOptionButton from "@components/UI/Table/TableOptionButton"

const styles = (theme) => ({
  root: {
    width: "100%",
    height: 80,
  },
  headerBox: {
    width: "100%",
    marginLeft: 56,
    marginRight: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: 85,
    },
  },

  title: {
    fontSize: 30,
    fontWeight: 400,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
    },
    paddingLeft: 16,
  },
  profileBox: {
    display: "flex",
    alignItems: "center",
  },
  profileName: {
    fontSize: 18,
    fontFamily: "Quicksand",
    color: colors.darkText,
    fontWeight: 500,
  },
})

class Header extends Component {
  componentDidMount() {
    this.props.dispatch(getUser())
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorUser !== this.props.errorUser) {
      this.props.dispatch(logout())
    }
  }

  verifyPath = () => {
    const path = this.props.location.pathname.toLowerCase().split("/")
    return path[2] == "painel-mantenedor"
  }

  handleClickClose = () => {
    this.props.history.goBack()
  }

  render() {
    const { classes, user } = this.props

    return (
      <Grid container className={classes.root}>
        <Grid className={classes.headerBox}>
          <Grid container>
            {this.verifyPath() ? (
              <TableOptionButton
                isTable={false}
                type="close"
                funct={this.handleClickClose}
              />
            ) : null}
            <Typography className={classes.title} variant="h1">
              <CurrentMenuTitle />
            </Typography>
          </Grid>
          <Box className={classes.profileBox}>
            <Typography className={classes.profileName}>
              {user && user.email ? user.email : null}
            </Typography>
            <DropDown />
          </Box>
        </Grid>
      </Grid>
    )
  }
}
const mapStateToProps = (state) => {
  const { user, token, errorUser } = state.get("auth")
  return {
    user,
    token,
    errorUser,
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Header)))
