import formatDate from "@api/format/team/formatDate"

const tableRenovationsBody = (maintainers) => {
  if (maintainers) {
    const pendenciesFormatted = maintainers.map((maintainer) => {
      const typeMethod = () => {
        if (maintainer.paymentMethod == "credit_card") {
          return "Cartão de crédito"
        } else if (maintainer.paymentMethod == "booklet") {
          return "Boleto"
        } else {
          return ""
        }
      }
      return {
        id: maintainer.maintainer._id,
        name: `${maintainer.maintainer.firstName} ${
          maintainer.maintainer.lastName == undefined ||
          maintainer.maintainer.lastName == null
            ? ""
            : maintainer.maintainer.lastName
        } `,
        overdue: maintainer.currentPeriodEnd
          ? formatDate(maintainer.currentPeriodEnd).date
          : "",
        paid: `${maintainer.paid?.installments}/${maintainer.paid?.installments}`,
        method: typeMethod(),
        amount: maintainer.plan
          ? `R$ ${parseFloat(maintainer.plan.amount).toFixed(2)}`
          : "R$ 00,00",
      }
    })
    return pendenciesFormatted
  } else {
    return []
  }
}

export default tableRenovationsBody
