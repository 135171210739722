import React, { useMemo } from "react"

import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import MessageItem from "./MessageItem"

import getMessagesItems from "@api/utils/getMessagesItems"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
    gap: "1rem",
    borderRadius: "0.6rem",
  },

  title: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    marginBottom: "0.2rem",
  },
}))

export default function FlowItem({ message, inputValue }) {
  const classes = useStyles()

  const messages = useMemo(() => getMessagesItems(message.messages), [message])

  return (
    <Grid item xs={12} lg={4}>
      <Paper elevation={2} className={classes.container}>
        <Typography variant="h5" component="h5" className={classes.title}>
          {message.title}
        </Typography>

        {messages.map((messageItem, index) => (
          <MessageItem
            key={`message-item-${index}`}
            messageType={messageItem.type}
            isWritten={messageItem.written}
            messageItem={{ messageId: message._id, message: messageItem }}
            inputValue={inputValue}
          />
        ))}
      </Paper>
    </Grid>
  )
}
