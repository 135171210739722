import React, { Component, Fragment, ReactDOM } from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { withRouter } from "react-router-dom"

import Table from "@components/UI/Table/GeneralTable"
import TableRow from "./TableRow"
import { getPeoples } from "@redux/actions/maintainerActions"
import Loading from "@components/UI/Loading/Loading"

import AddButton from "@components/UI/Button/AddButton"
import Searchbar from "@components/UI/Input/SearchInput"
import FiltersButtons from "@components/UI/Filters/PrimaryFiltersButtons"
import { tablePeoplesHead } from "@api/tables/tablesHead"
import tablePeoplesBody from "@api/tables/Maintainers/Peoples/tablePeoplesBody"
import reactDom from "react-dom"

const styles = (theme) => ({})

class Peoples extends Component {
  state = {
    page: this.props.match.params.p - 1 || 0,
    rowsPerPage: 5,
    currentTab: 0,
    inputValue: "",
    filter: "Todos",
    valueFilter: "Todos os dias",
    options: [
      { name: "Todos os dias" },
      { name: "1-5" },
      { name: "5-10" },
      { name: "10-15" },
      { name: "15-20" },
      { name: "20-25" },
      { name: "25-31" },
    ],
  }

  componentDidMount() {
    const { page, rowsPerPage } = this.state
    this.props.history.replace(
      `/app/mantenedores/pessoas/${this.props.currentPage}`
    )
    this.props.dispatch(getPeoples(page + 1, rowsPerPage))
    reactDom.findDOMNode(this).scrollTo(100, 0)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      const page = this.props.currentPage - 1
      this.setState({ page })
      this.props.history.replace(
        `/app/mantenedores/pessoas/${this.props.currentPage}`
      )
    }
    if (prevProps.rowsPerPage !== this.props.rowsPerPage) {
      this.setState({ rowsPerPage: this.props.rowsPerPage })
    }
    if (this.props.match.params.p == ":p") {
      this.props.history.replace(
        `/app/mantenedores/pessoas/${this.props.currentPage}`
      )
    }
  }

  handleChangePage = (newPage) => {
    const { page, rowsPerPage } = this.state
    if (newPage > page) {
      const hasNextPage = this.props.hasNextPage
      const next = this.props.next
      if (hasNextPage && next <= this.props.totalPages) {
        this.props.history.replace(`/app/mantenedores/pessoas/${next}`)
        this.props.dispatch(getPeoples(next, rowsPerPage))
      }
    } else {
      const hasPrevPage = this.props.hasPrevPage
      const prev = this.props.prev
      if (hasPrevPage) {
        this.props.history.replace(`/app/mantenedores/pessoas/${prev}`)
        this.props.dispatch(getPeoples(prev, rowsPerPage))
      }
    }
  }

  handleClickRow = (row) => {
    this.props.history.push(`/app/painel-mantenedor/${row.id}`)
  }

  handleChangeRowsPerPage = (event) => {
    this.props.dispatch(
      getPeoples(this.props.currentPage, parseInt(event.target.value, 10))
    )
  }

  handleClick = () => {
    this.props.history.push(`/app/adiciona-mantenedor`)
  }

  handleChangeSearchKey = ({ target }) =>
    this.setState({ inputValue: target.value })

  handleSearch = () => {
    const { inputValue } = this.state
    this.props.dispatch(getPeoples(1, 10, inputValue))
  }

  render() {
    const { classes, maintainers, isLoading, rowsCount } = this.props
    const {
      page,
      rowsPerPage,
      inputValue,
      filter,
      valueFilter,
      options,
    } = this.state

    return (
      <Grid container>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                className={classes.AddButton}
              >
                <AddButton onCLick={this.handleClick} />
              </Grid>
              <Grid item xs={12} sm={11} md={11} lg={11}>
                <Searchbar
                  value={inputValue}
                  changeKey={this.handleChangeSearchKey}
                  search={this.handleSearch}
                  typeSearch="nome, email ou telefone"
                >
                  <FiltersButtons
                    filter={filter}
                    // change={this.handleChangeFilter}
                    change={() => null}
                  />
                </Searchbar>
              </Grid>
            </Grid>
            <Table
              headConfig={tablePeoplesHead}
              data={tablePeoplesBody(maintainers)}
              count={rowsCount}
              currentPage={page}
              pagination="back"
              rowsPerPage={rowsPerPage}
              changePage={this.handleChangePage}
              changeRows={this.handleChangeRowsPerPage}
              row={<TableRow handleClickRow={this.handleClickRow} />}
            />
          </Fragment>
        )}
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  const {
    maintainers,
    isLoading,
    rowsPerPage,
    totalPages,
    rowsCount,
    currentPage,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
  } = state.get("maintainer")
  return {
    maintainers,
    isLoading,
    rowsPerPage,
    totalPages,
    rowsCount,
    currentPage,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Peoples)))
