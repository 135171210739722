export class MaintainersRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async getReports(initialDate, finalDate, origin) {
    return this.requestApi({
      url: `/maintainers/report-origin`,
      method: "get",
      params: {
        initialDate,
        finalDate,
        originApp: origin,
      },
    })
  }

  async emailAlreadyExists(email) {
    return this.requestApi({
      url: `/maintainers/check?email=${email}`,
      method: "get",
    })
  }

  async docAlreadyExists(doc) {
    return this.requestApi({
      url: `/maintainers/check?documentNumber=${doc}`,
      method: "get",
    })
  }

  async getMaintainer(id) {
    return this.requestApi({
      url: `/maintainers/${id}`,
      method: "get",
    })
  }

  async editMaintainer(id, body) {
    return this.requestApi({
      url: `/maintainers/${id}`,
      method: "patch",
      body,
    })
  }

  async createMaintainer(body) {
    return this.requestApi({
      url: `/maintainers`,
      method: "post",
      body,
    })
  }

  async getLabelByFilter(initialDate, finalDate, type) {
    return this.requestApi({
      url: `/maintainers/filter`,
      method: "get",
      params: {
        initialDate,
        finalDate,
        type,
      },
    })
  }
}
