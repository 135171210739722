import React, { Fragment } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import { logout } from "@redux/actions/authActions"
import Menu from "@material-ui/core/Menu"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"

import ListItem from "@material-ui/core/ListItem"

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#16697A",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />
}

function CustomizedMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickButton = (event) => {
    setAnchorEl(event.target.children[0])
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    props.dispatch(logout())
    props.history.push("/login")
    handleClose()
  }
  const changePassword = () => {
    handleClose()
    props.history.push("/app/alterar-senha")
  }

  return (
    <IconButton onClick={handleClickButton}>
      <ExpandMoreIcon
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={changePassword}>Mudar senha</MenuItem>
        <MenuItem onClick={handleLogout}>Sair</MenuItem>
      </Menu>
    </IconButton>
  )
}

export default connect()(withRouter(CustomizedMenu))
