import getStateName from "@api/utils/getStateName"
import c from "../constants/teamConstants"

const initialState = {
  all: [],
  admins: [],
  usersCount: 0,
  userPerPage: 5,
  currentPage: 1,
  hasNextPage: false,
  hasPrevPage: false,
  next: null,
  prev: null,
  totalPages: null,
  isLoading: false,
  error: false,
}

export default function team(state = initialState, action) {
  switch (action.type) {
    case c.CREATE_ADMIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case c.CREATE_ADMIN_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        error: false,
        isLoading: false,
      }
    }
    case c.CREATE_ADMIN_FAILURE: {
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    }
    case c.GET_ADMINS_SUCCESS: {
      return {
        ...state,
        admins: action.payload.admins,
        usersCount: action.payload.adminsCount,
        userPerPage: action.payload.adminsPerPage,
        currentPage: action.payload.currentPage,
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        next: action.payload.next,
        prev: action.payload.prev,
        totalPages: action.payload.totalPages,
        isLoading: false,
        error: false,
      }
    }
    case c.GET_USERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case c.GET_USERS_SUCCESS: {
      return {
        ...state,
        all: action.payload.users,
        usersCount: action.payload.usersCount,
        userPerPage: action.payload.usersPerPage,
        currentPage: action.payload.currentPage,
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        next: action.payload.next,
        prev: action.payload.prev,
        totalPages: action.payload.totalPages,
        isLoading: false,
        error: false,
      }
    }
    case c.GET_ADMIN_SUCCESS: {
      return {
        ...state,
        admins: state.admins.concat(action.payload),
        isLoading: false,
        error: false,
      }
    }
    case c.REMOVE_ADMIN_SUCCESS: {
      return {
        ...state,
        admins: state.admins.filter((admin) => admin.email !== action.payload),
        isLoading: false,
        error: false,
      }
    }
    case c.GET_ADMIN_FAILURE: {
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    }
    case c.GET_USERS_FAILURE: {
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    }
    case c.CLEAR_ADMIN: {
      return {
        ...state,
        all: [],
        admins: [],
        userCount: 0,
        userPerPage: 5,
        currentPage: 1,
        hasNextPage: false,
        hasPrevPage: false,
        next: null,
        prev: null,
        totalPages: null,
        isLoading: true,
        error: false,
      }
    }
    default:
      return state
  }
}
