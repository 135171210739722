import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import MaintainerForm from "@components/Mantainers/Register/MaintainerForm"

const useStyles = makeStyles({})

export default function RenovationMaintainer() {
  const classes = useStyles()

  return <MaintainerForm type="renovation" />
}
