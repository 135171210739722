import { formatDonationAmount } from "@api/format/formatDonationAmount"
import formatDate from "@api/format/team/formatDate"

const tablePaymentsBody = (maintainers) => {
  if (maintainers) {
    const maintainersFormatted = maintainers.map((maintainer) => {
      const paymentsPaid = maintainer.transactions?.map((transaction) => {
        if (transaction.currentStatus == "paid") {
          return transaction
        } else {
          return {
            dateUpdated: "",
          }
        }
      })
      const dateUpdated = paymentsPaid
        ? paymentsPaid[paymentsPaid.length - 1].dateUpdated
        : null
      const installments = () => {
        if (maintainer.plan) {
          if (maintainer.plan.frequency == "Annually") {
            return maintainer.plan.installments
          }
        }
        return 0
      }
      const typeMethod = () => {
        if (maintainer.paymentMethod === "credit_card") {
          return "Cartão de crédito"
        } else if (maintainer.paymentMethod === "boleto") {
          return "Boleto"
        } else {
          return ""
        }
      }

      if (maintainer.paymentMethod === "boleto") {
        if (!!maintainer.lastPaidTransaction) {
          return {
            id: maintainer.maintainer._id,
            name: `${maintainer.maintainer.firstName} ${
              !maintainer.maintainer.lastName
                ? ""
                : maintainer.maintainer.lastName
            } `,
            dueDate: maintainer.lastPaidTransaction.dueDate
              ? formatDate(maintainer.lastPaidTransaction.dueDate).date
              : "",
            payment: maintainer.lastPaidTransaction.dateUpdated
              ? formatDate(maintainer.lastPaidTransaction.dateUpdated).date
              : "",
            amount: formatDonationAmount(maintainer.totalAmount),
            method: typeMethod(),
          }
        }
      }

      return {
        id: maintainer.maintainer._id,
        name: `${maintainer.maintainer.firstName} ${
          maintainer.maintainer.lastName == undefined ||
          maintainer.maintainer.lastName == null
            ? ""
            : maintainer.maintainer.lastName
        } `,
        dueDate: maintainer.currentPeriodEnd
          ? formatDate(maintainer.currentPeriodEnd).date
          : "",
        payment: dateUpdated ? formatDate(dateUpdated).date : "",
        // parcel: `00/${installments()}`,
        amount: maintainer.plan ? `R$ ${maintainer.plan.amount}` : "R$ 00.00",
        method: typeMethod(),
      }
    })
    return maintainersFormatted
  } else {
    return []
  }
}

export default tablePaymentsBody
