export const formatDonationType = (donationType) => {
  switch (donationType) {
    case "subscription":
      return "Cartão de Crédito"
    case "booklet":
      return "Boleto"
    case "door_to_door":
      return "Porta em Porta"
    default:
      return "Não identificado"
  }
}
