export default function getMessagesItems(messages) {
  let defaultMessages = [
    {
      type: "email",
      subject: null,
      body: null,
      template: null,
      written: false,
      _id: null,
    },
    {
      type: "sms",
      body: null,
      written: false,
      _id: null,
    },
    {
      type: "whatsapp",
      body: null,
      written: false,
      _id: null,
    },
  ]

  let res = []

  for (let i = 0; i < defaultMessages.length; i++) {
    let newItem = defaultMessages[i]

    for (let j = 0; j < messages.length; j++) {
      if (defaultMessages[i].type === messages[j].type) {
        newItem = {
          ...messages[j],
          written: true,
        }
      }
    }

    res.push(newItem)
  }

  return res
}
