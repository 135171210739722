import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import getPagesDonation from "@api/maintainers/donations/getPages"
import DonationTab from "@components/UI/Tab/DonationTab"

import { Switch, Route, Redirect } from "react-router-dom"
import colors from "@styles/colors"

const styles = (theme) => ({
  donationsFilter: {
    marginTop: 16,
    marginBottom: 16,
  },
  donationTypeStyle: {
    gap: 10,
    marginTop: 32,
  },
  buttonStyle: {
    padding: "14px 20px",
    borderRadius: 50,
    fontSize: 14,
    textTransform: "none",
    color: colors.darkLightText,
  },
  selected: {
    backgroundColor: colors.primaryColor,
    color: colors.whiteText,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
})

class DonationsMaintainers extends Component {
  handleRenderContentDonation = () => {
    return (
      <Switch>
        {getPagesDonation().map((route) => (
          <Route
            path={`/app/mantenedores/doacoes/${route.path}`}
            component={route.component}
          />
        ))}
        <Redirect to="/app/mantenedores/doacoes/pagamentos/1" />
      </Switch>
    )
  }
  render() {
    const { classes } = this.props

    return (
      <Grid container>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.donationsFilter}
        >
          <DonationTab tabs={getPagesDonation()} />
        </Grid>

        {this.handleRenderContentDonation()}
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  const { paymentsDonationType } = state.get("maintainer")
  return {
    paymentsDonationType,
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(DonationsMaintainers)
)
