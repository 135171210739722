import React, { useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

import { Link, useHistory } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import { changePassword } from "@redux/actions/passwordActions"

import { useForm } from "react-hook-form"
import changePasswordLoggedInResolver from "@api/validation/resolvers/changePasswordLoggedInResolver"

import PrimaryButton from "@components/UI/Button/PrimaryButton"
import SecondaryButton from "@components/UI/Button/SecondaryButton"
import Input from "@components/UI/Input/FormInput"
import { alert } from "@redux/actions/alertActions"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop: "-8rem",
  },
  paper: {
    padding: 16,
  },
  input: {
    marginTop: "1rem",
  },
  password: {
    width: "80%",
    marginTop: "1.5rem",
  },
  buttons: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  cancelButton: {
    textDecoration: "none",
  },
  paperContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 550,
    },
  },
}))

export default function ChangePasswordLoggedIn() {
  const classes = useStyles()

  const { error } = useSelector((state) => state.get("password"))
  const dispatch = useDispatch()

  const history = useHistory()

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: changePasswordLoggedInResolver(),
  })

  useEffect(() => {
    if (error) {
      setError("oldPassword", {
        type: "manual",
        message:
          "Senha antiga e/ou nova senha e confirmação de nova senha não batem",
      })

      setError("newPassword", {
        type: "manual",
        message:
          "Senha antiga e/ou nova senha e confirmação de nova senha não batem",
      })

      setError("confirmNewPassword", {
        type: "manual",
        message:
          "Senha antiga e/ou nova senha e confirmação de nova senha não batem",
      })
    }
  }, [error])

  const handleRedirect = () => {
    history.push("/app")
  }

  const handleSubmitChangePassword = ({
    oldPassword,
    newPassword,
    confirmNewPassword,
  }) => {
    if (newPassword !== confirmNewPassword) {
      setError(
        "newPassword",
        {
          type: "manual",
          message: "Senha e confirmação de senha não batem",
        },
        { shouldFocus: true }
      )

      setError("confirmNewPassword", {
        type: "manual",
        message: "Senha e confirmação de senha não batem",
      })

      dispatch(alert("error", "As senhas não coincidem"))
      return
    }

    const data = {
      oldPassword,
      newPassword,
      confirmNewPassword,
    }

    dispatch(changePassword(data, handleRedirect))
  }

  return (
    <Grid className={classes.root} container>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={11} className={classes.paperContainer}>
          <Paper
            component="form"
            onSubmit={handleSubmit(handleSubmitChangePassword)}
            className={classes.paper}
            elevation={3}
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.input}
            >
              <Input
                className={classes.password}
                size="small"
                id="outlined-basic"
                label="Senha Atual"
                type="password"
                name="oldPassword"
                control={control}
                errors={errors}
              />
              <Input
                className={classes.password}
                size="small"
                id="outlined-basic"
                label="Senha Nova"
                type="password"
                name="newPassword"
                control={control}
                errors={errors}
              />
              <Input
                className={classes.password}
                size="small"
                id="outlined-basic"
                label="Confirmar Senha"
                type="password"
                name="confirmNewPassword"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid className={classes.buttons} container justify="center">
              <Link to="/app" className={classes.cancelButton}>
                <SecondaryButton onClick={() => null}>CANCELAR</SecondaryButton>
              </Link>
              <PrimaryButton type="submit">ALTERAR SENHA</PrimaryButton>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}
