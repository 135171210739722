import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

import { Link } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"

import { useForm } from "react-hook-form"
import loginResolver from "@api/validation/resolvers/loginResolver"

import { useHistory } from "react-router-dom"

import { clearErrors, login } from "@redux/actions/authActions"

import Input from "@components/UI/Input/FormInput"
import Button from "@components/UI/Button/PrimaryButton"
import Loading from "@components/UI/Loading/Loading"

import colors from "@styles/colors"

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 16,
  },
  paperContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 428,
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 45,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
  },
  auth: {
    marginTop: 30,
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(35),
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(28),
    },
  },
  forgetPassword: {
    paddingBottom: 16,
    width: theme.spacing(35),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(28),
    },
  },
  textForgetPassword: {
    fontSize: 14,
    color: colors.orange,
    fontFamily: "Raleway",
  },
  button: {
    marginTop: 25,
  },
}))

export default function LoginCard() {
  const classes = useStyles()

  const { error, token, loading } = useSelector((state) => state.get("auth"))
  const dispatch = useDispatch()

  const history = useHistory()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: loginResolver(),
  })

  const redirectIfLogged = () => {
    if (token) {
      history.push("/app")
    }
  }

  useEffect(() => {
    redirectIfLogged()
  }, [token])

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    dispatch(clearErrors())
  }

  const handleLogin = (data) => {
    dispatch(login(data))
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnack} severity="error">
          Email e/ou senhas incorretos!
        </Alert>
      </Snackbar>
      <Grid item xs={11} className={classes.paperContainer}>
        <Paper
          component="form"
          onSubmit={handleSubmit(handleLogin)}
          className={classes.card}
          elevation={3}
        >
          <Typography className={classes.title}>Mantenedores</Typography>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.auth}
          >
            <Input
              type="email"
              name="email"
              // autoComplete="email"
              className={classes.field}
              label="Email"
              size="small"
              control={control}
              errors={errors}
            />
            <Input
              name="password"
              type="password"
              className={classes.field}
              label="Senha"
              size="small"
              control={control}
              errors={errors}
            />
            <Grid item className={classes.forgetPassword} justify="flex-start">
              <Link
                to="/esqueci-minha-senha"
                style={{ textDecoration: "none" }}
              >
                <Typography className={classes.textForgetPassword}>
                  Esqueci minha senha
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            {loading ? (
              <Loading />
            ) : (
              <Button className={classes.button} type="submit">
                LOGIN
              </Button>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
