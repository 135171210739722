//Options
import Churches from "@components/Settings/Churches"
import Origins from "@components/Settings/Origins"
import Plans from "@components/Settings/Plans"
import ConstructionPage from "@components/UI/ConstructionPage"

export default () => {
  return [
    {
      name: "Igrejas",
      path: "igrejas/:p",
      component: Churches,
    },
    {
      name: "Origens",
      path: "origens/:p",
      component: Origins,
    },
    {
      name: "Planos",
      path: "planos/:p",
      component: Plans,
    },
    {
      name: "Operadoras",
      path: "operadoras",
      component: ConstructionPage,
    },
  ]
}
