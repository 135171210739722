import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Paper, Typography } from "@material-ui/core"

const styles = (theme) => ({
  root: {
    padding: 15,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      padding: 0,
    },
  },
  papper: {
    width: "100%",
    borderRadius: 6,
    boxShadow: "0px 0px 6px #00000029",
    padding: "24px 53px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
})

class MantainerInfo extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    address: {
      street: "",
      streetNumber: "",
      neighborhood: "",
      city: "",
    },
  }

  render() {
    const { classes, maintainer } = this.props
    return (
      <Grid container justify="center" className={classes.root}>
        <Paper className={classes.papper}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography>
              {maintainer.firstName} {maintainer.lastName}
            </Typography>
            <Typography>{maintainer.email}</Typography>
            <Typography>
              Rua {maintainer.address.street}, {maintainer.address.streetNumber}
              , {maintainer.address.neighborhood}, {maintainer.address.city}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}
function mapStateToProps(state) {
  const { maintainer } = state.get("maintainer")
  return {
    maintainer,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(MantainerInfo))
